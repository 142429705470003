import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import DatePicker from 'react-datepicker';

const DatePickerInput = forwardRef(
  (
    {
      selectedDate,
      handleDateChange,
      dateFormat,
      isDisabled,
      placeholderText,
      minDate,
      monthPicker,
      classStyle
    },
    ref
  ) => {
    return (
      <DatePicker
        ref={ref}
        selected={selectedDate}
        onChange={(date) => handleDateChange(date)}
        closeOnScroll
        dateFormat={dateFormat}
        className={classStyle}
        placeholderText={placeholderText}
        onChangeRaw={(e) => e.preventDefault()}
        disabled={isDisabled}
        minDate={minDate}
        showMonthYearPicker={monthPicker}
      />
    );
  }
);

export default DatePickerInput;

DatePickerInput.propTypes = {
  selectedDate: PropTypes.any.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  placeholderText: PropTypes.string,
  minDate: PropTypes.any,
  monthPicker: PropTypes.bool,
  classStyle: PropTypes.string
};

DatePickerInput.defaultProps = {
  dateFormat: 'MM/dd/yyyy',
  placeholderText: 'Select Date',
  minDate: moment().toDate(),
  monthPicker: false,
  classStyle:
    'leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2'
};
