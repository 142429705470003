/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';

import {
  getQuoteDetailsService,
  updateCarrierType,
  sendEmails
} from '../../services/quote.service';

import { LTL_FREIGHT } from '../../constants/service_type.constants';
import { toCamelCase } from '../../utils/quote.util';
import { useAuthContext } from '../../contexts/auth.context';

import { ReactComponent as RightArrowIcon } from '../../assets/images/right_arrow.svg';
import { VENDORS } from '../../constants/carrier.constants';
import Roles from '../../constants/roles.constants';
import {
  DELIVERY_SERVICE_OPTION,
  PICKUP_SERVICE_OPTION
} from '../../constants/quote.constants';

const NewQuoteSummaryPage = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const { authUser } = useAuthContext();

  const [quote, setQuote] = useState(null);
  const [isAPICalled, setisAPICalled] = useState(false);
  const [value, setValue] = useState('');
  const [goodsType, setGoodsType] = useState([]);
  const [cargoTypes, setcargoTypes] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [emailVal, setEmailVal] = useState('');

  async function fetchQuoteDetails() {
    try {
      const response = await getQuoteDetailsService(quoteId);

      setQuote(response.data.quote);

      const allCargos = response.data.quote.cargos.map(
        (cargo) => cargo.goods_type
      );
      const flattenCargos = allCargos.flat();
      setGoodsType([
        ...new Set(flattenCargos.map((x) => toCamelCase(x.display_name)))
      ]);

      const packageTypes = response.data.quote.cargos
        .map((cargo) => cargo.package_type)
        .flat();

      setcargoTypes([
        ...new Set(packageTypes.map((x) => toCamelCase(x.display_name)))
      ]);

      setisAPICalled(true);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }, []);

  useEffect(() => {
    fetchQuoteDetails();
  }, [quoteId]);

  function checkEmailFunc(val) {
    if (!val || val === '') return { error: false };
    const regex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line

    const emailArray = val?.split(',').map((email) => email.trim());
    const invalidEmails = [];
    emailArray.forEach((email) => {
      if (email === '' || email.length <= 3) return;
      if (!regex.test(email)) invalidEmails.push(email);
    });
    if (invalidEmails.length > 0) {
      return {
        error: true,
        message: `Invalid Emails: ${invalidEmails.join(', ')}`
      }; //eslint-disable-line
    }
    return { error: false };
  }

  const emailHandler = (e) => {
    const { error, message } = checkEmailFunc(e);
    setEmailError(error ? message : '');
    setEmailVal(e);
  };

  const onSendEmail = async () => {
    try {
      if (emailError) return toast.error(emailError);
      const response = await sendEmails({ quoteId, emails: emailVal });
      setEmailVal('');
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  const onAddQuote = () => {
    navigate('/new-quote', { replace: true });
  };

  const onBookNowQuote = async () => {
    try {
      if (quote.service_type !== LTL_FREIGHT) {
        return navigate(`/book-now-quote/${quoteId}`);
      }
      if (quote && value !== '') {
        if (!quote[value] || !quote[value][`${value}_estimate`]) {
          return toast.error('Please select another carrier');
        }
        const response = await updateCarrierType({
          carrier_type: value,
          quoteId
        });
        if (response.data.flag) {
          navigate(`/book-now-quote/${quoteId}`);
        }
      } else {
        toast.error('Please Select carrier');
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onChangeHandler = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="py-20 bg-gray-50 radius-for-skewed">
      <div className="container px-4 mx-auto">
        <div className="relative p-4 pl-0 mb-6">
          <h3 className="text-3xl font-bold text-navy-500">
            Your quote summary
          </h3>
        </div>
        {isAPICalled ? (
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3 mb-6 lg:mb-0 lg:w-full">
              <div className="flex flex-wrap p-4 border shadow md:p-6 bg-navy-50 border-navy-50 ">
                <div className="inline-block w-full overflow-x-scroll">
                  <table className="w-full p-4 table-fixed">
                    {quote.service_type === LTL_FREIGHT ? (
                      <tbody>
                        <tr className="w-full p-4 border-b border-gray-300">
                          <td className="w-2/4 p-4 font-bold">Select</td>
                          <td className="w-2/4 p-4 font-bold">Carrier</td>
                          <td className="w-2/4 p-4 font-bold">Service Name</td>
                          <td className="w-2/4 p-4 font-bold">Delivery Days</td>
                          <td className="w-2/4 p-4 font-bold">Distance</td>
                          {![Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
                            authUser.user.usertype
                          ) ? (
                            <td className="w-2/4 p-4 font-bold">Estimate</td>
                          ) : (
                            <>
                              <td className="w-2/4 p-4 font-bold">Revenue</td>
                              <td className="w-2/4 p-4 font-bold">
                                Carrier Price
                              </td>
                              <td className="w-2/4 p-4 font-bold">Profit</td>
                            </>
                          )}
                        </tr>
                        {VENDORS.sort(
                          (a, b) =>
                            (quote?.[a.name]?.[`${a.name}_estimate`] ?? 0) -
                            (quote?.[b.name]?.[`${b.name}_estimate`] ?? 0)
                        ).map((carrier) => {
                          return quote?.[carrier.name]?.[
                            `${carrier.name}_estimate`
                          ] ? (
                            <tr className="w-full p-4 border-b border-gray-300">
                              <td>
                                <label>
                                  <input
                                    type="radio"
                                    value={carrier.name}
                                    checked={value === carrier.name}
                                    onChange={(e) => onChangeHandler(e)}
                                  />
                                  <span className="ml-1"> </span>
                                </label>
                              </td>
                              <td className="w-2/4 p-4 uppercase">
                                {carrier.display_name}
                              </td>
                              <td className="w-2/4 p-4 uppercase">
                                {carrier.service}
                              </td>
                              <td className="w-2/4 p-4">
                                {quote[carrier.name]?.transitDays
                                  ? quote[carrier.name].transitDays
                                  : 'N/A'}
                              </td>
                              <td className="w-2/4 p-4">
                                {quote.distance} miles
                              </td>
                              <td className="w-1/4 p-4">
                                $
                                {quote[carrier.name] &&
                                quote[carrier.name].estimate
                                  ? quote[carrier.name].estimate.toFixed(2)
                                  : '0.00'}
                              </td>
                              {[Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
                                authUser.user.usertype
                              ) ? (
                                <>
                                  <td className="w-1/4 p-4">
                                    $
                                    {quote[carrier.name] &&
                                    quote[carrier.name][
                                      `${carrier.name}_estimate`
                                    ]
                                      ? quote[carrier.name][
                                          `${carrier.name}_estimate`
                                        ].toFixed(2)
                                      : '0.00'}
                                  </td>
                                  <td className="w-1/4 p-4">
                                    $
                                    {quote?.[carrier.name]?.commission &&
                                    quote[carrier.name].commission
                                      ? quote[carrier.name].commission.toFixed(
                                          2
                                        )
                                      : '0.00'}
                                  </td>
                                </>
                              ) : null}
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="w-full p-4 border-b border-gray-300">
                          <td className="w-2/4 p-4 font-bold">
                            Estimated Price
                          </td>
                          <td className="w-1/4 p-4 font-bold">
                            $
                            {quote?.estimate
                              ? (quote.estimate + quote.tarps_value).toFixed(2)
                              : '0.00'}
                          </td>
                        </tr>
                        <tr className="w-full p-4 border-b border-gray-300">
                          <td className="w-2/4 p-4">From</td>
                          <td className="w-1/4 p-4 font-bold">
                            {quote.isMultipleQuote
                              ? quote.pickupInfo
                                  ?.map((pick) => pick.pickup_zip_code)
                                  ?.join(', ')
                              : quote.pickup_zip_code}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                          <td className="w-2/4 p-4">Number of cargo(s)</td>
                          <td className="w-1/4 p-4 font-bold">
                            {quote.cargos.length}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                          <td className="w-2/4 p-4">Type of cargo(s)</td>
                          <td className="w-1/4 p-4 font-bold">
                            {cargoTypes.map((cargoType) => (
                              <div key={cargoType}>{cargoType}</div>
                            ))}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                          <td className="w-2/4 p-4">Types of goods</td>
                          <td className="w-1/4 p-4 font-bold">
                            {goodsType.map((goodType) => (
                              <div key={goodType}>{goodType}</div>
                            ))}
                            {!goodsType.length && 'N/A'}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                          <td className="w-2/4 p-4">To</td>
                          <td className="w-1/4 p-4 font-bold">
                            {quote.isMultipleQuote
                              ? quote.deliveryInfo
                                  ?.map((del) => del.delivery_zip_code)
                                  ?.join(', ')
                              : quote.delivery_zip_code}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                          <td className="w-2/4 p-4">Distance</td>
                          <td className="w-1/4 p-4 font-bold">
                            {quote.distance ? `${quote.distance} miles` : 'N/A'}
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300">
                          <td className="w-2/4 p-4">
                            Special service(s) for pickup
                          </td>
                          <td className="w-1/4 p-4 font-bold">
                            {quote.isMultipleQuote
                              ? quote.pickupInfo.map((pick) => {
                                  const str = pick.pickup_service_options
                                    .map((p) => {
                                      return PICKUP_SERVICE_OPTION.find(
                                        (e) => e.value === p
                                      )?.label;
                                    })
                                    ?.join(', ');
                                  return (
                                    <div>
                                      {pick.pickup_zip_code} - {str}
                                    </div>
                                  );
                                })
                              : quote.pickup_service_options.map((option) => (
                                  <div key={option.id}>
                                    {option.display_name}
                                  </div>
                                ))}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="w-2/4 p-4">
                            Special service(s) for delivery
                          </td>
                          <td className="w-1/4 p-4 font-bold">
                            {quote.isMultipleQuote
                              ? quote.deliveryInfo.map((del) => {
                                  const str = del.delivery_service_options
                                    .map((p) => {
                                      return DELIVERY_SERVICE_OPTION.find(
                                        (e) => e.value === p
                                      )?.label;
                                    })
                                    ?.join(', ');
                                  return (
                                    <div>
                                      {del.delivery_zip_code} - {str}
                                    </div>
                                  );
                                })
                              : quote.delivery_service_options.map((option) => (
                                  <div key={option.id}>
                                    {option.display_name}
                                  </div>
                                ))}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="w-full mt-5 mb-5 text-gray-400">
                <div className="relative p-4 pb-5 pl-0 mb-3 border border-t-0 border-l-0 border-r-0 border-gray-200">
                  <p className="text-xl text-left text-black">Enter Emails</p>
                  <div className="p-4 pl-0 mb-3">
                    <textarea
                      onInput={(event) => emailHandler(event.target.value)}
                      name="emails"
                      value={emailVal}
                      placeholder="Enter Comma seperated valid emails. Example: steve@example.com, vest@example.com"
                      className="w-full p-4 font-semibold leading-none text-gray-700 bg-white border border-gray-200 outline-none text-xm"
                    />
                    {emailError && (
                      <span className="text-xs text-red-700">{emailError}</span>
                    )}
                  </div>
                  <button
                    type="button"
                    className="relative inline-block w-1/2 p-4 mb-2 text-sm font-bold text-center text-white transition duration-200 border-2 md:w-1/4 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                    onClick={onSendEmail}
                  >
                    Send Emails
                  </button>
                </div>
                <p>
                  If you wish to book now please click on the button below and
                  once all the information is enter your shipment will
                  automatically be scheduled with the carrier. Please make sure
                  the freight is labeled properly and the bill of lading
                  provided is printed and given to the driver at the time of
                  pick up to insure proper billing.
                </p>
              </div>
              <div className="relative flex p-4 pl-0 text-left">
                <button
                  type="button"
                  onClick={onAddQuote}
                  className="relative inline-block w-1/2 p-4 mb-2 mr-2 text-sm font-bold text-left transition duration-200 bg-white border-2 md:w-1/4 md:mr-12 text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 border-navy-500 hover:border-navy-600"
                >
                  New Quote
                </button>
                <button
                  type="button"
                  onClick={onBookNowQuote}
                  disabled={quote?.estimate === null}
                  className={`relative inline-block w-1/2 p-4 mb-2 text-sm font-bold text-left text-white transition duration-200 border-2 md:w-1/4 ${
                    quote?.estimate === null
                      ? 'cursor-not-allowed bg-gray-500 border-gray-500'
                      : 'bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700'
                  }`}
                >
                  Book Now
                  <RightArrowIcon className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-2 mr-2 font-bold pointer-events-none" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {/* </div> */}
      </div>
    </div>
  );
};

export default NewQuoteSummaryPage;
