import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Select from 'react-select';
import * as yup from 'yup';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';
import TimePickerInput from '../../../components/TimePickerInput/TimePickerInput';
import {
  PICKUP_LIMITED_ACCESS_TYPES,
  PICKUP_SERVICE_OPTION
} from '../../../constants/quote.constants';
import {
  PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT,
  PICKUP_INSIDE_PICKUP,
  PICKUP_LIMITED_ACCESS,
  PICKUP_RESIDENTIAL
} from '../../../constants/service_type.constants';
import { useAuthContext } from '../../../contexts/auth.context';
import { getAddressFromLatLong } from '../../../services/geo.service';
import {
  addLocationService,
  getLocationService,
  updateLocationService
} from '../../../services/shipper.service';
import { formatPickupDeliveryTime } from '../../../utils/date_conversion.util';

const AddPickupAddress = () => {
  const { authUser } = useAuthContext();
  const { customerId: companyId } = useParams();

  const navigate = useNavigate();

  const validationSchema = yup.object({
    pickup_from: yup.string().required('Pickup from is required'),
    pickup_formatted_address: yup
      .string()
      .required('Pickup formatted address is required'),
    pickup_zip_code: yup.string().required('Zip code is required'),
    pickup_time: yup.string().when('is_pickup_time_flexible', {
      is: false,
      then: () => yup.string().required('Pickup time is required')
    }),
    pickup_close_time: yup.string(),
    is_pickup_time_flexible: yup.bool(),
    pickup_service_options: yup.mixed().nullable(),
    pickup_special_instructions: yup.string(),
    pickup_limited_access_type: yup.mixed().when('pickup_service_options', {
      is: (data) => data?.includes(PICKUP_LIMITED_ACCESS),
      then: () => yup.mixed().required('Pickup limited access type is required')
    }),
    company: yup.string(),
    contactperson: yup.string(),
    contactnumber: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(/^[0-9]+$/, 'Mobile number must be only digits')
      .max(11)
      .min(10, 'Mobile number must be at least 10 digits')
  });

  const {
    register,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const [isTimeDisable, setTimeDisable] = useState(true);
  const [pickupMessage, setpickupMessage] = useState(false);
  const [address, setAddress] = useState('');
  const [isAPICalled, setAPICalled] = useState(false);

  const isPickupTimeFlexible = watch('is_pickup_time_flexible');
  const pickUpServices = watch('pickup_service_options');
  const pickupServiceOptions = watch('pickup_service_options');

  const handleSelect = async (value, placeId) => {
    let id = placeId;
    if (!id) {
      const result = await geocodeByAddress(value);
      id = result[0].place_id;
    }
    const [place] = await geocodeByPlaceId(id.trim());
    const { addr, postcode, city, state, country } =
      await getAddressFromLatLong(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        value,
        place.postcode_localities
      );
    setAddress(value);

    setValue(
      'pickup_from',
      `${addr} | ${postcode}, ${city}, ${state}, ${country}`
    );
    setValue('pickup_formatted_address', value);
    setValue('pickup_zip_code', postcode);
    setValue('locationId', placeId);
  };
  const queryData = useLocation().search;
  const locationId = new URLSearchParams(queryData).get('location');

  async function fetchLocationData() {
    try {
      const response = await getLocationService(locationId);
      const resData = response?.data?.data;

      setAddress(resData?.name);
      reset({
        ...resData,
        pickup_time:
          resData?.pickup_time !== 'flexible time'
            ? new Date(moment(resData.pickup_time, 'h:mm a').format())
            : '',
        pickup_close_time: resData?.pickup_close_time
          ? new Date(moment(resData.pickup_close_time, 'h:mm a').format())
          : ''
      });

      setAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    if (locationId) {
      fetchLocationData();
    } else {
      setAPICalled(true);
    }
  }, []);
  useEffect(() => {
    if (isPickupTimeFlexible !== undefined) {
      if (isPickupTimeFlexible === true) {
        setValue('pickup_time', '', { shouldValidate: true });
        setValue('pickup_close_time', '', { shouldValidate: true });
        setTimeDisable(true);
      } else {
        setTimeDisable(false);
      }
    }
  }, [isPickupTimeFlexible]);

  useEffect(() => {
    if (typeof pickUpServices === 'string' && pickUpServices.length) {
      setValue('pickup_service_options', [pickUpServices]);
    }
    if (pickUpServices && pickUpServices.length) {
      if (
        pickUpServices.includes(PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT) &&
        pickUpServices.includes(PICKUP_RESIDENTIAL)
      ) {
        setValue(
          'pickup_service_options',
          pickUpServices.filter(
            (opt) =>
              opt !== PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT &&
              opt !== PICKUP_RESIDENTIAL
          ),
          {
            shouldValidate: true
          }
        );
        toast.error(
          'You can select either Business with a Dock or Forklift or Residential at a time!'
        );
      }
      if (!pickUpServices.includes(PICKUP_LIMITED_ACCESS)) {
        setValue('delivery_limited_access_type', undefined);
      }
      if (pickUpServices.includes(PICKUP_INSIDE_PICKUP) && !pickupMessage) {
        toast.success(
          'Please fill up special instructions for us at the bottom of this page, since you have selected inside pick up.'
        );
        setpickupMessage(true);
      }
    }
  }, [pickUpServices]);

  const onSubmitForm = async (data) => {
    try {
      const userId =
        authUser?.user?.usertype === 'user'
          ? authUser?.user?.companyId?.id
          : companyId;

      const pickupData = {
        ...data,
        name: data.pickup_formatted_address,
        type: 'pickup',
        companyId: userId,
        pickup_time: formatPickupDeliveryTime(
          data.pickup_time,
          data.is_pickup_time_flexible
        ),
        pickup_close_time: !data.pickup_close_time
          ? ''
          : formatPickupDeliveryTime(data.pickup_close_time, false),
        service_options_type: [data.pickup_limited_access_type]
          ?.filter((type) => type)
          .map((option) => option.value)
      };
      const isEdit = !!locationId;

      if (isEdit) {
        await updateLocationService(locationId, pickupData);
        toast.success('Location saved successfully!');
      } else {
        await addLocationService(pickupData);
        toast.success('Location saved successfully!');
      }

      navigate(-1);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  const onBackArrowClick = () => {
    navigate(-1);
  };
  return (
    isAPICalled && (
      <section className="py-10">
        <div className="container mx-auto">
          <div className="flex flex-row gap-2 items-center p-4 text-navy-500">
            <div
              onKeyPress={() => {}}
              role="button"
              tabIndex="-1"
              onClick={onBackArrowClick}
            >
              <LeftArrowIcon className="flex items-center w-6 h-6 font-bold text-navy-500" />
            </div>
            <h3 className="text-2xl font-extrabold">Add New Pickup Address</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="p-4 mb-3">
              <label
                className="block w-full mb-2 text-sm font-bold text-black"
                htmlFor="ship-from"
              >
                Ship From
              </label>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div>
                    <div className="flex items-center gap-3">
                      <input
                        className="w-full px-2 py-3 text-xs leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
                        {...getInputProps({ placeholder: 'Type address' })}
                      />
                    </div>
                    {suggestions.length > 0 && (
                      <div className="overflow-scroll max-h-48">
                        <div className="px-2 py-2 mt-2 space-y-3 bg-gray-100 border border-gray-200 border-solid shadow-lg">
                          <div className="flex flex-col gap-2 mt-3 bg-gray-100">
                            {loading ? <div>...loading</div> : null}
                            {suggestions.map((suggestion) => {
                              return (
                                <label {...getSuggestionItemProps(suggestion)}>
                                  <input type="radio" value={suggestion.id} />

                                  <span className="">
                                    {suggestion.description}
                                  </span>
                                </label>
                              );
                            })}
                          </div>
                          <hr className="mt-3" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
              {errors?.pickup_zip_code && (
                <span className="text-xs text-red-700">
                  {errors?.pickup_zip_code.message}
                </span>
              )}
              <div className="flex justify-between gap-6 mt-6">
                <div className="w-full">
                  <label
                    className="block w-full mb-2 text-sm font-bold text-black"
                    htmlFor="ship-from"
                  >
                    Company Name
                  </label>
                  <input
                    {...register(`company`)}
                    className="w-full px-2 py-3 text-xs leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
                    type="text"
                    placeholder="Add company name"
                  />
                  {errors.company && (
                    <span className="text-xs text-red-700">
                      {errors.company.message}
                    </span>
                  )}
                </div>
                <div className="w-full">
                  <label className="block w-full mb-2 text-sm font-bold text-black">
                    Contact Person Name
                  </label>
                  <input
                    {...register(`contactperson`)}
                    className="w-full px-2 py-3 text-xs leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
                    type="text"
                    placeholder="Add contact person name"
                  />
                  {errors.contactperson && (
                    <span className="text-xs text-red-700">
                      {errors.contactperson.message}
                    </span>
                  )}
                </div>
                <div className="w-full">
                  <label className="block w-full mb-2 text-sm font-bold text-black">
                    Phone number
                  </label>
                  <input
                    {...register(`contactnumber`)}
                    className="w-full px-2 py-3 text-xs leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
                    type="text"
                    placeholder="Add contact person phone number"
                  />
                  {errors.contactnumber && (
                    <span className="text-xs text-red-700">
                      {errors.contactnumber.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <hr className="mx-3" />
            <div className="flex w-2/3">
              <div className="flex-col w-full">
                <div className="p-4 pb-0">
                  <label
                    className="block w-full mb-2 text-sm font-bold text-black"
                    htmlFor="pickup-time"
                  >
                    Pickup Time
                  </label>
                  <Controller
                    render={({ field: { ref, name, value, onChange } }) => (
                      <TimePickerInput
                        name={name}
                        ref={ref}
                        handleDateChange={(e) => onChange(e)}
                        selectedDate={value}
                        isDisabled={isTimeDisable}
                        classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                      />
                    )}
                    name="pickup_time"
                    control={control}
                  />
                  {errors.pickup_time && (
                    <span className="text-xs text-red-700">
                      {errors.pickup_time.message}
                    </span>
                  )}
                </div>

                <div className="p-4 mb-3">
                  <label>
                    <input
                      {...register('is_pickup_time_flexible')}
                      type="checkbox"
                      defaultChecked={
                        !isPickupTimeFlexible && !getValues('pickup_time')
                      }
                    />
                    <span className="ml-1 text-sm">Flexible (8am-5pm)</span>
                  </label>
                  {errors.is_pickup_time_flexible && (
                    <span className="text-xs text-red-700">
                      {errors.is_pickup_time_flexible.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full p-4 pb-0 mb-3">
                <label
                  className="block w-full mb-2 text-sm font-bold text-black"
                  htmlFor="pickup-time"
                >
                  Pickup Close Time
                </label>
                <Controller
                  render={({ field: { ref, name, value, onChange } }) => (
                    <TimePickerInput
                      name={name}
                      ref={ref}
                      handleDateChange={(e) => onChange(e)}
                      selectedDate={value}
                      isDisabled={isTimeDisable}
                      classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    />
                  )}
                  name="pickup_close_time"
                  control={control}
                />
                {errors.pickup_close_time && (
                  <span className="text-xs text-red-700">
                    {errors.pickup_close_time.message}
                  </span>
                )}
              </div>
            </div>
            <hr className="mx-3" />
            <div className="p-4 ">
              <label
                className="block w-full mb-2 text-sm font-bold text-black"
                htmlFor="pickup-date"
              >
                Select Pickup Services
              </label>
              <div className="grid grid-cols-4 mt-6">
                {PICKUP_SERVICE_OPTION.map((pickupOption) => {
                  return (
                    <>
                      <div className="mb-4" key={pickupOption.value}>
                        <label>
                          {pickupOption.value === '57fb6370b13762ee6a3f94d0' &&
                          !pickupServiceOptions?.length ? (
                            <input
                              {...register('pickup_service_options')}
                              type="checkbox"
                              value={pickupOption.value}
                              defaultChecked
                            />
                          ) : (
                            <input
                              {...register('pickup_service_options')}
                              type="checkbox"
                              value={pickupOption.value}
                            />
                          )}
                          <span className="ml-1 text-sm">
                            {pickupOption.label}
                          </span>
                        </label>
                      </div>
                      {pickUpServices?.includes(PICKUP_LIMITED_ACCESS) &&
                      pickupOption.value === PICKUP_LIMITED_ACCESS ? (
                        <div className="flex flex-col w-1/2 mb-3">
                          <Controller
                            name="pickup_limited_access_type"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={PICKUP_LIMITED_ACCESS_TYPES}
                                className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
                              />
                            )}
                          />
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>

              {errors.pickup_service_options && (
                <span className="text-xs text-red-700">
                  {errors.pickup_service_options.message}
                </span>
              )}
              {errors?.pickup_limited_access_type && (
                <span className="text-xs text-red-700">
                  {errors?.pickup_limited_access_type?.message}
                </span>
              )}
            </div>
            <hr className="mx-3" />
            <div className="p-4">
              <label className="block w-full mb-2 text-sm font-bold text-black">
                Special pickup instructions
              </label>
              <textarea
                {...register('pickup_special_instructions')}
                className="w-full px-2 py-2 pb-10 leading-tight bg-gray-100 border border-gray-200 border-solid shadow appearance-none"
                placeholder="Write your instructions here"
              />
            </div>
            {errors.pickup_special_instructions && (
              <span className="text-xs text-red-700">
                {errors.pickup_special_instructions.message}
              </span>
            )}
            <label className="p-4">
              <input {...register('isDefault')} type="checkbox" />
              <span className="ml-1 text-sm">
                Make This Address As A Default Pickup Address
              </span>
            </label>
            <div className="p-4 mt-10">
              <button
                type="submit"
                className="px-4 py-2 text-sm font-semibold text-white bg-navy-500"
              >
                Add Pickup Address
              </button>
            </div>
          </form>
        </div>
      </section>
    )
  );
};

AddPickupAddress.propTypes = {
  //   prevStep: PropTypes.func.isRequired,
  //   formContent: PropTypes.any.isRequired,
};

export default AddPickupAddress;
