import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';

const TimePickerInput = forwardRef(
  (
    { selectedDate, handleDateChange, dateFormat, isDisabled, classStyle },
    ref
  ) => {
    const datePickerRef = React.useRef(null);

    // Forward the ref to DatePicker
    React.useImperativeHandle(ref, () => ({
      focus: () => {
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false); // Open the DatePicker when focused
        }
      }
    }));
    return (
      <DatePicker
        ref={datePickerRef}
        selected={selectedDate}
        onChange={(date) => handleDateChange(date)}
        closeOnScroll
        dateFormat={dateFormat}
        timeFormat="h:mm aa"
        timeIntervals={15}
        className={classStyle}
        showTimeSelectOnly
        showTimeSelect
        timeCaption="Time"
        onChangeRaw={(e) => e.preventDefault()}
        placeholderText="Select Time"
        disabled={isDisabled}
      />
    );
  }
);

export default TimePickerInput;

TimePickerInput.propTypes = {
  selectedDate: PropTypes.any.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  classStyle: PropTypes.string
};

TimePickerInput.defaultProps = {
  dateFormat: 'h:mm aa',
  classStyle:
    'leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2'
};
