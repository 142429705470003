import Roles from './constants/roles.constants';

import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import CarrierSignupPage from './pages/Carrier/Signup/CarrierSignupPage';
import ShipperSignupPage from './pages/Shipper/Signup/ShipperSignupPage';
import CarrierThankYouPage from './pages/CarrierThankYouPage';
import GuestTrackingPage from './pages/GuestTrackingPage';
import AddressInfoPage from './pages/Carrier/AddressInfoPage';
import NewQuotePage from './pages/Shipper/ShipperQuoteSubmission/NewQuotePage';
import NewQuoteSummaryPage from './pages/Shipper/NewQuoteSummaryPage';
import RecentQuotesPage from './pages/Shipper/RecentQuotesPage';
import ActiveShipmentPage from './pages/Shipper/ActiveShipmentPage';
import ShipperPendingLoadsPage from './pages/Shipper/ShipperPendingLoadsPage';
import ShipperEnrouteLoadsPage from './pages/Shipper/ShipperEnrouteLoadsPage';
import ShipperCompletedLoadsPage from './pages/Shipper/ShipperCompletedLoadsPage';
import LoadSummaryPage from './pages/Shipper/LoadSummaryPage';
import ShipperSuccessPage from './pages/Shipper/ShipperSuccessPage';
import BookNowQuotePage from './pages/Shipper/BookNowQuotePage';
import ShipperPaymentPage from './pages/Shipper/ShipperPaymentPage';
import ShipperSubmissionList from './pages/Shipper/ShipperSubmissionList';
import ShipperAddSignaturePage from './pages/Shipper/ShipperAddSignaturePage';
import ConfirmPaymentPage from './pages/Shipper/ConfirmPaymentPage';
import TrackShipmentPage from './pages/Shipper/TrackShipmentPage';
import TrackingPage from './pages/Shipper/TrackingPage';
import GeneralSettings from './pages/SalesRep/GeneralSettingsPage';
import DOTNumberPage from './pages/Carrier/DOTNumberPage';
import AvailableLoadsPage from './pages/Carrier/AvailableLoadsPage';
import AcceptedLoadsPage from './pages/Carrier/AcceptedLoadsPage';
import PendingLoadsPage from './pages/Carrier/PendingLoadsPage';
import RejectedLoadsPage from './pages/Carrier/RejectedLoadsPage';
import EnrouteLoadsPage from './pages/Carrier/EnrouteLoadsPage';
import CompletedLoadsPage from './pages/Carrier/CompletedLoadsPage';
import LoadDetailsPage from './pages/Carrier/LoadDetailsPage';
import CarrierAddSignaturePage from './pages/Carrier/CarrierAddSignaturePage';
import DashboardPage from './pages/SalesRep/DashboardPage';
import CustomersListPage from './pages/SalesRep/CustomersListPage';
import CustomerRepListPage from './pages/SalesRep/CustomerRepListPage';
import CustomerRepAddPage from './pages/SalesRep/CustomerRepAddPage';
import CustomerDetailsPage from './pages/SalesRep/CustomerDetailsPage';
import QuotesListingPage from './pages/SalesRep/QuotesListingPage';
import QuoteDetailsPage from './pages/SalesRep/QuoteDetailsPage';
import PageNotFound from './pages/ErrorsPages/404';
import InternalServerError from './pages/ErrorsPages/500';
import ShipperSettings from './pages/Shipper/ShipperSettingsPage';
import CarrierSettings from './pages/Carrier/CarrierSettingsPage';
import AssignedLoadsPage from './pages/Carrier/AssignedLoadsPage';
import ShipperProfilePage from './pages/Shipper/ShipperProfilePage';
import AddPickupAddress from './pages/Shipper/Profile/AddPickupAddress';
import AddDeliveryAddress from './pages/Shipper/Profile/AddDeliveryAddress';
import AddNewCargo from './pages/Shipper/Profile/AddNewCargo';
import CarrierListPage from './pages/SalesRep/CarrierListPage';
import CarrierDetailsPage from './pages/SalesRep/CarrierDetailsPage';
import CarrierDashboard from './pages/Carrier/CarrierDashboard';
import ShipperDashboard from './pages/Shipper/ShipperDashboard';
import EditUserPage from './pages/SalesRep/EditUserPage';
import AddCustomerPage from './pages/SalesRep/AddCustomerPage';

const routes = {
  publicRoutes: [
    {
      path: '/sign-up',
      element: SignupPage
    },
    {
      path: '/login',
      element: LoginPage
    },
    {
      path: '/sign-in',
      element: LoginPage
    },
    {
      path: '/reset-password',
      element: ResetPasswordPage
    },
    {
      path: '/carrier-signup',
      element: CarrierSignupPage
    },
    {
      path: '/shipper-signup',
      element: ShipperSignupPage
    },
    {
      path: '/shipper-signup/:token',
      element: ShipperSignupPage
    },
    {
      path: '/page-not-found',
      element: PageNotFound
    },
    {
      path: '/something-went-wrong',
      element: InternalServerError
    },
    {
      path: '/carrier-thank-you',
      element: CarrierThankYouPage
    },
    {
      path: '/guest-tracking',
      element: GuestTrackingPage
    }
  ],
  privateRoutes: [
    {
      path: '/new-quote',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: NewQuotePage
    },
    {
      path: '/recent-quotes',
      rolesAccess: [Roles.SHIPPER],
      element: RecentQuotesPage
    },
    {
      path: '/shipper-submission-list/:quoteId',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: ShipperSubmissionList
    },
    {
      path: '/salesRep/shipper-submission-list/:quoteId',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: ShipperSubmissionList
    },
    {
      path: '/active-shipment',
      rolesAccess: [Roles.SHIPPER],
      element: ActiveShipmentPage
    },
    {
      path: '/shipper-pending',
      rolesAccess: [Roles.SHIPPER],
      element: ShipperPendingLoadsPage
    },
    {
      path: '/shipper-enroute',
      rolesAccess: [Roles.SHIPPER],
      element: ShipperEnrouteLoadsPage
    },
    {
      path: '/shipper-completed',
      rolesAccess: [Roles.SHIPPER],
      element: ShipperCompletedLoadsPage
    },
    {
      path: '/shipper-load-summary/:quoteId',
      rolesAccess: [Roles.SHIPPER],
      element: LoadSummaryPage
    },
    {
      path: '/shipper-enroute-load-summary/:quoteId',
      rolesAccess: [Roles.SHIPPER],
      element: LoadSummaryPage
    },
    {
      path: '/shipper-settings',
      rolesAccess: [Roles.SHIPPER],
      element: ShipperSettings
    },
    {
      path: '/shipper-profile',
      rolesAccess: [Roles.SHIPPER],
      element: ShipperProfilePage
    },
    {
      path: '/add-address/pickup',
      rolesAccess: [Roles.SHIPPER],
      element: AddPickupAddress
    },
    {
      path: '/add-address/delivery',
      rolesAccess: [Roles.SHIPPER],
      element: AddDeliveryAddress
    },
    {
      path: '/add-cargo',
      rolesAccess: [Roles.SHIPPER],
      element: AddNewCargo
    },
    {
      path: '/available-loads',
      rolesAccess: [Roles.CARRIER],
      element: AvailableLoadsPage
    },
    {
      path: '/accepted-loads',
      rolesAccess: [Roles.CARRIER],
      element: AcceptedLoadsPage
    },
    {
      path: '/pending-loads',
      rolesAccess: [Roles.CARRIER],
      element: PendingLoadsPage
    },
    {
      path: '/rejected-loads',
      rolesAccess: [Roles.CARRIER],
      element: RejectedLoadsPage
    },
    {
      path: '/assigned-loads',
      rolesAccess: [Roles.CARRIER],
      element: AssignedLoadsPage
    },
    {
      path: '/enroute-loads',
      rolesAccess: [Roles.CARRIER],
      element: EnrouteLoadsPage
    },
    {
      path: '/completed-loads',
      rolesAccess: [Roles.CARRIER],
      element: CompletedLoadsPage
    },
    {
      path: '/carrier-dashboard',
      rolesAccess: [Roles.CARRIER],
      element: CarrierDashboard
    },
    {
      path: '/carrier-available-load-details/:quoteId',
      rolesAccess: [Roles.CARRIER],
      element: LoadDetailsPage
    },
    {
      path: '/carrier-load-details/:quoteId/:bidId',
      rolesAccess: [Roles.CARRIER],
      element: LoadDetailsPage
    },
    {
      path: '/carrier-dot-number',
      rolesAccess: [Roles.CARRIER],
      element: DOTNumberPage
    },
    {
      path: '/carrier-settings',
      rolesAccess: [Roles.CARRIER],
      element: CarrierSettings
    },
    {
      path: '/address-details/:quoteId',
      rolesAccess: [Roles.CARRIER],
      element: AddressInfoPage
    },
    {
      path: '/dashboard',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: DashboardPage
    },
    {
      path: '/customers',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: CustomersListPage
    },
    {
      path: '/customerReps',
      rolesAccess: [Roles.SALES_REP],
      element: CustomerRepListPage
    },
    {
      path: '/customerReps/add',
      rolesAccess: [Roles.SALES_REP],
      element: CustomerRepAddPage
    },
    {
      path: '/customerReps/edit/:userId',
      rolesAccess: [Roles.SALES_REP],
      element: CustomerRepAddPage
    },
    {
      path: '/customers/add',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: AddCustomerPage
    },
    {
      path: '/customers/:customerId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: CustomerDetailsPage
    },
    {
      path: '/customers/editUsers/:customerId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: EditUserPage
    },
    {
      path: '/salesRep/carriers',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: CarrierListPage
    },
    {
      path: '/salesRep/carriers/:type/:carrierId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: CarrierDetailsPage
    },
    {
      path: '/salesRep/active-shipment',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: QuotesListingPage
    },
    {
      path: '/salesRep/pending-quotes',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: QuotesListingPage
    },
    {
      path: '/salesRep/enroute-quotes',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: QuotesListingPage
    },
    {
      path: '/salesRep/recentQuotes',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: QuotesListingPage
    },
    {
      path: '/salesRep/completed-quotes',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: QuotesListingPage
    },
    {
      path: '/salesRep/quote-details/:quoteId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: QuoteDetailsPage
    },
    {
      path: '/salesRep/enroute-quote-details/:quoteId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: QuoteDetailsPage
    },
    {
      path: '/salesRep/general-settings',
      rolesAccess: [Roles.SALES_REP],
      element: GeneralSettings
    },
    {
      path: '/shipper-profile/:customerId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: ShipperProfilePage
    },
    {
      path: '/add-address/pickup/:customerId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: AddPickupAddress
    },
    {
      path: '/add-address/delivery/:customerId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: AddDeliveryAddress
    },
    {
      path: '/add-cargo/:customerId',
      rolesAccess: [Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: AddNewCargo
    },
    {
      path: '/page-not-found',
      rolesAccess: [
        Roles.CARRIER,
        Roles.SHIPPER,
        Roles.SALES_REP,
        Roles.CUSTOMER_REP
      ],
      element: PageNotFound
    },
    {
      path: '/shipper-dashboard',
      rolesAccess: [Roles.SHIPPER],
      element: ShipperDashboard
    }
  ],
  protectedRoutes: [
    {
      path: '/new-quote-summary/:quoteId',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: NewQuoteSummaryPage
    },
    {
      path: '/thank-you',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: ShipperSuccessPage
    },
    {
      path: '/shipper-thank-you',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: ShipperSuccessPage
    },
    {
      path: '/book-now-quote/payment/:quoteId',
      rolesAccess: [Roles.SHIPPER],
      element: ShipperPaymentPage
    },
    {
      path: '/book-now-quote/confirm-payment/:bidId',
      rolesAccess: [Roles.SHIPPER],
      element: ConfirmPaymentPage
    },
    {
      path: '/book-now-quote/:quoteId/:bidId?',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: BookNowQuotePage
    },
    {
      path: '/shipper-add-signature/:bidId',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: ShipperAddSignaturePage
    },
    {
      path: '/shipper-enroute-load-summary/track-shipment/:quoteId',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: TrackShipmentPage
    },
    {
      path: '/salesRep/enroute-quote-details/track-shipment/:quoteId',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: TrackShipmentPage
    },
    {
      path: '/shipper-enroute-load-summary/tracking/:quoteId',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: TrackingPage
    },
    {
      path: '/salesRep/enroute-quote-details/tracking/:quoteId',
      rolesAccess: [Roles.SHIPPER, Roles.SALES_REP, Roles.CUSTOMER_REP],
      element: TrackingPage
    },
    {
      path: '/carrier-add-signature/:quoteId/:bidId',
      rolesAccess: [Roles.CARRIER],
      element: CarrierAddSignaturePage
    }
  ]
};

export default routes;
