import React from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'antd';
import { FaCircleExclamation } from 'react-icons/fa6';
import { deleteAccount } from '../../services/shipper.service';
import { useAuthContext } from '../../contexts/auth.context';

const DeleteAccountButton = () => {
  const { confirm } = Modal;
  const { setAuthUser } = useAuthContext();

  const handleDelete = async () => {
    try {
      const response = await deleteAccount();
      const { flag, message } = response.data;

      if (flag) {
        setAuthUser({});
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        toast.success(message);
      } else {
        throw new Error('Something Went wrong');
      }
    } catch (error) {
      console.log(error.message);
      toast.error('Something went wrong!');
    }
  };

  const handleClick = () => {
    confirm({
      title: (
        <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
          Are you sure you want to delete your account?
        </span>
      ),
      icon: (
        <FaCircleExclamation
          style={{
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            color: 'red',
            paddingRight: '15px'
          }}
        />
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete();
      },
      centered: true
    });
  };
  return (
    <div className="text-left w-full md:w-9/12 py-2">
      <button
        type="button"
        onClick={handleClick}
        className="order-1 xl:order-0 mr-12 w-full 
              bg-red-500 border border-red-500 hover:bg-red-600 active:bg-red-700 text-white 
              hover:border-red-600 active:border-red-700 text-lg font-bold transition duration-200
              flex justify-between items-center space-x-2 py-2 px-4"
      >
        <span>Delete Account</span>
        <span className="pointer-events-none text-3xl">&times;</span>
      </button>
    </div>
  );
};

export default DeleteAccountButton;
