import React from 'react';

import { Link } from 'react-router-dom';

// import NETFooterLogo from '../../assets/images/app_logo.svg';
import Facebook from '../../assets/atis-assets/social/facebook.svg';
import Twitter from '../../assets/atis-assets/social/twitter.svg';
import Instagram from '../../assets/atis-assets/social/instagram.svg';
import currentYear from '../../constants/generalConstants';

const Footer = () => {
  return (
    <section>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10" />
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10" />
        </svg>
      </div>
      <div className=" bg-navy-500 radius-for-skewed bottom-0 ">
        <div className="container mx-auto px-4 border-b-0 border-l-0 border-r-0 border">
          {/* <div className="mb-6 w-full lg:w-full pt-10">
              <span className=" text-3xl font-bold leading-none ">
                <img
                  className="h-12 bg-white"
                  src={NETFooterLogo}
                  alt=""
                  width="auto"
                />
              </span>
            </div> */}
          <div className="flex flex-wrap py-8 ">
            <div className="mb-5 w-full lg:w-1/5">
              <h5 className="mb-4 font-bold text-white">About</h5>
              <p className="text-white leading-loose">
                <Link
                  to="https://nationwidetrans.com/contact-us/"
                  target="_blank"
                  rel="noopener"
                >
                  Office
                </Link>
              </p>
              <p className="text-white leading-loose">
                <Link
                  to="https://nationwidetrans.com/about-us/"
                  target="_blank"
                  rel="noopener"
                >
                  About us
                </Link>
              </p>
              <p className="text-white leading-loose">
                <Link
                  to="https://nationwidetrans.com/services/"
                  target="_blank"
                  rel="noopener"
                >
                  Services
                </Link>
              </p>
              <p className="text-white leading-loose">
                <Link to="/guest-tracking" rel="noopener">
                  Guest Tracking
                </Link>
              </p>
            </div>
            <div className="w-full lg:w-3/5 flex flex-wrap -mx-3">
              <div className="mb-6 w-full md:w-1/2 lg:w-2/4 lg:mr-6 px-3">
                <h5 className="mb-4 font-bold text-white">HQ</h5>
                <p className="text-white leading-loose">
                  <span className="font-bold">
                    Nationwide Equipment Transportation Inc.
                  </span>{' '}
                  <br />
                  10940 Parallel Pkwy Ste K Box 235
                  <br />
                  Kansas City, KS 66109
                </p>
              </div>
            </div>
            <div className="mb-5 w-full lg:w-1/5">
              <h5 className="mb-4 font-bold text-white">Contact Us</h5>
              <p className="text-white leading-loose">
                Email -{' '}
                <a href="mailto:info@nationwidetrans.com">
                  info@nationwidetrans.com
                </a>
              </p>
              <p className="text-white leading-loose">
                Phone - <a href="tel:+18669228855">(866) 922-8855</a>
              </p>
            </div>
          </div>
          <div className="flex space-x-2 lg:space-x-4 py-10">
            <Link
              to="https://www.facebook.com/NtwdEquipTrans/"
              target="_blank"
              rel="noopener"
            >
              <img src={Facebook} alt="" />
            </Link>
            <Link
              to="https://twitter.com/NtwdEquipTrans/"
              target="_blank"
              rel="noopener"
            >
              <img src={Twitter} alt="" />
            </Link>
            <Link
              to="https://www.instagram.com/ntwdequiptrans/"
              target="_blank"
              rel="noopener"
            >
              <img src={Instagram} alt="" />
            </Link>
          </div>
          <div className="w-full flex justify-between border-color-gray-400 pt-10 pb-10 border border-r-0 border-l-0 border-b-0">
            <p className="text-md text-white">
              Copyright © {currentYear} Nationwide Equipment Transportation.
              All rights reserved. |{' '}
              <Link
                to="https://nationwidetrans.com/terms-and-conditions/"
                target="_blank"
                rel="noopener"
              >
                Terms and conditions
              </Link>{' '}
              |{' '}
              <Link
                to="https://nationwidetrans.com/privacy-policy/"
                target="_blank"
                rel="noopener"
              >
                Privacy policy
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="skew skew-bottom mr-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10" />
          </svg>
        </div>
        <div className="skew skew-bottom ml-for-radius">
          <svg
            className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewBox="0 0 10 10"
            preserveAspectRatio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10" />
          </svg>
        </div> */}
    </section>
  );
};

export default Footer;
