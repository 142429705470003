import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';

import AddDelivery from './AddDelivery';
import {
  formatPickupDeliveryDate,
  formatPickupDeliveryTime
} from '../../../utils/date_conversion.util';
import { DELIVERY_SERVICE_OPTION } from '../../../constants/quote.constants';

const ShipperDelivery = ({
  deliveries,
  addDelivery,
  updateDelivery,
  removeDelivery,
  prevStep,
  formContent,
  nextClick,
  changedDeliveries,
  setChangedDeliveries
}) => {
  const [deliveryIndex, setDeliveryIndex] = useState(false);
  const [isAddMode, setisAddMode] = useState(true);
  const [deliveryData, setDeliveryData] = useState({});
  const [showDeliveryForm, setShowDeliveryForm] = useState(false);

  const {
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }, []);

  const onAddDelivery = async (data) => {
    if (isAddMode) {
      addDelivery({ ...data, id: data.delivery_id });
      setDeliveryData({});
    } else {
      updateDelivery(deliveryIndex, data);
      setisAddMode(true);
      setDeliveryIndex();
      setDeliveryData({});
    }
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  };

  const onDeleteCargo = (index) => {
    const deletedDeliveryAddress = getValues('deliveryInfo')[index];
    setChangedDeliveries([
      ...changedDeliveries,
      deletedDeliveryAddress.delivery_id
    ]);
    removeDelivery(index);
  };

  useEffect(() => {
    const quoteData = formContent?.two;
    if (quoteData.pickup_zip_code) {
      const deliveryInfo = {
        id: uuidv4(),
        delivery_date: quoteData.delivery_date,
        delivery_time: quoteData.delivery_time,
        delivery_close_time: quoteData.delivery_close_time,
        delivery_to: quoteData?.delivery_to,
        delivery_formatted_address: quoteData?.delivery_formatted_address,
        delivery_zip_code: quoteData?.delivery_zip_code,
        is_delivery_flexible: quoteData?.is_delivery_flexible,
        is_delivery_time_flexible: quoteData.delivery_time === '',
        delivery_special_instructions: quoteData?.delivery_special_instructions,
        delivery_service_options: quoteData.delivery_service_options,
        delivery_id: quoteData?.delivery_id
      };
      if (!deliveries?.length) setValue('deliveryInfo', [deliveryInfo]);
    }
    if (
      formContent?.three &&
      !formContent?.three?.deliveryInfo?.length &&
      formContent?.three?.delivery_zip_code
    ) {
      const data = {
        delivery_date: formContent?.two?.delivery_date,
        delivery_time: formContent?.two?.delivery_time,
        delivery_close_time: formContent?.two?.delivery_close_time,
        is_delivery_flexible: formContent?.two?.is_delivery_flexible,
        is_delivery_time_flexible: formContent?.two?.is_delivery_time_flexible,
        delivery_special_instructions:
          formContent?.two?.delivery_special_instructions,
        delivery_zip_code: formContent?.two?.delivery_zip_code,
        delivery_to: formContent?.two?.delivery_to,
        delivery_formatted_address:
          formContent?.two?.delivery_formatted_address,
        delivery_service_options: formContent?.two?.delivery_service_options,
        delivery_id: uuidv4()
      };
      onAddDelivery(data);
    }
  }, [formContent]);

  useEffect(() => {
    if (!deliveries.length && formContent?.one?.deliveryInfo?.length === 0) {
      setShowDeliveryForm(true);
      setisAddMode(true);
    }
  }, [deliveries]);

  const onEditClick = (index) => {
    setShowDeliveryForm(true);
    setisAddMode(false);
    setDeliveryIndex(index);
    setDeliveryData(getValues(`deliveryInfo.${index}`));
  };

  return (
    <>
      <div className="p-4 text-navy-500 flex flex-row items-start">
        <div
          onClick={prevStep}
          onKeyPress={() => {}}
          role="button"
          tabIndex="-1"
          className="pt-3"
        >
          <LeftArrowIcon className="w-6 h-6 text-navy-500 font-bold" />
        </div>
        <div>
          <h3 className="text-3xl font-bold ml-6">Select delivery option</h3>
          <p className="ml-6 text-black">
            Provide delivery location details for the most accurate quote.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-4">
        {deliveries.map((delivery, index) => {
          return (
            <React.Fragment key={delivery.id}>
              <div className="mb-6 mt-6 lg:mb-0 w-full lg:w-full px-3">
                <div className="p-6 flex flex-wrap bg-gray-100 border border-gray-100 shadow ">
                  <div className="">
                    <p className="mb-1 font-heading space-x-2">
                      <span className="font-semibold">Delivery address: </span>
                      {delivery?.delivery_formatted_address?.split('|')?.[0]}
                    </p>

                    <p className="mb-1 font-heading space-x-2">
                      <span className="font-semibold">Delivery date: </span>
                      {delivery?.delivery_date
                        ? formatPickupDeliveryDate(delivery?.delivery_date)
                        : 'Flexible date'}
                      ,<span className="font-semibold">Delivery time: </span>
                      {delivery?.delivery_time
                        ? formatPickupDeliveryTime(delivery?.delivery_time)
                        : 'Flexible time'}
                      ,
                      <span className="font-semibold">
                        Delivery close time:{' '}
                      </span>
                      {delivery?.delivery_close_time
                        ? formatPickupDeliveryTime(
                            delivery?.delivery_close_time
                          )
                        : 'Flexible time'}
                    </p>
                    <p>
                      <span className="font-semibold">
                        Delivery service options:{' '}
                      </span>

                      {delivery?.delivery_service_options
                        ?.map(
                          (pkup) =>
                            DELIVERY_SERVICE_OPTION?.find(
                              (e) => e.value === pkup
                            ).label
                        )
                        .join(', ')}
                    </p>
                  </div>
                  <div className="ml-auto mt-auto mb-auto">
                    <button
                      type="button"
                      className="lg:ml-auto lg:mr-3 py-3 px-6 
                                  bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                                hover:border-navy-600 active:border-navy-700
                                  transition duration-200 border round"
                      onClick={() => {
                        onEditClick(index);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className=" lg:ml-auto lg:mr-3 py-3 px-6 bg-transparent text-md
                                   text-navy-500 hover:text-navy-500 active:text-navy-600                    
                                  transition duration-200  round"
                      onClick={() => {
                        onDeleteCargo(index);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {errors.deliveries && (
                <div className="text-md text-center p-4 text-red-700">
                  {errors.deliveries.message}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <AddDelivery
        addDelivery={onAddDelivery}
        isAddMode={isAddMode}
        initialValues={deliveryData}
        updateDelivery={onAddDelivery}
        serviceType={formContent.one.service_type}
        nextClick={nextClick}
        showDeliveryForm={showDeliveryForm}
        setShowDeliveryForm={setShowDeliveryForm}
        changedDeliveries={changedDeliveries}
        setChangedDeliveries={setChangedDeliveries}
      />
    </>
  );
};

ShipperDelivery.propTypes = {
  deliveries: PropTypes.array,
  addDelivery: PropTypes.func.isRequired,
  updateDelivery: PropTypes.func.isRequired,
  removeDelivery: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formContent: PropTypes.any.isRequired,
  nextClick: PropTypes.any.isRequired,
  changedDeliveries: PropTypes.array.isRequired,
  setChangedDeliveries: PropTypes.func.isRequired
};

ShipperDelivery.defaultProps = {
  deliveries: []
};

export default ShipperDelivery;
