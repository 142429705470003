import React, { useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import toast from 'react-hot-toast';
import SignaturePad from 'signature_pad';

import { updateAddressService } from '../../services/quote.service';
import { acceptBidService } from '../../services/bid.service';

import { ReactComponent as RightArrowIcon } from '../../assets/images/right_arrow.svg';

const ShipperAddSignaturePage = () => {
  const { state } = useLocation();
  const { bidId } = useParams();
  const navigate = useNavigate();

  let signaturePad = null;

  function resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const canvasT = document.getElementById('signature-pad');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvasT.width = canvasT.offsetWidth * ratio;
    canvasT.height = canvasT.offsetHeight * ratio;
    canvasT.getContext('2d').scale(ratio, ratio);
  }

  useEffect(() => {
    resizeCanvas();
    const canvasObj = document.getElementById('signature-pad');

    signaturePad = new SignaturePad(canvasObj, {
      backgroundColor: 'rgb(255, 255, 255)',
      penColor: 'rgb(0, 0, 0)'
    });
    document.addEventListener('resize', resizeCanvas);
  }, []);

  const onClear = () => {
    signaturePad.clear();
  };

  const onConfirm = async () => {
    try {
      if (!signaturePad.isEmpty()) {
        const dataURL = signaturePad.toDataURL();

        // settrimmedDataURL(signaturePad.toDataURL());
        const fileResponse = await (await fetch(dataURL)).blob();
        const fileObj = new File([fileResponse], 'signature.png');
        const formData = new FormData();
        formData.append('id', state.id);
        formData.append('bidType', state.bidType);

        if (state.isMultipleQuote) {
          formData.append(
            'isMultipleQuote',
            JSON.stringify(state.isMultipleQuote)
          );
          formData.append('pickupInfo', state.pickupInfo);
          formData.append('deliveryInfo', state.deliveryInfo);
        } else {
          formData.append('pickup_date', JSON.stringify(state.pickup_date));
          formData.append('delivery_date', JSON.stringify(state.delivery_date));
          formData.append('delivery_address', state.delivery_address);
          formData.append('pickup_address', state.pickup_address);
          formData.append('pickup_time', JSON.stringify(state.pickup_time));
          formData.append(
            'pickup_close_time',
            JSON.stringify(state.pickup_close_time)
          );
          formData.append('delivery_time', JSON.stringify(state.delivery_time));
          formData.append(
            'delivery_close_time',
            JSON.stringify(state.delivery_close_time)
          );
        }

        formData.append('signature', fileObj);

        const response = await updateAddressService(formData);

        if (response.data.flag) {
          toast.success(response.data.message);
          const bidResponse = await acceptBidService({
            id: bidId,
            quoteId: state.id
          });
          if (bidResponse.data.flag) {
            toast.success(bidResponse.data.message);
            navigate('/', { replace: true });
          }
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  if (!state) {
    navigate('/active-shipment', { replace: true });
  }

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <div className="mb-6 px-3 p-4 relative">
          <h3 className="text-3xl font-bold text-navy-500">Signature</h3>
        </div>
        <div className="flex flex-wrap text-center">
          <div className="mb-8 w-full lg:w-1/2 px-3">
            <div className="relative bg-white shadow border h-full border-navy-500 ">
              <canvas
                id="signature-pad"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
          <div className="mb-8 w-full lg:w-1/2 px-3 -mt-4">
            <div className="text-left p-4 relative">
              <button
                type="button"
                onClick={onConfirm}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 text-sm font-bold 
                  bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                   transition duration-200 text-left"
              >
                Confirm
                <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-5 font-bold" />
              </button>
            </div>
            <div className="text-left p-4 relative">
              <button
                type="button"
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                  bg-white hover:bg-red-500  text-sm font-bold text-navy-500 hover:text-white 
                  border border-navy-500 hover:border-red-500 transition duration-200 text-left"
                onClick={onBackArrowClick}
              >
                Cancel
                <span className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-4 font-bold text-5xl">
                  &#215;
                </span>
              </button>
            </div>
            <div className="text-left p-4 relative">
              <button
                type="button"
                onClick={onClear}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                  bg-white hover:bg-navy-100  text-sm font-bold text-navy-500 hover:text-white border border-navy-500 transition duration-200 text-left"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShipperAddSignaturePage;
