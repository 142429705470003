import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import toast from 'react-hot-toast';

import { ReactComponent as AcceptedIcon } from '../../assets/images/check-circle.svg';
import QuoteIcon from '../../components/QuoteIcon';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import {
  getQuoteBidsService,
  declineBidService
} from '../../services/bid.service';
import { convertTimeStamp } from '../../utils/date_conversion.util';
import { BID_TYPE } from '../../constants/bids.constants';

const ShipperSubmissionList = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { QUOTE_FROM_NET, FIXED_AMOUNT, OPEN_BID } = BID_TYPE;

  const [isAPICalled, setAPICalled] = useState(false);
  const [bids, setbids] = useState([]);

  async function fetchQuoteBids() {
    try {
      const response = await getQuoteBidsService(quoteId);

      setbids(response.data.bids);
      setAPICalled(true);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchQuoteBids();
  }, [quoteId]);

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const onAcceptBid = (bidId) => {
    if (state.bidType === QUOTE_FROM_NET) {
      navigate(`/shipper-add-signature/${bidId}`, state);
    } else if (state.bidType === FIXED_AMOUNT || state.bidType === OPEN_BID) {
      navigate(`/book-now-quote/${quoteId}/${bidId}`);
    }
  };

  const onDeclineBid = async (bidId, customerId) => {
    try {
      const response = await declineBidService({ bidId, customerId });
      toast.success(response.data.message);
      onBackArrowClick();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  if (!state) {
    navigate('/active-shipment', { replace: true });
  }

  return (
    <section>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10" />
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10" />
        </svg>
      </div>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div className="mb-6  p-4 relative">
            <div
              tabIndex="-1"
              role="button"
              onKeyPress={() => {}}
              onClick={onBackArrowClick}
            >
              <LeftArrowIcon className="w-6 h-6 absolute inset-y-0 left-0 flex items-center mt-6 text-navy-500" />
            </div>
            <h3 className="text-3xl text-navy-500 font-bold ml-4">
              Submission
            </h3>
          </div>

          {isAPICalled ? (
            <div className="flex flex-wrap -mx-3">
              {bids.map((bid) => {
                return (
                  <div
                    className="mb-6 mt-6 lg:mb-0 w-full lg:w-full px-3"
                    key={bid.id}
                  >
                    <div className="p-6 flex flex-wrap bg-gray-100 shadow ">
                      <div>
                        <QuoteIcon />
                      </div>
                      <div className="w-full lg:w-2/3 text-black">
                        <h3 className="mb-1 text-xl font-bold font-heading">
                          {bid.carrierId.name}
                          <span className="text-sm ml-1 font-medium">
                            {convertTimeStamp(bid.createdAt)}
                          </span>
                        </h3>

                        <p className="mb-2">{bid.carrierId.company}</p>
                        <h3 className="mb-2 text-xl font-bold font-heading">
                          Budget: $
                          {bid?.amount
                            ? (bid.amount + bid.tarps_value).toFixed(2)
                            : '0.00'}
                        </h3>
                        <p>
                          Email:{' '}
                          <span className="font-bold ml-1">
                            {bid.carrierId.email}
                          </span>
                        </p>
                        <p>
                          From:{' '}
                          <span className="font-bold ml-1">
                            {bid.pickup_zip_code}
                          </span>
                        </p>
                        <p>
                          To:{' '}
                          <span className="font-bold ml-6">
                            {bid.delivery_zip_code}
                          </span>
                        </p>
                      </div>
                      {bid.status === 1 && (
                        <div className="ml-auto mt-auto mb-auto mr-auto">
                          <button
                            type="button"
                            onClick={() => {
                              onAcceptBid(bid.id);
                            }}
                            className=" lg:ml-auto lg:mr-3 py-3 px-6
                                  bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                                hover:border-navy-600 active:border-navy-700 text-md transition duration-200 border round"
                          >
                            Accept
                          </button>
                          <button
                            type="button"
                            className=" lg:ml-auto lg:mr-3 py-3 px-6 bg-transparent
                                   text-navy-500 hover:text-navy-500 active:text-navy-600 text-md transition duration-200  round"
                            onClick={() => {
                              onDeclineBid(bid.id, bid.customerId);
                            }}
                          >
                            Decline
                          </button>
                        </div>
                      )}

                      {bid.status === 2 && (
                        <div className="ml-auto mt-auto mb-auto mr-auto">
                          <div className=" lg:ml-auto lg:mr-3 py-3 px-6 bg-transparent hover:bg-gray-100 text-md text-gray-900 transition duration-200 round relative">
                            <AcceptedIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 flex items-center px-3 text-black  font-bold mr-2 inset-x-0 mt-1 -ml-2" />
                            Accepted
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="skew skew-bottom mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10" />
        </svg>
      </div>
      <div className="skew skew-bottom ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 10 10" />
        </svg>
      </div>
    </section>
  );
};

export default ShipperSubmissionList;
