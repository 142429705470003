import React, { useEffect, useState } from 'react';
import { parse } from 'tldts';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import BasicDetailsForm from './BasicDetailsForm';
import AddressDetailsForm from './AddressDetailsForm';
import {
  checkSignupLinkService,
  shipperSignUpService,
  activateUserService
} from '../../../services/shipper.service';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';

const ShipperSignupPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('referralCode');
  const navigate = useNavigate();
  const { token } = useParams();
  const [user, setUser] = useState();
  const [step, setStep] = useState(1);
  const [compiledForm, setCompiledForm] = useState({});
  const [isVerifyMode, setVerifyMode] = useState(false);
  const [isExistingCompany, setIsExistingCompany] = useState(false);
  const [isNewCompany, setIsNewCompany] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);

  const validationSchema = [
    // validation for step1
    yup.object({
      name: yup.string().required('Shipper name is required'),
      email: yup
        .string()
        .required('Email is required!')
        .email('Please enter a valid email.')
        .test('is-valid-tld', 'Please enter a valid email.', (value) => {
          if (!value) return false;
          const { isIcann } = parse(value);
          return isIcann;
        }),
      phone: yup
        .number()
        .typeError('Mobile number must be a number')
        .integer('Mobile number must be an integer')
        .positive('Mobile number must be a positive number')
        .required('Mobile number is required')
        .min(1000000000, 'Mobile number must be at least 10 digits')
        .max(9999999999, 'Mobile number can be at most 10 digits')
        .test('is-num', 'Mobile number must be numeric', (value) => {
          return /^[0-9]*$/.test(value.toString());
        }),
      password: yup.string().required('Password is required'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Please make sure your passwords match!')
    }),
    // validation for step2
    yup.object({
      company: yup.string().required('Company name is required'),
      address1: yup.string().required('Address Line 1 is required'),
      address2: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      country: yup.string().required('Country is required').default('US'),
      zipcode: yup.string().required('Zip code is required')
    })
  ];

  const currentValidationSchema = validationSchema[step - 1];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid },
    reset,
    trigger
  } = methods;
  const form = watch();

  const prevStep = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
      switch (step) {
        case 2:
          setCompiledForm({ ...compiledForm, two: form });
          setIsNewCompany(false);
          break;

        default:
          break;
      }
    }
  };

  const nextStep = async () => {
    let isStepValid;
    switch (step) {
      case 1: {
        isStepValid = await trigger(
          ['name', 'email', 'phone', 'password', 'confirmPassword'],
          {
            shouldFocus: true
          }
        );
        break;
      }
      case 2: {
        isStepValid = await trigger([
          'company',
          'address1',
          'address2',
          'city',
          'state',
          'country',
          'zipcode'
        ]);
        break;
      }
      default:
        break;
    }

    if (isStepValid || isValid) {
      switch (step) {
        case 1: {
          setCompiledForm({ ...compiledForm, one: form });

          if (isNewCompany) {
            setStep((prev) => prev + 1);
          }
          break;
        }
        case 2: {
          setCompiledForm({ ...compiledForm, two: form });
          break;
        }
        default:
          break;
      }
    }
  };

  const GetStepContent = (formContent) => {
    switch (step) {
      case 1:
        return (
          <BasicDetailsForm
            {...formContent}
            isVerifyMode={isVerifyMode}
            setIsExistingCompany={setIsExistingCompany}
            setIsNewCompany={setIsNewCompany}
            nextStep={nextStep}
            setStep={setStep}
            setCurrentCompany={setCurrentCompany}
          />
        );

      case 2:
        return <AddressDetailsForm prevStep={prevStep} {...formContent} />;

      default:
        break;
    }
  };

  const onSubmitUser = async () => {
    try {
      let body;
      if (isExistingCompany) {
        body = {
          name: form.name,
          email: form.email,
          companyId: currentCompany,
          password: form.password,
          phone: form.phone,
          referralCode,
          isVerifyMode
        };
      } else {
        body = {
          name: form.name,
          email: form.email,
          company: {
            name: form.company,
            address: {
              address1: form.address1,
              address2: form.address2,
              city: form.city,
              state: form.state,
              zipcode: form.zipcode
            }
          },
          password: form.password,
          phone: form.phone,
          referralCode,
          isVerifyMode
        };
      }

      if (isVerifyMode) {
        const response = await activateUserService(user.id, {
          ...body,
          token
        });
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          navigate('/login');
        } else {
          toast.error(response?.data?.message);
          navigate('/shipper-signup');
        }
      } else {
        const response = await shipperSignUpService(
          {
            ...body,
            version: process.env.REACT_APP_APP_VERSION
          },
          isNewCompany
        );
        if (response.data.flag) {
          toast.success(response.data.message);
          navigate('/login');
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  async function fetchUserDetails() {
    try {
      const response = await checkSignupLinkService(token);

      if (response?.status !== 200) return toast.error(response?.data?.message);
      const userData = response?.data?.user;
      setUser(userData);
      reset({
        name: userData.name,
        email: userData.email,
        company: userData.company,
        phone: userData.phone,
        address1: userData?.address?.address1,
        address2: userData?.address?.address2,
        city: userData?.address?.city,
        state: userData?.address?.state,
        zipcode: userData?.address?.zipcode,
        referralCode: userData?.salesRepId?.referralCode
      });
      setVerifyMode(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }
  useEffect(() => {
    if (token) {
      fetchUserDetails();
    }
  }, []);

  useEffect(() => {
    setIsButtonEnabled(isNewCompany || isExistingCompany);
  }, [isExistingCompany, isNewCompany]);
  useEffect(() => {
    nextStep();
  }, [isNewCompany]);

  return (
    <section className="py-10 lg:py-20">
      <div className="container mx-auto">
        <div className=" mx-auto">
          <div className="mb-6 lg:mb-10 text-left relative">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmitUser)}>
                {GetStepContent(compiledForm)}

                <div className="text-left p-4 relative">
                  <button
                    type="submit"
                    disabled={!isButtonEnabled}
                    onClick={nextStep}
                    className={`order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                   ${!isButtonEnabled ? 'bg-gray-400 border-gray-400 cursor-not-allowed' : 'bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700'} border text-white
                 text-sm font-bold transition duration-200 text-left`}
                  >
                    Sign Up
                    <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShipperSignupPage;
