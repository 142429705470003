/* eslint-disable no-param-reassign */
import React, { useState, useEffect, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Pagination from '../Pagination';
import { updateSettings } from '../../services/salesrep.service';
import { getDashboardTableData } from '../../services/quote.service';
import { VENDORS } from '../../constants/carrier.constants';
import {
  SalesRepColumns,
  ShipperColumns
} from '../../constants/columns.constants';
import { BID_TYPE, BID_STATUS } from '../../constants/bids.constants';
import { ReactComponent as FiltersIcon } from '../../assets/images/filters.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/settings.svg';
import DashboardSettings from '../DashboardSettings';
import SalesRepFilters from '../SalesRepFilters';
import { QUOTE_STATUS } from '../../constants/quote.constants';
import { additionalCharges, getInsuranceMarkup } from '../../utils/quote.util';
import { useAuthContext } from '../../contexts/auth.context';
import Roles from '../../constants/roles.constants';
import { updateUserSettings } from '../../services/shipper.service';

const Shipments = memo(({ allSalesRep = [] }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);

  const [totalCount, setTotalCount] = useState(0);

  const [isAPICalled, setisAPICalled] = useState(false);

  const filtersObj = queryString.parse(search, { arrayFormat: 'bracket' });

  const [quotes, setQuotes] = useState([]);
  const [filters, setfilters] = useState(() => {
    return search.length ? filtersObj : {};
  });

  const [showModal, setshowModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [columnsIdx, setColumnsIdx] = useState([]);
  const [dragOver, setDragOver] = useState('');
  const [quotesFetched, setQuotesFetched] = useState(false);

  const toggleModal = () => setshowModal((prevModal) => !prevModal);
  const settingsToggleModal = () => setSettingsModal((prevModal) => !prevModal);

  const { QUOTE_FROM_NET, OPEN_BID } = BID_TYPE;
  const { ENROUTE_QUOTE, DELIVERED_QUOTE } = BID_STATUS;

  const { authUser } = useAuthContext();

  const [sorting, setSorting] = useState({
    key: 'createdAt',
    ascending: null
  });

  const fetchAllQuotes = debounce(async () => {
    try {
      const apiResponse = await getDashboardTableData(
        recordsPerPage,
        currentPage,
        filters,
        sorting
      );
      const response = apiResponse.data.quotes;
      const { settingsData } = apiResponse.data;

      const sanitizedSettingsData = settingsData
        ? settingsData.filter((item) => item !== 'user' && item !== null)
        : [];

      if (apiResponse.data.flag) {
        const carriers = VENDORS.map((vendor) => vendor.name);
        const quoteArr = [];
        response?.forEach((quote) => {
          if (quote.service_type !== 'LTL Freight') {
            if (quote.service_type === 'Blanket Wrap') {
              quote.carrier_type = quote?.bwrapCarrier
                ? quote.bwrapCarrier?.toUpperCase()
                : 'CRST'; // eslint-disable-line no-param-reassign
            } else if (
              [ENROUTE_QUOTE, DELIVERED_QUOTE].includes(quote.bidStatus)
            )
              quote.carrier_type = quote?.bid?.[0]?.carrier?.[0]?.company;
            if (quote.isMultipleQuote) {
              let pickupDate = '';
              let pickupZip = '';
              let deliveryDate = '';
              let deliveryZip = '';
              quote?.pickupInfo?.forEach((pick) => {
                pickupDate += ` ${pick.pickup_date},`;
                pickupZip += ` ${pick.pickup_zip_code},`;
              });
              quote?.deliveryInfo?.forEach((pick) => {
                deliveryDate += ` ${pick?.delivery_date},`;
                deliveryZip += ` ${pick?.delivery_zip_code},`;
              });
              quote.pickup_date = pickupDate;
              quote.pickup_zip_code = pickupZip;
              quote.delivery_date = deliveryDate;
              quote.delivery_zip_code = deliveryZip;
            }
            if (quote.estimate || quote?.amount) {
              quoteArr.push({
                ...quote,
                estimate:
                  quote.bidType !== QUOTE_FROM_NET
                    ? quote.amount
                    : quote.estimate,
                commission:
                  quote.bidType !== QUOTE_FROM_NET
                    ? quote.netCharge
                    : quote.commission,
                trackingNumber:
                  quote && quote.tracking_details
                    ? quote?.tracking_details[0]?.trackingNumber
                    : ''
              });
            }
          } else {
            const rates = [];
            carriers?.forEach((carrier) => {
              const rate = quote[carrier]?.estimate;
              if (rate && rate !== 0) rates?.push(rate);
            });
            const quoteData = { ...quote };
            const min = Math.min(...rates);
            quoteData.minRate = min === Infinity ? 0 : min;
            quoteData.carrier_type = quoteData?.carrier_type?.toUpperCase();
            quoteData.trackingNumber =
              quoteData?.tracking_details?.[0]?.trackingNumber;
            if (quoteData?.minRate) {
              quoteArr.push(quoteData);
            }
          }
        });
        setQuotes(quoteArr);
        setQuotesFetched(!quotesFetched);
      }

      let defaultColumns;

      if (authUser.user.usertype === Roles.SHIPPER) {
        defaultColumns = ShipperColumns.map((c) =>
          c.isDefault ? c.value : null
        ).filter((e) => e);
      } else {
        defaultColumns = SalesRepColumns.map((c) =>
          c.isDefault ? c.value : null
        ).filter((e) => e);
      }
      const columnArray = sanitizedSettingsData?.length
        ? sanitizedSettingsData
        : defaultColumns;
      setColumns(columnArray);

      let arranged;
      if (authUser.user.usertype === Roles.SHIPPER) {
        arranged = columnArray?.map((col) =>
          ShipperColumns.find((c) => c?.value === col)
        );
      } else {
        arranged = columnArray?.map((col) =>
          SalesRepColumns.find((c) => c?.value === col)
        );
      }
      setColumnsIdx(arranged);
      setTotalCount(apiResponse.data.totalQuotes);
      setisAPICalled(true);
    } catch (error) {
      console.log({ error });
      toast.error(error.message ?? 'Something Went Wrong!');
    }
  }, 1);

  const onApplyFilters = (filtersData) => {
    try {
      toggleModal();
      setfilters(filtersData);
      setCurrentPage(1);

      // setCurrentPage(1);
      // fetchFiltersData();
      const query = queryString.stringify(filtersData, {
        arrayFormat: 'bracket'
      });

      const url =
        authUser.user.usertype !== Roles.SHIPPER
          ? `/dashboard?${query}`
          : `/shipper-dashboard?${query}`;

      navigate(url, { replace: true });
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onClearAllFilters = () => {
    setfilters({});
    setCurrentPage(1);

    const url =
      authUser.user.usertype !== Roles.SHIPPER
        ? '/dashboard'
        : '/shipper-dashboard';
    navigate(url, { replace: true });
    // fetchAllQuotes();
  };

  useEffect(() => {
    fetchAllQuotes();
  }, [currentPage, filters, sorting]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onApplySetting = (columnData) => {
    setColumns(columnData || []);

    let arranged;
    if (authUser.user.usertype === Roles.SHIPPER) {
      arranged = columnData?.map((col) =>
        ShipperColumns.find((c) => c?.value === col)
      );
    } else {
      arranged = columnData.map((col) =>
        SalesRepColumns.find((c) => c.value === col)
      );
    }
    setColumnsIdx(arranged || []);
  };

  function applySorting(key, ascending) {
    setSorting({ key, ascending });
  }

  function salesRepClickHandler(quote) {
    const statusFinder = QUOTE_STATUS.find(
      (bid) =>
        bid.value.includes(quote?.bidStatus) &&
        bid.booked === quote.booked &&
        bid.isPickupByCarrier === quote.isPickupByCarrier
    )?.label;

    switch (statusFinder) {
      case 'Recent':
      case 'Pending':
      case 'Delivered':
        navigate(`/salesRep/quote-details/${quote.id}`);
        break;
      case 'Assigned':
      case 'Enroute':
        navigate(`/salesRep/enroute-quote-details/${quote.id}`);
        break;
      default:
        break;
    }
  }

  function shipperClickHandler(quote) {
    const statusFinder = QUOTE_STATUS.find(
      (bid) =>
        bid.value.includes(quote?.bidStatus) &&
        bid.booked === quote.booked &&
        bid.isPickupByCarrier === quote.isPickupByCarrier
    )?.label;

    switch (statusFinder) {
      case 'Recent':
      case 'Pending':
      case 'Delivered':
        navigate(`/shipper-load-summary/${quote.id}`);
        break;
      case 'Assigned':
      case 'Enroute':
        navigate(`/shipper-enroute-load-summary/${quote.id}`);
        break;
      default:
        break;
    }
  }

  const handleDragStart = (e) => {
    const { id } = e.target;
    const idx = columnsIdx?.findIndex((col) => col?.value === id);
    e.dataTransfer.setData('colIdx', idx);
  };

  const handleDragEnter = (e) => {
    const { id } = e.target;
    setDragOver(id);
  };
  // getInsuranceMarkup()
  const handleOnDrop = async (e) => {
    const { id } = e.target;
    const droppedColIdx = columnsIdx?.findIndex((col) => col?.value === id);
    const draggedColIdx = e?.dataTransfer?.getData('colIdx');
    const tempCols = [...columnsIdx];

    tempCols[draggedColIdx] = columnsIdx[droppedColIdx];
    tempCols[droppedColIdx] = columnsIdx[draggedColIdx];
    setColumnsIdx(tempCols);
    setDragOver('');
    const arrangedColumns = tempCols?.map((col) => col?.value);
    setColumns(arrangedColumns || []);

    if (authUser.user.usertype === Roles.SHIPPER) {
      await updateUserSettings({ columns: arrangedColumns });
    } else {
      await updateSettings({ columns: arrangedColumns });
    }
  };

  return (
    <div className="w-full">
      <div className="mb-6 p-4 pl-0 w-full md:w-6/12 inline-block">
        <h3 className="text-3xl font-bold inline-block text-navy-500">
          Shipments
        </h3>
      </div>
      <div className="mb-6 p-4 pr-0 w-full md:w-6/12 inline-block text-right items-center space-x-4">
        {Object.keys(filters).length > 0 && (
          <button
            type="button"
            className="mr-4 underline "
            onClick={onClearAllFilters}
          >
            <span className="text-red-600 font-bold">Clear All</span>
          </button>
        )}

        <button
          type="button"
          onClick={toggleModal}
          className="relative inline-block pl-10 mb-2 py-3 pr-4 bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700 text-base font-bold text-left"
        >
          <FiltersIcon className="w-12 h-12 pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 mr-4 font-bold" />
          Filters
        </button>
        <button
          type="button"
          onClick={settingsToggleModal}
          className="relative inline-block pl-10 mb-2 py-3 pr-4 bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700 text-base font-bold text-left"
        >
          <SettingsIcon className="w-12 h-12 pointer-events-none absolute inset-y-0 left-0 flex items-center cetnter px-2 pr-4 font-bold text-center" />
          Settings
        </button>
      </div>

      <div className="w-full inline-block overflow-x-scroll">
        <table className="table-auto w-full text-left md:text-overflow: clip; overflow: hidden; white-space: nowrap">
          <thead className="text-gray-400 mb-2">
            <tr>
              {columnsIdx?.map((column) => {
                return (
                  columns.includes(column?.value) && (
                    <th
                      id={column?.value}
                      key={column?.value}
                      className="font-light text-center pb-2 pl-2"
                      onClick={() =>
                        applySorting(column?.value, !sorting.ascending)
                      }
                      draggable
                      onDragStart={handleDragStart}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={handleOnDrop}
                      onDragEnter={handleDragEnter}
                      dragOver={column?.value === dragOver}
                    >
                      {column?.displayName}
                      {sorting.ascending === null ||
                      sorting.key !== column?.value ? (
                        <span className="text-xs  ml-2 opacity-50">▲</span>
                      ) : (
                        <span className="text-xs  ml-2 text-black">
                          {sorting.ascending ? '▲' : '▼'}
                        </span>
                      )}
                    </th>
                  )
                );
              })}
            </tr>
          </thead>
          <tbody className="border border-gray-300 p-2">
            {isAPICalled ? (
              <>
                {quotes?.map((quote) => {
                  return (
                    <tr
                      key={quote.id}
                      onClick={() =>
                        authUser.user.usertype === Roles.SHIPPER
                          ? shipperClickHandler(quote)
                          : salesRepClickHandler(quote)
                      }
                      className="border-b text-center border-gray-300 hover:bg-blue-200 cursor-pointer"
                    >
                      {columnsIdx?.map((column) => {
                        return (
                          <React.Fragment key={column?.value}>
                            {columns.includes(column?.value) &&
                              (column?.value === 'estimate' ? (
                                <td className="py-4">
                                  {quote.service_type === 'LTL Freight' &&
                                  !quote.booked
                                    ? `$ ${quote.minRate.toFixed(2)} (min)`
                                    : `${
                                        quote.bidType === OPEN_BID
                                          ? 'Open Bid'
                                          : `$ ${(
                                              quote.estimate +
                                              (quote.commission || 0) +
                                              (quote.tarps_value || 0) +
                                              getInsuranceMarkup(
                                                quote.is_insurance_required,
                                                quote.insurance_value
                                              ) +
                                              additionalCharges(quote)
                                                .revenueCharge
                                            ).toFixed(2)}`
                                      }`}
                                </td>
                              ) : (
                                <td className="py-4">
                                  {column?.value === 'bidStatus'
                                    ? QUOTE_STATUS.find(
                                        (bid) =>
                                          bid.value.includes(
                                            quote?.[column?.value]
                                          ) &&
                                          bid.booked === quote.booked &&
                                          bid.isPickupByCarrier ===
                                            quote.isPickupByCarrier
                                      )?.label
                                    : quote[column?.value]}
                                </td>
                              ))}
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
                {!totalCount && (
                  <tr className="border-none p-3">
                    <td colSpan="7">
                      <h3 className="text-center p-3">No shipments found!</h3>
                    </td>
                  </tr>
                )}
              </>
            ) : (
              ''
            )}
          </tbody>
        </table>
      </div>
      <div className="px-4 py-3 flex items-end justify-end border-gray-200 sm:px-6">
        {totalCount > 0 && (
          <Pagination
            activePage={currentPage}
            perPage={recordsPerPage}
            total={totalCount}
            handlePageChange={(page) => handlePageChange(page)}
          />
        )}
      </div>
      {showModal && (
        <SalesRepFilters
          allSalesRep={allSalesRep}
          selectedFilters={filters}
          closeModal={toggleModal}
          onApply={onApplyFilters}
        />
      )}
      {settingsModal && (
        <DashboardSettings
          selectedFilters={columns}
          closeModal={settingsToggleModal}
          onApply={onApplySetting}
        />
      )}
    </div>
  );
});

export default Shipments;

Shipments.propTypes = {
  allSalesRep: PropTypes.array.isRequired
};
