import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useFormContext } from 'react-hook-form';

import toast from 'react-hot-toast';
import { FaDropbox } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import AddCargo from './AddCargo';

import { getPackageType } from '../../../utils/cargo.util';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';
// import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';
import { calculateDensity } from '../../../utils/quote.util';
import { useAuthContext } from '../../../contexts/auth.context';
import { getSavedCargosService } from '../../../services/shipper.service';
import CargoDefault from '../../../assets/images/cargo_default.png';
import Roles from '../../../constants/roles.constants';
import DummyImage from '../../../components/DummyImage/DummyImage';

const CargoList = ({
  prevStep,
  cargos,
  addCargo,
  updateCargo,
  removeCargo,
  formContent,
  nextStep,
  changedPickups,
  changedDeliveries,
  trigger
}) => {
  const {
    formState: { errors },
    getValues
  } = useFormContext();

  const { authUser } = useAuthContext();

  const [isAddMode, setisAddMode] = useState(true);
  const [cargoIndex, setcargoIndex] = useState();
  const [cargoData, setcargo] = useState({});
  const [selectedCargo, setSelectedCargo] = useState({});
  const [isSelectCargo, setSelectCargo] = useState(false);
  const [savedCargo, setSavedCargo] = useState([]);
  const [showAddNewCargoForm, setShowAddNewCargoForm] = useState(false);

  useEffect(() => {
    const cargosValues = getValues('cargos');
    cargosValues.forEach((cg, index) => {
      const cgShipFrom = cg.ship_from;
      const isPickupChanged = changedPickups.includes(cgShipFrom);

      const cgCopy = { ...cg };
      if (isPickupChanged) {
        cgCopy.ship_from = '';
      }

      const cgShipTo = cg.ship_to;
      const isDeliveryChanged = changedDeliveries.includes(cgShipTo);

      if (isDeliveryChanged) {
        cgCopy.ship_to = '';
      }
      updateCargo(index, cgCopy);
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }, []);

  const onAddCargo = async (data) => {
    if (isAddMode) {
      addCargo(data);
      setcargo({});
    } else {
      updateCargo(cargoIndex, data);
      setisAddMode(true);
      setcargoIndex();
      setcargo({});
      await trigger(['cargos']);
    }
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  };

  useEffect(() => {
    if (!cargos.length) {
      setShowAddNewCargoForm(true);
      setisAddMode(true);
    }
  }, [cargos]);

  const onEditClick = (index) => {
    setShowAddNewCargoForm(true);
    setisAddMode(false);
    setcargoIndex(index);
    setcargo(getValues(`cargos.${index}`));
  };

  const onDeleteCargo = (index) => {
    removeCargo(index);
  };

  const getSavedCargos = async () => {
    try {
      if (!getValues('cargos')?.length) {
        const companyId =
          authUser?.user?.usertype === Roles.SHIPPER
            ? authUser?.user?.companyId?.id
            : getValues('company')?.id;
        const response = await getSavedCargosService(companyId);
        const cgData = response.data.data;
        const defaultCg = cgData?.filter?.((cg) => cg.isDefault)?.[0];

        if (!formContent?.three?.cargos?.length && defaultCg) {
          setSelectedCargo(defaultCg);
        }
        setSavedCargo(cgData);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const changeCargo = (cg) => {
    setSelectCargo(false);
    setSelectedCargo(cg);
  };

  const clearFields = () => {
    setSelectedCargo({});
  };

  useEffect(() => {
    getSavedCargos();
  }, []);

  return (
    <div className="container mx-auto">
      <div className=" mx-auto">
        <div className="mb-6 lg:mb-10 text-left">
          <div>
            <div className="container mx-auto px-4">
              <div className="flex items-center justify-between">
                <div className="flex flex-row items-center gap-3 text-navy-500">
                  <div
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="-1"
                    onClick={prevStep}
                  >
                    <LeftArrowIcon className="w-6 h-6 text-navy-500 font-bold" />
                  </div>
                  <h3 className="text-2xl font-extrabold">Add New Cargo</h3>
                </div>
                <div className="inline-flex justify-end w-2/5 items-center">
                  <button
                    className="mr-6 text-sm"
                    type="button"
                    onClick={() => clearFields()}
                  >
                    <p className="underline text-navy-500 ">Clear All Fields</p>
                  </button>
                  <button
                    type="button"
                    onClick={() => setSelectCargo(true)}
                    className="flex py-2 px-2 gap-1 items-center h-10 whitespace-normal max-w-max border rounded-sm border-navy-500 hover:bg-navy-600 hover:text-white active:bg-navy-700 text-navy-500 
                      hover:border-navy-600 active:border-navy-700 text-sm break-words"
                  >
                    <FaDropbox size={20} />
                    Change Cargo
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3">
                {cargos.map((cargo, index) => {
                  return (
                    <React.Fragment key={cargo.id}>
                      <div className="mb-6 mt-6 lg:mb-0 w-full lg:w-full px-3">
                        <div className="p-6 flex flex-wrap bg-gray-100 border border-gray-100 shadow ">
                          {cargo.cargo_img ? (
                            <div>
                              <span className="mb-4 lg:mb-0 mr-6 inline-block p-1 md:p-1 ">
                                <img
                                  className="object-contain h-20 w-20"
                                  src={cargo.cargo_img}
                                  alt="cargo img"
                                />
                              </span>
                            </div>
                          ) : (
                            <div>
                              <DummyImage />
                            </div>
                          )}
                          <div className="">
                            <h3 className="mb-1 text-xl  font-heading">
                              {cargo.quantity} -{' '}
                              {getPackageType(cargo.package_type)}
                            </h3>
                            <h3 className="mb-1 text-xl font-heading">
                              {cargo.length} in x {cargo.width} in x{' '}
                              {cargo.height} in x {cargo.weight} lbs
                            </h3>
                            {cargo.length && cargo.height && cargo.width ? (
                              <p className="mb-1 font-heading space-x-2">
                                <span>
                                  Density: {calculateDensity(cargo).density}{' '}
                                  lb/ft
                                  <sup>3</sup>
                                </span>
                                <span>|</span>
                                <span>
                                  Cubic FT: {calculateDensity(cargo).cubitFeet}{' '}
                                  ft
                                  <sup>3</sup>
                                </span>
                              </p>
                            ) : null}
                            {cargo?.tarps ? (
                              <p className="text-md font-bold text-gray-500 ">
                                <span />
                                Note: Tarps required for this cargo.
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="ml-auto mt-auto mb-auto">
                            <button
                              type="button"
                              className=" lg:ml-auto lg:mr-3 py-3 px-6 
                                bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                              hover:border-navy-600 active:border-navy-700
                                transition duration-200 border round"
                              onClick={() => {
                                onEditClick(index);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className=" lg:ml-auto lg:mr-3 py-3 px-6 bg-transparent text-md
                                 text-navy-500 hover:text-navy-500 active:text-navy-600                    
                                transition duration-200  round"
                              onClick={() => {
                                onDeleteCargo(index);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                      {errors?.cargos && errors.cargos[index] && (
                        <div className="text-md text-center p-4 text-red-700">
                          {Object.keys(errors.cargos[index]).map((field) => (
                            <div key={uuidv4()}>
                              {errors.cargos[index][field]?.message}
                            </div>
                          ))}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="mt-8">
            <AddCargo
              addCargo={onAddCargo}
              isAddMode={isAddMode}
              initialValues={cargoData}
              updateCargo={onAddCargo}
              serviceType={formContent.one.service_type}
              nextStep={nextStep}
              selectedCargo={selectedCargo}
              showAddNewCargoForm={showAddNewCargoForm}
              setShowAddNewCargoForm={setShowAddNewCargoForm}
              onEditClick={onEditClick}
            />
            {isSelectCargo && (
              <div
                className="fixed inset-0 z-10 overflow-y-auto backdrop-filter 
                backdrop-brightness-50"
              >
                <div className="flex items-center min-h-screen px-2 py-4">
                  <div className="relative w-full max-w-lg py-8 mx-auto bg-white rounded-md shadow-lg">
                    <div className="text-center flex justify-between  mx-8">
                      <p className="text-navy-500 font-bold text-xl">
                        Please Select Cargo
                      </p>
                      <IoCloseSharp
                        className="cursor-pointer"
                        size={25}
                        onClick={() => setSelectCargo(false)}
                      />
                    </div>
                    {savedCargo?.map((cg) => {
                      return (
                        <div
                          className="flex flex-col justify-between bg-gray-100 my-6 p-3 mx-8"
                          key={cg?.cargo_id}
                        >
                          <label className="flex w-full">
                            <input
                              id="specialRadio"
                              type="radio"
                              value={cg?.id}
                              checked={cg?.id === selectedCargo?.id}
                              onChange={() => changeCargo(cg)}
                            />

                            <span className="flex gap-2">
                              <div className="inline-block">
                                {cg?.files?.fd ? (
                                  <img
                                    className="object-contain h-20 w-20"
                                    src={`${process.env.REACT_APP_API_URL}/${cg?.files?.fd}`}
                                    alt="cargo img"
                                    onError={(e) => {
                                      e.target.src = CargoDefault;
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="object-contain h-12 w-12"
                                    src={CargoDefault}
                                    alt="cargo img"
                                    onError={(e) => {
                                      e.target.src = CargoDefault;
                                    }}
                                  />
                                )}
                              </div>
                              <div className="ml-4">
                                <p>{cg?.quantity} - Box</p>
                                <p>
                                  {cg?.length} in x {cg?.width} in x{' '}
                                  {cg?.height} in x {cg?.weight} lbs
                                </p>
                              </div>
                            </span>
                          </label>
                          <div className="mx-8 mt-4 flex justify-between text-sm gap-4">
                            <span className="w-1/2">
                              <p className="pt-2">
                                Goods Condition -{' '}
                                <span className="font-bold capitalize">
                                  {cg?.goods_condition?.display_name}
                                </span>
                              </p>
                              <p className="pt-2">
                                Freight Class -{' '}
                                <span className="font-bold">
                                  {cg?.freight_class}
                                </span>
                              </p>
                            </span>
                            <span className="w-1/2">
                              <p className="pt-2">
                                Materials -{' '}
                                <span className="font-bold">
                                  {cg?.goods_type?.map(
                                    (type) => `${type?.display_name}, `
                                  ) || 'N/A'}
                                </span>
                              </p>
                              <p className="pt-2">
                                NMFC Code -{' '}
                                <span className="font-bold">
                                  {cg?.nmfc_code || 'N/A'}
                                </span>
                              </p>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {/* 
              <div className="text-left p-4 relative">
                <button
                  type="button"
                  onClick={nextStep}
                  disabled={!cargos.length}
                  className={`${
                    !cargos.length
                      ? 'bg-gray-400 border-gray-400 cursor-not-allowed'
                      : 'bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white hover:border-navy-600 active:border-navy-700'
                  } order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 border  text-sm font-bold text-white transition duration-200 text-left`}
                >
                  Proceed
                  <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-8 mt-6 font-bold" />
                </button>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CargoList;

CargoList.propTypes = {
  prevStep: PropTypes.func.isRequired,
  cargos: PropTypes.array,
  addCargo: PropTypes.func.isRequired,
  updateCargo: PropTypes.func.isRequired,
  removeCargo: PropTypes.func.isRequired,
  formContent: PropTypes.any.isRequired,
  nextStep: PropTypes.func.isRequired,
  changedPickups: PropTypes.array.isRequired,
  changedDeliveries: PropTypes.array.isRequired,
  trigger: PropTypes.func.isRequired
};

CargoList.defaultProps = {
  cargos: [
    // {
    //   quantity: 1,
    //   width: 48,
    //   package_type: '57f6397d95757c64129caeac',
    //   goods_condition: '57f638ef95757c64129caea6',
    //   weight: 500,
    //   files: '60ec455a9522221bd1e33990',
    //   height: 48,
    //   desc: 'Test Desctiption',
    //   goods_type: ['57f6395395757c64129caeaa'],
    //   length: 48,
    //   version: 'v3',
    // },
  ]
};
