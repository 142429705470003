import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import toast from 'react-hot-toast';
import moment from 'moment';
import { QUOTE_STATUS } from '../../constants/quote.constants';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import { getAllCompaniesBySalesRep } from '../../services/salesrep.service';
import { EquipmentTypes, States } from '../../constants/carrier.constants';
import { useAuthContext } from '../../contexts/auth.context';
import Roles from '../../constants/roles.constants';

const SalesRepFilters = ({
  allSalesRep,
  closeModal,
  onApply,
  selectedFilters
}) => {
  const [bidTypes, setBidTypes] = useState(
    selectedFilters?.bidType ? selectedFilters.bidType : []
  );
  const [zipcodes, setzipcodes] = useState({
    fromZipcode: selectedFilters?.fromZipcode
      ? selectedFilters.fromZipcode
      : '',
    toZipcode: selectedFilters?.toZipcode ? selectedFilters.toZipcode : ''
  });
  const [states, setStates] = useState({
    fromState: selectedFilters?.fromState || '',
    toState: selectedFilters?.toState || ''
  });
  const [durations, setdurations] = useState({
    pickupDateFrom: selectedFilters?.pickupDateFrom
      ? selectedFilters.pickupDateFrom
      : '',
    pickupDateTo: selectedFilters?.pickupDateTo
      ? selectedFilters.pickupDateTo
      : '',
    deliveryDateFrom: selectedFilters?.deliveryDateFrom
      ? selectedFilters.deliveryDateFrom
      : '',
    deliveryDateTo: selectedFilters?.deliveryDateTo
      ? selectedFilters.deliveryDateTo
      : ''
  });
  const [isPickupFlexible, setisPickupFlexible] = useState(
    selectedFilters?.isPickupFlexible ? selectedFilters.isPickupFlexible : false
  );
  const [isDeliveryFlexible, setisDeliveryFlexible] = useState(
    selectedFilters?.isDeliveryFlexible
      ? selectedFilters.isDeliveryFlexible
      : false
  );
  const [salesRep, setSalesRep] = useState(
    selectedFilters?.salesRep ? selectedFilters.salesRep : ''
  );
  const [filterSelected, setfilterSelected] = useState(false);
  const [customers, setCustomers] = useState();
  const [companyId, setCompanyId] = useState(null);
  const [equipmentTypes, setEquipmentTypes] = useState(
    selectedFilters?.equipmentTypes || []
  );
  const [description, setDescription] = useState(
    selectedFilters?.description || ''
  );
  const [nmfc, setNmfc] = useState(selectedFilters?.nmfc || '');

  const fromStateRef = useRef();
  const toStateRef = useRef();
  const { authUser } = useAuthContext();

  async function fetchAllUsers() {
    try {
      const response = await getAllCompaniesBySalesRep();
      setCustomers(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    if (authUser?.user?.usertype !== Roles.SHIPPER) {
      fetchAllUsers();
    }
  }, []);

  useEffect(() => {
    if (
      bidTypes.length ||
      zipcodes.fromZipcode ||
      zipcodes.toZipcode ||
      durations.pickupDateFrom ||
      durations.pickupDateTo ||
      durations.deliveryDateFrom ||
      durations.deliveryDateTo ||
      isPickupFlexible ||
      isDeliveryFlexible ||
      salesRep ||
      companyId ||
      equipmentTypes.length ||
      states.fromState ||
      states.toState ||
      description ||
      nmfc
    ) {
      setfilterSelected(true);
    } else {
      setfilterSelected(false);
    }
  }, [
    bidTypes,
    zipcodes,
    durations,
    isPickupFlexible,
    isDeliveryFlexible,
    salesRep,
    companyId,
    equipmentTypes,
    states,
    description,
    nmfc
  ]);

  const onClearFilters = () => {
    setBidTypes([]);
    setzipcodes({ fromZipcode: '', toZipcode: '' });
    setdurations({
      pickupDateFrom: '',
      pickupDateTo: '',
      deliveryDateFrom: '',
      deliveryDateTo: ''
    });
    setisPickupFlexible(false);
    setisDeliveryFlexible(false);
    setCompanyId(null);
    setEquipmentTypes([]);
    setDescription('');
    fromStateRef.current.select.clearValue();
    toStateRef.current.select.clearValue();
    setStates({
      fromState: '',
      toState: ''
    });
  };

  const onServiceChange = (e, service) => {
    if (e.target.checked) {
      setBidTypes((oldServices) => [...oldServices, service]);
    } else {
      setBidTypes(bidTypes.filter((s) => s !== service));
    }
  };

  const onSubmitFilters = () => {
    const filtersData = {};
    if (filterSelected) {
      if (bidTypes.length) {
        filtersData.bidType = bidTypes;
      }

      if (zipcodes.fromZipcode) {
        filtersData.fromZipcode = zipcodes.fromZipcode;
      }

      if (zipcodes.toZipcode) {
        filtersData.toZipcode = zipcodes.toZipcode;
      }

      if (durations.pickupDateFrom) {
        filtersData.pickupDateFrom = moment(durations.pickupDateFrom).format(
          'MM/DD/YYYY'
        );
      }

      if (durations.pickupDateTo) {
        filtersData.pickupDateTo = moment(durations.pickupDateTo).format(
          'MM/DD/YYYY'
        );
      }

      if (durations.deliveryDateFrom) {
        filtersData.deliveryDateFrom = moment(
          durations.deliveryDateFrom
        ).format('MM/DD/YYYY');
      }

      if (durations.deliveryDateTo) {
        filtersData.deliveryDateTo = moment(durations.deliveryDateTo).format(
          'MM/DD/YYYY'
        );
      }

      if (isPickupFlexible) {
        filtersData.isPickupFlexible = isPickupFlexible;
      }

      if (isDeliveryFlexible) {
        filtersData.isDeliveryFlexible = isDeliveryFlexible;
      }

      if (salesRep) {
        filtersData.salesRep = salesRep;
      }

      if (companyId) {
        filtersData.companyId = companyId;
      }

      if (states.fromState) {
        filtersData.fromState = states.fromState;
      }

      if (states.toState) {
        filtersData.toState = states.toState;
      }

      if (equipmentTypes.length) {
        filtersData.equipmentTypes = equipmentTypes;
      }

      if (description) {
        filtersData.description = description;
      }
      if (nmfc) {
        filtersData.nmfc = nmfc;
      }
    }
    onApply(filtersData);
  };
  return (
    <div
      className="fixed z-10 overflow-y-auto top-0 w-full h-full left-0"
      id="modal"
    >
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75" />
      </div>
      <div className="container mx-auto bg-gray-100 relative z-10">
        <div className="mb-6 lg:mb-10 text-left p-6">
          <div className="flex items-center mb-6  p-4 ">
            <div className="w-9/12">
              <h3 className="text-3xl font-bold inline-block text-navy-500">
                Filter
              </h3>
            </div>
            <div className="w-3/12 text-right">
              {filterSelected && (
                <button
                  type="button"
                  className="mr-4 underline"
                  onClick={onClearFilters}
                >
                  Clear All
                </button>
              )}

              <button
                type="button"
                onClick={closeModal}
                className="float-right close text-navy-500 bg-white"
              >
                <svg
                  className="h-6 w-6  cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <form action="">
            <div className="p-4">
              <label
                className="w-full block text-black text-xl  mb-6 font-bold"
                htmlFor="delivery-date"
              >
                Status
              </label>
              {QUOTE_STATUS.map((status) => {
                return (
                  <div
                    className="mb-4 w-full lg:w-1/5 float-left"
                    key={status.label}
                  >
                    <label>
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        name="checkbox-name"
                        value={status}
                        onChange={(e) => {
                          onServiceChange(e, status.label);
                        }}
                        checked={bidTypes.includes(status.label)}
                      />
                      <span className="ml-1">{status.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-10 clear-both p-4">
              <div>
                <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                  Zip code
                </p>
                <div className=" mb-10  w-1/3 float-left mr-10">
                  <input
                    placeholder="Ship from"
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                    type="text"
                    id="ship-from"
                    value={zipcodes.fromZipcode}
                    onChange={(e) => {
                      const re = /^[a-zA-Z0-9\s\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setzipcodes({
                          ...zipcodes,
                          fromZipcode: e.target.value
                        });
                      }
                    }}
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  />
                </div>
                <div className=" mb-10  w-1/3 float-left mr-10">
                  <input
                    placeholder="Ship to"
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                    type="text"
                    id="ship-to"
                    value={zipcodes.toZipcode}
                    onChange={(e) => {
                      const re = /^[a-zA-Z0-9\s\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setzipcodes({
                          ...zipcodes,
                          toZipcode: e.target.value
                        });
                      }
                    }}
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  />
                </div>
              </div>
              <div>
                <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                  State
                </p>
                <div className=" mb-10  w-1/3 float-left mr-10">
                  <Select
                    ref={fromStateRef}
                    options={States}
                    placeholder="Ship From"
                    onChange={(selectedOptions) =>
                      setStates({
                        ...states,
                        fromState: selectedOptions ? selectedOptions?.value : ''
                      })
                    }
                    isClearable
                    defaultValue={States.find(
                      (state) => state.value === states.fromState
                    )}
                    className="appearance-none w-full shadow text-xs font-semibold leading-none bg-white outline-none border-gray-200"
                  />
                </div>
                <div className=" mb-10  w-1/3 float-left mr-10">
                  <Select
                    ref={toStateRef}
                    options={States}
                    placeholder="Ship To"
                    onChange={(selectedOptions) =>
                      setStates({
                        ...states,
                        toState: selectedOptions ? selectedOptions?.value : ''
                      })
                    }
                    isClearable
                    defaultValue={States.find(
                      (state) => state.value === states.toState
                    )}
                    className="appearance-none w-full shadow text-xs font-semibold leading-none bg-white outline-none border-gray-200"
                  />
                </div>
              </div>
            </div>

            <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-10 clear-both p-4">
              <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                Date
              </p>
              <div className="mb-6 w-1/3 float-left mr-10">
                <DatePickerInput
                  name="pickupDateFrom"
                  handleDateChange={(val) =>
                    setdurations({ ...durations, pickupDateFrom: val })
                  }
                  selectedDate={
                    durations.pickupDateFrom &&
                    new Date(Date.parse(durations.pickupDateFrom))
                  }
                  placeholderText="Pickup Date From"
                  minDate=""
                />
                <div className="mb-3 py-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="field-name"
                      value={isPickupFlexible}
                      checked={isPickupFlexible}
                      onChange={(e) => setisPickupFlexible(e.target.checked)}
                    />

                    <span className="ml-1">Including Flexible Date</span>
                  </label>
                </div>
              </div>
              <div className="mb-6 w-1/3 float-left mr-10">
                <DatePickerInput
                  name="pickupDateTo"
                  handleDateChange={(val) =>
                    setdurations({ ...durations, pickupDateTo: val })
                  }
                  selectedDate={
                    durations.pickupDateTo &&
                    new Date(Date.parse(durations.pickupDateTo))
                  }
                  placeholderText="Pickup Date To"
                  minDate=""
                />
                <div className="mb-3 py-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="field-name"
                      value={isPickupFlexible}
                      checked={isPickupFlexible}
                      onChange={(e) => setisPickupFlexible(e.target.checked)}
                    />

                    <span className="ml-1">Including Flexible Date</span>
                  </label>
                </div>
              </div>
              <div className="mb-6 w-1/3 float-left mr-10">
                <DatePickerInput
                  name="deliveryDateFrom"
                  handleDateChange={(val) =>
                    setdurations({ ...durations, deliveryDateFrom: val })
                  }
                  selectedDate={
                    durations.deliveryDateFrom &&
                    new Date(Date.parse(durations.deliveryDateFrom))
                  }
                  placeholderText="Delivery Date From"
                  minDate=""
                />
                <div className="mb-3 py-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="field-name"
                      value={isDeliveryFlexible}
                      checked={isDeliveryFlexible}
                      onChange={(e) => setisDeliveryFlexible(e.target.checked)}
                    />
                    <span className="ml-1">Including Flexible Date</span>
                  </label>
                </div>
              </div>
              <div className="mb-6 w-1/3 float-left mr-10">
                <DatePickerInput
                  name="deliveryDateTo"
                  handleDateChange={(val) =>
                    setdurations({ ...durations, deliveryDateTo: val })
                  }
                  selectedDate={
                    durations.deliveryDateTo &&
                    new Date(Date.parse(durations.deliveryDateTo))
                  }
                  placeholderText="Delivery Date To"
                  minDate=""
                />
                <div className="mb-3 py-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="field-name"
                      value={isDeliveryFlexible}
                      checked={isDeliveryFlexible}
                      onChange={(e) => setisDeliveryFlexible(e.target.checked)}
                    />
                    <span className="ml-1">Including Flexible Date</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-start border border-gray-200 border-b-0 border-l-0 border-r-0 pt-10 clear-both p-4">
              <div className=" flex flex-col w-1/3 mr-10">
                {authUser?.user?.usertype !== Roles.SHIPPER && (
                  <div>
                    <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                      Company Name
                    </p>
                    <div className="mb-10 w-full float-left mr-10">
                      <Select
                        options={customers}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                          setCompanyId(e.id);
                        }}
                        className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
                      />
                    </div>
                  </div>
                )}
                <div>
                  <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                    Cargo Description
                  </p>
                  <div className="w-full float-left mr-10">
                    <input
                      value={description}
                      placeholder="Enter Description"
                      onChange={(e) => setDescription(e.target.value)}
                      className="appearance-none w-full shadow resize-none px-2 py-3 text-xs leading-none bg-white  outline-none border border-gray-200"
                    />
                  </div>
                </div>
              </div>
              <div className=" flex flex-col w-1/3 mr-10">
                <div>
                  <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                    Equipment Types
                  </p>
                  <div className="mb-10 w-full float-left mr-10">
                    <Select
                      options={EquipmentTypes}
                      isMulti
                      value={equipmentTypes.map((equipmentType) => ({
                        label: equipmentType,
                        value: equipmentType
                      }))}
                      onChange={(selectedOptions) => {
                        // Extract labels from selected options
                        const selectedLabels = selectedOptions.map(
                          (option) => option.label
                        );

                        // Update the equipmentTypes state with the new labels
                        setEquipmentTypes(selectedLabels);
                      }}
                      className="appearance-none w-full text-xs font-semibold leading-none bg-white outline-none border-gray-200"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                    NMFC
                  </p>
                  <div className="w-full mr-10">
                    <input
                      value={nmfc}
                      placeholder="Enter NMFC"
                      onChange={(e) => setNmfc(e.target.value)}
                      className="appearance-none w-full shadow resize-none px-2 py-3 text-xs leading-none bg-white  outline-none border border-gray-200 "
                    />
                  </div>
                </div>
              </div>
            </div>
            {allSalesRep?.length ? (
              <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-10 clear-both p-4">
                <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                  Sales Representatives
                </p>
                <div className=" mb-10  w-1/3 float-left mr-10">
                  <select
                    className="flex leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-2 px-2"
                    onChange={(e) => setSalesRep(e.target.value)}
                    onBlur=""
                    value={salesRep}
                  >
                    <option disabled selected value="">
                      Select Sales Representative
                    </option>
                    {allSalesRep?.map((option) => (
                      <option
                        value={option.id}
                        className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
                      >
                        {`${option.person} ${option.last_name}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : null}
            <div className="p-4 flex w-full justify-end">
              <div className="m-2">
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-10 mb-2 w-full py-4 
                    bg-white hover:bg-red-500  font-bold
                    text-navy-500 hover:text-white active:text-navy-600   
                    transition duration-200 text-center"
                >
                  Cancel
                </button>
              </div>
              <div className="m-2">
                <button
                  type="button"
                  onClick={() => {
                    onSubmitFilters();
                  }}
                  className="px-10 mb-2 w-full py-4 
                    bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700
                                hover:border-navy-600 active:border-navy-700
                     font-bold text-white
                    transition duration-200 text-center border "
                >
                  Apply
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SalesRepFilters;

SalesRepFilters.propTypes = {
  allSalesRep: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired
};
