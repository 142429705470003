import axios from 'axios';

import { find } from 'lodash';

// import { trackPromise } from 'react-promise-tracker';

const api = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api'
});

const GoogleMapsAPIKey = ' AIzaSyC_3WmDjIG2L4k8sm1P3lvcEt-_VgA4Bhg';

const getLatitudeLongitude = async (zipCode) => {
  // return api.get(
  //   `/geocode/json?components=postal_code:${zipCode}&country:US&key=${GoogleMapsAPIKey}`
  // );
  try {
    const response = await api.get(
      `/geocode/json?address=${encodeURIComponent(zipCode)}&key=${encodeURIComponent(GoogleMapsAPIKey)}`
    );

    switch (response.data.status) {
      case 'OK':
        return {
          lat: response.data.results[0].geometry.location.lat,
          lng: response.data.results[0].geometry.location.lng
        };

      default:
        throw new Error(response.data.status);
    }
  } catch (error) {
    return null;
  }
};

const getCityStateNames = async (zipCode) => {
  try {
    const response = await api.get(
      `/geocode/json?components=postal_code:${zipCode}|country:US&key=${GoogleMapsAPIKey}`
    );
    switch (response.data.status) {
      case 'OK': {
        const addressFormat =
          response.data.results[0].formatted_address.split(',');

        let cityName = addressFormat[0];
        cityName = cityName.replace('.', '');
        const cityCode = addressFormat[1].split(' ');
        const stateName = cityCode[1].trim();
        const { short_name: countryCode = '' } =
          response.data.results[0]?.address_components?.find((c) =>
            c.types.includes('country')
          ) || {};

        return {
          cityName,
          stateName,
          countryCode
        };
      }

      default:
        return null;
    }
  } catch (error) {
    return { cityName: '', stateName: '' };
  }
};

const standByGeoService = async (val, localities) => {
  try {
    const response = await api.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${val}&key=${GoogleMapsAPIKey}`
    );

    const record = response?.data?.results[0];
    const postalCode = find(record?.address_components, (ac) =>
      ac?.types?.includes('postal_code')
    )?.short_name;

    let city;
    localities?.forEach((local) => {
      const isExist = val.toLowerCase().includes(local.toLowerCase());
      if (isExist) {
        city = local;
      }
    });

    if (!city) {
      city = find(
        record?.address_components,
        (ac) =>
          ac?.types?.includes('locality') ||
          ac?.types?.includes('administrative_area_level_2')
      )?.short_name;
    }

    const state = find(record?.address_components, (ac) =>
      ac?.types?.includes('administrative_area_level_1')
    )?.short_name;

    const country = find(record?.address_components, (ac) =>
      ac?.types?.includes('country')
    )?.short_name;

    const place = {
      addr: val?.split(',')?.slice(0, -3)?.join(','),
      postcode: postalCode,
      city,
      state,
      country
    };

    return place;
  } catch (error) {
    console.log('error stand by service');
  }
};

const getAddressFromLatLong = async (lat, lng, val, localities) => {
  const res = await standByGeoService(val, localities);
  if (!res.postcode || !res.city || !res.state) {
    const response = await api.get(
      `/geocode/json?latlng=${lat},${lng}&key=${GoogleMapsAPIKey}`
    );

    switch (response.data.status) {
      case 'OK': {
        const record = find(
          response?.data?.results,
          (loc) =>
            loc.types.includes('premise') ||
            loc.types.includes('route') ||
            loc.types.includes('postal_code')
        );

        const postalCode = find(record?.address_components, (ac) =>
          ac?.types?.includes('postal_code')
        )?.short_name;

        const city = find(
          record?.address_components,
          (ac) =>
            ac?.types?.includes('locality') ||
            ac?.types?.includes('administrative_area_level_2')
        )?.short_name;

        const state = find(record?.address_components, (ac) =>
          ac?.types?.includes('administrative_area_level_1')
        )?.short_name;

        const country = find(record?.address_components, (ac) =>
          ac?.types?.includes('country')
        )?.short_name;

        const place = {
          addr: val?.split(',')?.slice(0, -3)?.join(','),
          postcode: postalCode,
          city,
          state,
          country
        };

        return place;
      }

      default:
        return null;
    }
  } else {
    return res;
  }
};

export {
  getLatitudeLongitude,
  getCityStateNames,
  getAddressFromLatLong,
  standByGeoService
};
