import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, ConfigProvider } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { parse } from 'tldts';
import { yupResolver } from '@hookform/resolvers/yup';
import { rolesOptions } from '../../constants/dashboard.constants';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import {
  getUser,
  updateShipperDataService,
  updateEmailPreferences
} from '../../services/shipper.service';

const EditUserPage = () => {
  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup
      .string()
      .required('Email is required!')
      .email('Please enter a valid email.')
      .test('is-valid-tld', 'Please enter a valid email.', (value) => {
        if (!value) return false;
        const { isIcann } = parse(value);
        return isIcann;
      }),
    phone: yup
      .number()
      .typeError('Mobile number must be a number')
      .integer('Mobile number must be an integer')
      .positive('Mobile number must be a positive number')
      .required('Mobile number is required')
      .min(1000000000, 'Mobile number must be at least 10 digits')
      .max(9999999999, 'Mobile number can be at most 10 digits')
      .test('is-num', 'Mobile number must be numeric', (value) => {
        return /^[0-9]*$/.test(value.toString());
      }),
    shipperNewQuoteNotify: yup.bool(),
    shipperShipmentBookNotify: yup.bool(),
    shipperShipmentDeliverNotify: yup.bool()
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const navigate = useNavigate();
  const { customerId } = useParams();

  const [currentRole, setCurrentRole] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const onSubmitForm = async (data) => {
    if (isValid) {
      const currentValues = {
        ...data,
        role: currentRole?.label
      };

      // NOTE: keeping this log for ease in debugging purpose.
      console.log({ initialValues, currentValues });
      const isFormChanged = Object.keys(initialValues).some(
        (key) => initialValues[key] !== currentValues[key]
      );

      if (!isFormChanged) {
        return toast.error('You have not made any changes!');
      }
      try {
        const response = await updateShipperDataService(customerId, {
          name: data?.name,
          email: data?.email,
          phone: data?.phone,
          role: currentRole?.value
        });

        // added this check for additional error safety
        if (!response?.data?.flag) {
          return toast.error(
            response?.data?.message ?? 'Something Went Wrong!'
          );
        }

        const emailRes = await updateEmailPreferences(customerId, {
          userEmailPreferences: {
            newQuoteNotify: data?.shipperNewQuoteNotify,
            shipmentBookNotify: data?.shipperShipmentBookNotify,
            shipmentDeliverNotify: data?.shipperShipmentDeliverNotify
          }
        });

        // added this check for additional error safety
        if (!emailRes?.data?.flag) {
          return toast.error(
            emailRes?.data?.message ?? 'Something Went Wrong!'
          );
        }

        toast.success('Details Updated Successfully!');
        setInitialValues(currentValues);
      } catch (error) {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? 'Something Went Wrong!');
      }
    } else {
      toast.error('Something Went Wronng!');
    }
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const fetchUserDetails = async () => {
    try {
      const response = await getUser(customerId);
      const userData = {
        name: response?.data?.name,
        email: response?.data?.email,
        phone: response?.data?.phone,
        shipperNewQuoteNotify:
          response?.data?.userEmailPreferences[0]?.newQuoteNotify,
        shipperShipmentBookNotify:
          response?.data?.userEmailPreferences[0]?.shipmentBookNotify,
        shipperShipmentDeliverNotify:
          response?.data?.userEmailPreferences[0]?.shipmentDeliverNotify
      };
      setInitialValues({ ...userData, role: response?.data?.role?.roleType });
      reset(userData);
      setCurrentRole({
        value: response?.data?.role?.id,
        label: response?.data?.role?.roleType
      });
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
    fetchUserDetails();
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="py-10 bg-gray-50 radius-for-skewed">
        <div className="container px-4 mx-auto">
          <div className="w-full flex flex-col gap-4  pb-6 mb-6 md:w-1/2 md:pr-12">
            <div className="relative mb-6 text-navy-500">
              <div
                tabIndex="-1"
                role="button"
                onKeyPress={() => {}}
                onClick={onBackArrowClick}
                className="flex items-center"
              >
                <LeftArrowIcon className="left-0 flex items-center w-6 h-6 " />
                <p className="ml-4">Back to Dashboard</p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-2 md:w-9/12">
              <label className="block tracking-wide">Name</label>
              <input
                {...register('name')}
                className="block w-full h-10 px-4 py-3 leading-tight border border-gray-300 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                type="text"
                placeholder="Enter Your Name"
              />
              {errors.name && (
                <span className="text-red-600 text-md">
                  {errors.name.message}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col gap-2 md:w-9/12">
              <label className="block tracking-wide">Email</label>
              <input
                {...register('email')}
                className="block w-full h-10 px-4 py-3 leading-tight border border-gray-300 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                type="text"
                placeholder="Enter Your Email"
              />
              {errors.email && (
                <span className="text-red-600 text-md">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col gap-2 md:w-9/12">
              <label className="block tracking-wide">Mobile Number</label>
              <input
                {...register('phone')}
                className="block w-full h-10 px-4 py-3 leading-tight border border-gray-300 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                type="text"
                placeholder="Enter Your Phone Number"
              />
              {errors.phone && (
                <span className="text-red-600 text-md">
                  {errors.phone.message}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col gap-2 md:w-9/12">
              <label className="block tracking-wide">Role</label>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      optionActiveBg: '#DEEBFF',
                      optionFontSize: 13,
                      borderRadius: 0
                    }
                  }
                }}
              >
                <Select
                  showSearch
                  style={{
                    width: 380,
                    height: 35
                  }}
                  defaultActiveFirstOption
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={rolesOptions}
                  value={currentRole}
                  onChange={(_, obj) => setCurrentRole(obj)}
                />
              </ConfigProvider>
            </div>
            <div className="flex flex-col my-8">
              <div className="font-bold text-2xl text-navy-500 underline">
                Email Preferences
              </div>

              <div className="flex flex-col">
                <div className="mt-4 w-full">
                  <div className="w-full mt-4 pb-4">
                    <label className="block mb-2 tracking-wide">
                      <input
                        type="checkbox"
                        {...register('shipperNewQuoteNotify')}
                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      />
                      <span className="ml-1 ">Receive new quote emails</span>
                    </label>
                    {errors.shipperNewQuoteNotify && (
                      <span className="text-red-700 text-md">
                        {errors.shipperNewQuoteNotify.message}
                      </span>
                    )}
                  </div>
                  <div className="w-full pb-4">
                    <label className="block mb-2 tracking-wide">
                      <input
                        type="checkbox"
                        {...register('shipperShipmentBookNotify')}
                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      />
                      <span className="ml-1">Receive shipment book emails</span>
                    </label>
                    {errors.shipperShipmentBookNotify && (
                      <span className="text-red-700 text-md">
                        {errors.shipperShipmentBookNotify.message}
                      </span>
                    )}
                  </div>
                  <div className="w-full pb-8">
                    <label className="block mb-2 tracking-wide">
                      <input
                        type="checkbox"
                        {...register('shipperShipmentDeliverNotify')}
                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      />
                      <span className="ml-1 ">
                        Receive shipment delivery emails
                      </span>
                    </label>
                    {errors.shipperShipmentDeliverNotify && (
                      <span className="text-red-700 text-md">
                        {errors.shipperShipmentDeliverNotify.message}
                      </span>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="bg-navy-500 text-white h-14 w-80 p-4 mt-8 text-lg"
                  >
                    Update User Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditUserPage;
