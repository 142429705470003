import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';

import AddPickup from './AddPickup';
import {
  formatPickupDeliveryDate,
  formatPickupDeliveryTime
} from '../../../utils/date_conversion.util';
import { PICKUP_SERVICE_OPTION } from '../../../constants/quote.constants';

const ShipperPickUP = ({
  pickups,
  addPickup,
  updatePickup,
  removePickup,
  prevStep,
  formContent,
  nextClick,
  changedPickups,
  setChangedPickups
}) => {
  const [pickupIndex, setPickupIndex] = useState(false);
  const [isAddMode, setisAddMode] = useState(true);
  const [pickupData, setPickupData] = useState({});
  const [showPickupForm, setShowPickupForm] = useState(false);

  const {
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }, []);

  const onAddPickup = async (data) => {
    if (isAddMode) {
      addPickup({ ...data, id: data.pickup_id });
      setPickupData({});
    } else {
      updatePickup(pickupIndex, data);
      setisAddMode(true);
      setPickupIndex();
      setPickupData({});
    }
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  };

  const onDeleteCargo = (index) => {
    const deletedPickupAddress = getValues('pickupInfo')[index];
    setChangedPickups([...changedPickups, deletedPickupAddress.pickup_id]);
    removePickup(index);
  };

  useEffect(() => {
    const quoteData = formContent?.one;
    if (quoteData.pickup_zip_code) {
      const pickupInfo = {
        id: uuidv4(),
        pickup_date: quoteData.pickup_date,
        pickup_time: quoteData.pickup_time,
        pickup_close_time: quoteData.pickup_close_time,
        pickup_from: quoteData?.pickup_from,
        pickup_formatted_address: quoteData?.pickup_formatted_address,
        pickup_zip_code: quoteData?.pickup_zip_code,
        is_pickup_flexible: quoteData?.is_pickup_flexible,
        is_pickup_time_flexible: quoteData.pickup_time === '',
        pickup_special_instructions: quoteData?.pickup_special_instructions,
        pickup_service_options: quoteData.pickup_service_options,
        pickup_id: quoteData.pickup_id
      };
      if (!pickups?.length) setValue('pickupInfo', [pickupInfo]);
    }
    if (
      formContent?.two &&
      !formContent?.two?.pickupInfo?.length &&
      formContent?.two?.pickup_zip_code
    ) {
      const data = {
        pickup_date: formContent?.two?.pickup_date,
        pickup_time: formContent?.two?.pickup_time,
        pickup_close_time: formContent?.two?.pickup_close_time,
        is_pickup_flexible: formContent?.two?.is_pickup_flexible,
        is_pickup_time_flexible: formContent?.two?.is_pickup_time_flexible,
        pickup_special_instructions:
          formContent?.two?.pickup_special_instructions,
        pickup_zip_code: formContent?.two?.pickup_zip_code,
        pickup_from: formContent?.two?.pickup_from,
        pickup_formatted_address: formContent?.two?.pickup_formatted_address,
        pickup_service_options: formContent?.two?.pickup_service_options,
        pickup_id: uuidv4()
      };
      onAddPickup(data);
    }
  }, []);

  useEffect(() => {
    if (!pickups.length && formContent?.one?.pickupInfo?.length === 0) {
      setShowPickupForm(true);
      setisAddMode(true);
    }
  }, [pickups]);

  const onEditClick = (index) => {
    setShowPickupForm(true);
    setisAddMode(false);
    setPickupIndex(index);
    setPickupData(getValues(`pickupInfo.${index}`));
  };
  return (
    <>
      <div className="p-4 text-navy-500 flex flex-row items-start">
        <div
          onClick={prevStep}
          onKeyPress={() => {}}
          role="button"
          tabIndex="-1"
          className="pt-3"
        >
          <LeftArrowIcon className="w-6 h-6 text-navy-500 font-bold" />
        </div>
        <div>
          <h3 className="text-3xl font-bold ml-6">Select delivery option</h3>
          <p className="ml-6 text-black">
            Provide pickup location details for the most accurate quote.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap mb-4">
        {pickups.map((pickup, index) => {
          return (
            <React.Fragment key={pickup?.id}>
              <div
                key={pickup.id}
                className="mb-6 mt-6 lg:mb-0 w-full lg:w-full px-3"
              >
                <div className="p-6 flex flex-wrap bg-gray-100 border border-gray-100 shadow ">
                  <div className="">
                    <p className="mb-1 font-heading space-x-2">
                      <span className="font-semibold">Pickup address: </span>
                      {pickup?.pickup_formatted_address?.split('|')?.[0]}
                    </p>

                    <p className="mb-1 font-heading space-x-2">
                      <span className="font-semibold">Pickup date: </span>
                      {pickup?.pickup_date
                        ? formatPickupDeliveryDate(pickup?.pickup_date)
                        : 'Flexible date'}
                      ,<span className="font-semibold">Pickup time: </span>
                      {pickup?.pickup_time
                        ? formatPickupDeliveryTime(pickup?.pickup_time)
                        : 'Flexible time'}
                      ,
                      <span className="font-semibold">Pickup close time: </span>
                      {pickup?.pickup_close_time
                        ? formatPickupDeliveryTime(pickup?.pickup_close_time)
                        : 'Flexible time'}
                    </p>
                    <p>
                      <span className="font-semibold">
                        Pickup service options:{' '}
                      </span>

                      {pickup?.pickup_service_options
                        ?.map(
                          (pkup) =>
                            PICKUP_SERVICE_OPTION?.find((e) => e.value === pkup)
                              ?.label
                        )
                        .join(', ')}
                    </p>
                  </div>
                  <div className="ml-auto mt-auto mb-auto">
                    <button
                      type="button"
                      className="lg:ml-auto lg:mr-3 py-3 px-6 
                      bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                      transition duration-200 border round"
                      onClick={() => {
                        onEditClick(index);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className=" lg:ml-auto lg:mr-3 py-3 px-6 bg-transparent text-md
                                   text-navy-500 hover:text-navy-500 active:text-navy-600                    
                                  transition duration-200  round"
                      onClick={() => {
                        onDeleteCargo(index);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {errors.pickups && (
                <div className="text-md text-center p-4 text-red-700">
                  {errors.pickups.message}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <AddPickup
        addPickup={onAddPickup}
        isAddMode={isAddMode}
        initialValues={pickupData}
        updatePickup={onAddPickup}
        serviceType={formContent.one.service_type}
        nextClick={nextClick}
        showPickupForm={showPickupForm}
        setShowPickupForm={setShowPickupForm}
        changedPickups={changedPickups}
        setChangedPickups={setChangedPickups}
      />
    </>
  );
};

ShipperPickUP.propTypes = {
  pickups: PropTypes.array,
  addPickup: PropTypes.func.isRequired,
  updatePickup: PropTypes.func.isRequired,
  removePickup: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formContent: PropTypes.any.isRequired,
  nextClick: PropTypes.any.isRequired,
  changedPickups: PropTypes.array.isRequired,
  setChangedPickups: PropTypes.func.isRequired
};

ShipperPickUP.defaultProps = {
  pickups: []
};

export default ShipperPickUP;
