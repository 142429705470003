import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CarrierCard from '../CarrierCard';
import ShipperCard from '../ShipperCard';
import SalesRepCard from '../SalesRepCard';
import { getSummaryPath } from '../../utils/quote.util';
import { BID_STATUS } from '../../constants/bids.constants';

const QuoteDetailsCard = memo(({ quote, loadType }) => {
  const [summaryPath, setSummaryPath] = useState();
  const [showEnroute, setshowEnroute] = useState(false);

  const { ASSIGNED_QUOTE } = BID_STATUS;

  useEffect(() => {
    const quoteCopy = { ...quote };
    quoteCopy.loadType = loadType;
    const summaryPathName = getSummaryPath(quoteCopy);
    setSummaryPath(summaryPathName);

    if (
      loadType === 'carrier-enroute-loads' ||
      loadType === 'shipper-enroute-loads' ||
      loadType === 'salesRep-enroute-quotes' ||
      (loadType === 'shipper-active-shipment' &&
        quoteCopy?.bidStatus === ASSIGNED_QUOTE) ||
      (loadType === 'salesRep-active-quotes' &&
        quoteCopy?.bidStatus === ASSIGNED_QUOTE)
    ) {
      setshowEnroute(true);
    }
  }, [quote, loadType]);

  const getQuoteCard = () => {
    switch (loadType) {
      case 'carrier-available-loads':
      case 'carrier-accepted-loads':
      case 'carrier-pending-loads':
      case 'carrier-assigned-loads':
      case 'carrier-enroute-loads':
      case 'carrier-completed-loads':
      case 'carrier-rejected-loads':
        return (
          <CarrierCard
            quote={quote}
            summaryPath={summaryPath ?? {}}
            showEnroute={showEnroute}
            loadType={loadType}
          />
        );

      case 'shipper-active-shipment':
      case 'shipper-recent-quotes':
      case 'shipper-pending-loads':
      case 'shipper-enroute-loads':
      case 'shipper-completed-loads':
        return (
          <ShipperCard
            quote={quote}
            summaryPath={summaryPath ?? {}}
            showEnroute={showEnroute}
            loadType={loadType}
          />
        );

      case 'salesRep-recent-quotes':
      case 'salesRep-active-quotes':
      case 'salesRep-pending-quotes':
      case 'salesRep-enroute-quotes':
      case 'salesRep-completed-quotes':
        return (
          <SalesRepCard
            quote={quote}
            summaryPath={summaryPath ?? {}}
            showEnroute={showEnroute}
            loadType={loadType}
          />
        );

      default:
        return null;
    }
  };

  return getQuoteCard();
});

export default QuoteDetailsCard;

QuoteDetailsCard.propTypes = {
  quote: PropTypes.object.isRequired,
  loadType: PropTypes.string.isRequired
};
