import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import moment from 'moment';

import CargoDetailsCard from '../../components/CargoDetailsCard/CargoDetailsCard';
import GoogleMapComponent from '../../components/GoogleMapComponent/GoogleMapComponent';
import { BID_TYPE, BID_STATUS } from '../../constants/bids.constants';
import { getQuoteDetailsService } from '../../services/quote.service';
import {
  additionalCharges,
  cargoStringConvertor,
  getPickupDeliveryDate,
  printServiceOptions
} from '../../utils/quote.util';
import {
  carrierAcceptBidService,
  carrierMarkAsDelivered,
  carrierMarkAsPicked,
  carrierRejectBidService,
  carrierRebidService
} from '../../services/carrier.service';

import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { formatPickupDeliveryTime } from '../../utils/date_conversion.util';
import PlaceBid from '../../components/PlaceBid/PlaceBid';

import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import { getBidDetailsService } from '../../services/bid.service';
import ROCButton from '../../components/ROCButton/ROCButton';

const LoadDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { quoteId, bidId } = useParams();
  const [showConfirm, setshowConfirm] = useState(false);
  const [isAPICalled, setisAPICalled] = useState(false);
  const [quote, setQuote] = useState(null);
  const [bid, setBid] = useState(null);
  const [totalCargoWeight, setTotalCargoWeight] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const { QUOTE_FROM_NET, FIXED_AMOUNT, OPEN_BID } = BID_TYPE;
  const {
    PENDING_QUOTE,
    SALES_REP_PENDING_QUOTE,
    ASSIGNED_QUOTE,
    ENROUTE_QUOTE,
    DELIVERED_QUOTE
  } = BID_STATUS;

  const toggleModal = () => setshowModal((prevModal) => !prevModal);

  async function fetchQuoteBidDetails() {
    try {
      const [response, responseBid] = await Promise.all([
        getQuoteDetailsService(quoteId),
        getBidDetailsService(bidId)
      ]);

      setQuote(response.data.quote);
      setBid(responseBid.data.bid[0]);
      setTotalCargoWeight(
        response?.data?.quote?.cargos?.reduce(
          (prev, next) => prev + next.weight * next.quantity,
          0
        )
      );
      setisAPICalled(true);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchQuoteDetails() {
    try {
      const response = await getQuoteDetailsService(quoteId);
      setQuote(response.data.quote);
      setTotalCargoWeight(
        response?.data?.quote?.cargos?.reduce(
          (prev, next) => prev + next.weight * next.quantity,
          0
        )
      );
      setisAPICalled(true);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const onMarkAsCompleted = async () => {
    try {
      const response = await carrierMarkAsDelivered(quoteId);

      if (response.data.flag) {
        toast.success(response.data.message);
        navigate(-1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onMarkAsPicked = async () => {
    try {
      const response = await carrierMarkAsPicked({
        quoteId,
        carrierPickupDate: moment().format('MM/DD/YYYY'),
        carrierPickUpTime: formatPickupDeliveryTime(new Date(), false)
      });

      if (response.data.flag) {
        toast.success(response.data.message);
        navigate(-1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useEffect(() => {
    if (bidId) {
      fetchQuoteBidDetails();
    } else {
      fetchQuoteDetails();
    }
  }, [quoteId, bidId]);

  useEffect(() => {
    if (location.pathname.startsWith('/carrier-available-load-details')) {
      // fetchQuoteDetails();
      setshowConfirm(true);
    } else {
      // fetchQuoteBidDetails();
      setshowConfirm(false);
    }
  }, [location.pathname, quoteId, bidId]);

  const onClickAddressInfo = () => {
    navigate(`/address-details/${quoteId}`);
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const onRejectBidClick = async () => {
    try {
      const response = await carrierRejectBidService(quoteId);
      if (response.data.flag) {
        toast.success(response.data.message);
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onPlaceBid = async (bidAmount) => {
    toggleModal();
    try {
      if (
        location.state?.loadType === 'carrier-rejected-loads' &&
        quote.bidType === OPEN_BID
      ) {
        const response = await carrierRebidService({
          bidId,
          quoteId,
          amount: Number.parseFloat(bidAmount)
        });
        if (response.data.flag) {
          toast.success(response.data.message);
          navigate('/available-loads', { replace: true });
        }
      } else {
        const response = await carrierAcceptBidService(
          quoteId,
          Number.parseFloat(bidAmount)
        );
        if (response.data.flag) {
          toast.success(response.data.message);
          navigate('/available-loads', { replace: true });
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onAcceptBidClick = async () => {
    try {
      const response = await carrierAcceptBidService(quoteId, quote.estimate);
      if (response.data.flag) {
        toast.success(response.data.message);
        navigate(
          `/carrier-add-signature/${quoteId}/${response?.data?.bid?.id}`
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onAcceptAndSignClick = async () => {
    navigate(`/carrier-add-signature/${quoteId}/${bidId}`);
  };

  const getPickupDateTime = () => {
    if (quote.isMultipleQuote) {
      return quote?.pickupInfo
        ?.map((pick) => {
          let pickupCloseTime = '';
          if (!pick?.is_pickup_time_flexible && pick?.pickup_close_time) {
            pickupCloseTime = ` (close time: ${pick.pickup_close_time})`;
          }
          return `${pick?.pickup_date}${pickupCloseTime}`;
        })
        .join(', ');
    }

    const formattedPickupDate = getPickupDeliveryDate(quote.pickup_date);
    const pickupCloseTime = quote.pickup_close_time
      ? `(close time: ${quote.pickup_close_time})`
      : '';

    return `${formattedPickupDate} ${pickupCloseTime}`;
  };
  const getDeliveryDateTime = () => {
    if (quote.isMultipleQuote) {
      return quote?.deliveryInfo
        ?.map((del) => {
          let deliveryCloseTime = '';
          if (!del?.is_delivery_time_flexible && del?.delivery_close_time) {
            deliveryCloseTime = ` (close time: ${del.delivery_close_time})`;
          }
          return `${del?.delivery_date}${deliveryCloseTime}`;
        })
        .join(', ');
    }

    const formattedDeliveryDate = getPickupDeliveryDate(quote.delivery_date);
    const deliveryCloseTime = quote.delivery_close_time
      ? `(close time: ${quote.delivery_close_time})`
      : '';

    return `${formattedDeliveryDate}${deliveryCloseTime}`;
  };

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        {isAPICalled ? (
          <div className="flex flex-wrap text-center -mx-4">
            <div className="mb-8 w-full lg:w-1/2 px-3">
              <div className="mb-6 text-navy-500 flex flex-col items-start gap-3">
                <div
                  tabIndex="-1"
                  role="button"
                  onKeyPress={() => {}}
                  onClick={onBackArrowClick}
                  className="inline-flex items-center"
                >
                  <LeftArrowIcon className="w-6 h-6 left-0 flex items-center " />
                  <p className="ml-4">Back to the list</p>
                </div>
                <h3 className="text-3xl font-bold text-left">Summary</h3>
              </div>
              <div className="border  border-gray-200 border-t-0 border-l-0 border-r-0 pb-5">
                {quote.isMultipleQuote ? (
                  <p className="text-left">
                    You are requesting for a quote of{' '}
                    {cargoStringConvertor(quote.cargos)}. All piece will need to
                    be transported from{' '}
                    {quote.pickupInfo
                      ?.map((pick) => pick.pickup_zip_code)
                      ?.join(', ')}{' '}
                    to{' '}
                    {quote.deliveryInfo
                      ?.map((del) => del.delivery_zip_code)
                      ?.join(', ')}{' '}
                    {quote.distance ? `(${quote.distance} miles) ` : ''}
                    with a pick up on {getPickupDateTime()} and drop off on{' '}
                    {getDeliveryDateTime()}. The total weight of your cargo is{' '}
                    {totalCargoWeight} lbs.{' '}
                  </p>
                ) : (
                  <p className="text-left">
                    You are requesting for a quote of{' '}
                    {cargoStringConvertor(quote.cargos)}. All piece will need to
                    be transported from {quote.pickup_zip_code} to{' '}
                    {quote.delivery_zip_code}{' '}
                    {quote.distance ? `(${quote.distance} miles) ` : ''}
                    with a pick up on {getPickupDateTime()} and drop off on{' '}
                    {getDeliveryDateTime()}. The total weight of your cargo is{' '}
                    {totalCargoWeight} lbs.{' '}
                  </p>
                )}
                <p className="text-left mt-5">
                  You have requested special pickup services and delivery
                  Services.
                </p>
              </div>
              <div className="pt-5">
                <p className="text-xl text-left text-gray-400">
                  Special pickup services
                </p>
                {printServiceOptions(
                  quote.isMultipleQuote
                    ? quote.pickupInfo?.map(
                        (pick) => pick.pickup_service_options
                      )
                    : quote.pickup_service_options,
                  quote.isMultipleQuote,
                  'PICKUP'
                )}
              </div>
              <div className="pt-5 border  border-gray-200 border-t-0 border-l-0 border-r-0 pb-5">
                <p className="text-xl text-left text-gray-400">
                  Special delivery services
                </p>
                {printServiceOptions(
                  quote.isMultipleQuote
                    ? quote.deliveryInfo?.map(
                        (del) => del.delivery_service_options
                      )
                    : quote.delivery_service_options,
                  quote.isMultipleQuote,
                  'DELIVERY'
                )}
              </div>
              {quote.cargos.map((cargo) => {
                return (
                  <div
                    key={cargo.id}
                    className="border  border-gray-200 border-t-0 border-l-0 border-r-0 pb-5 mt-6"
                  >
                    <CargoDetailsCard
                      cargo={cargo}
                      shipFrom={quote?.pickupInfo?.find(
                        (pick) => pick.pickup_id === cargo.ship_from
                      )}
                      shipTo={quote?.deliveryInfo?.find(
                        (del) => del.delivery_id === cargo.ship_to
                      )}
                    />
                  </div>
                );
              })}

              {[ENROUTE_QUOTE, DELIVERED_QUOTE].includes(quote.bidStatus) &&
                bid.carrierSignature && (
                  <div className="border  border-gray-200 border-t-0 border-l-0 border-r-0 pb-5 relative mt-5">
                    <button
                      type="button"
                      className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3
                       bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                      text-sm font-bold  transition duration-200 text-left"
                      onClick={onClickAddressInfo}
                    >
                      Address Info
                      <RightArrow className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-1 font-bold" />
                    </button>
                  </div>
                )}

              <div className="pt-5 text-left">
                <p className="text-xl text-left text-black">Instructions</p>
                <form action="">
                  <div className="mb-3  p-4 pl-0">
                    <label
                      className="w-full block text-black text-sm font-bold mb-2"
                      htmlFor=""
                    >
                      Special pickup instructions
                    </label>
                    <input
                      className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                      type="text"
                      name="pickup-instructions"
                      readOnly
                      value={
                        quote.isMultipleQuote
                          ? quote?.pickupInfo
                              ?.map((pick) => pick.pickup_special_instructions)
                              ?.filter(Boolean)
                              ?.join(', ') || ''
                          : quote.pickup_special_instructions || ''
                      }
                    />
                  </div>
                  <div className="mb-3  p-4 pl-0">
                    <label
                      className="w-full block text-black text-sm font-bold mb-2"
                      htmlFor=""
                    >
                      Special delivery instructions
                    </label>
                    <input
                      className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                      type="text"
                      name="delivery-instructions"
                      readOnly
                      value={
                        quote.isMultipleQuote
                          ? quote?.deliveryInfo
                              ?.map((del) => del.delivery_special_instructions)
                              ?.filter(Boolean)
                              ?.join(', ') || ''
                          : quote.delivery_special_instructions || ''
                      }
                    />
                  </div>
                </form>
              </div>

              {(showConfirm && quote.bidType !== OPEN_BID) || !showConfirm ? (
                <div className="text-left p-4 relative pl-0">
                  <h2 className="text-3xl font-bold text-center text-green-700">
                    {quote.bidType === QUOTE_FROM_NET &&
                      quote.estimate != null && (
                        <span>
                          $
                          {(
                            quote.estimate +
                            quote.tarps_value_carrier +
                            additionalCharges(quote).carrierCharge
                          )?.toFixed(2)}
                        </span>
                      )}
                    {quote.bidType === FIXED_AMOUNT &&
                      quote?.amount != null && (
                        <span>
                          $
                          {(
                            quote.amount +
                            quote.tarps_value_carrier +
                            additionalCharges(quote).carrierCharge
                          )?.toFixed(2)}
                        </span>
                      )}
                    {quote.bidType === OPEN_BID && bid?.status === 4 && (
                      <span>Open Bid</span>
                    )}
                    {quote.bidType === OPEN_BID &&
                      bid?.status !== 4 &&
                      bid?.amount != null && (
                        <span>
                          $
                          {(
                            bid.amount +
                            quote.tarps_value_carrier +
                            additionalCharges(quote).carrierCharge
                          )?.toFixed(2)}
                        </span>
                      )}
                  </h2>
                </div>
              ) : (
                ''
              )}

              {/* Show if quote is in Available Loads */}
              {showConfirm && quote.bidStatus === PENDING_QUOTE && (
                <>
                  {quote.bidType === QUOTE_FROM_NET ||
                  quote.bidType === FIXED_AMOUNT ? (
                    <div className="text-left p-4 relative pl-0">
                      <button
                        onClick={onAcceptBidClick}
                        type="button"
                        className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                          bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                      text-sm font-bold transition duration-200 text-left"
                      >
                        Accept
                        <RightArrow className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-5 font-bold" />
                      </button>
                    </div>
                  ) : (
                    <div className="text-left p-4 relative pl-0">
                      <button
                        onClick={toggleModal}
                        type="button"
                        className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                          bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                      text-sm font-bold transition duration-200 text-left"
                      >
                        Enter Bid
                      </button>
                    </div>
                  )}

                  <div className="text-left p-4 relative pl-0">
                    <button
                      type="button"
                      onClick={onRejectBidClick}
                      className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 
                   border border-navy-500 hover:border-navy-600
                     text-sm font-bold transition duration-200 text-left"
                    >
                      Decline
                      <span className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-4 font-bold text-5xl">
                        &#215;
                      </span>
                    </button>
                  </div>
                  <div className="text-left p-4 relative pl-0">
                    <button
                      type="button"
                      className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 
                   border border-navy-500 hover:border-navy-600
                     text-sm font-bold duration-200 text-left"
                      onClick={onBackArrowClick}
                    >
                      Later
                    </button>
                  </div>
                </>
              )}

              {/* Show if quote is in Accepted Loads */}
              {location.state?.loadType === 'carrier-accepted-loads' &&
                [SALES_REP_PENDING_QUOTE, ASSIGNED_QUOTE].includes(
                  quote.bidStatus
                ) &&
                !bid?.carrierSignature && (
                  <div className="text-left p-4 relative pl-0">
                    <button
                      onClick={onAcceptAndSignClick}
                      type="button"
                      className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3
                           bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                          hover:border-navy-600 active:border-navy-700
                      text-sm font-bold transition duration-200 text-left"
                    >
                      Accept and Sign
                      <RightArrow className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-5 font-bold" />
                    </button>
                  </div>
                )}
              {location.state?.loadType === 'carrier-accepted-loads' &&
                quote.bidStatus === SALES_REP_PENDING_QUOTE && (
                  <>
                    <div className="text-left p-4 relative pl-0">
                      <button
                        type="button"
                        onClick={onRejectBidClick}
                        className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 
                   border border-navy-500 hover:border-navy-600
                     text-sm font-bold transition duration-200 text-left"
                      >
                        Decline
                        <span className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-4 font-bold text-5xl">
                          &#215;
                        </span>
                      </button>
                    </div>
                    <div className="text-left p-4 relative pl-0">
                      <button
                        type="button"
                        className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 
                   border border-navy-500 hover:border-navy-600
                     text-sm font-bold duration-200 text-left"
                        onClick={onBackArrowClick}
                      >
                        Later
                      </button>
                    </div>
                  </>
                )}

              {['carrier-enroute-loads', 'carrier-assigned-loads'].includes(
                location.state?.loadType
              )
                ? !quote.isPickupByCarrier &&
                  quote.bidStatus === ENROUTE_QUOTE && (
                    <div className="text-left py-4 relative pl-0">
                      <button
                        type="button"
                        onClick={onMarkAsPicked}
                        className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                          bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                      text-sm font-bold transition duration-200 text-left"
                      >
                        Mark as Picked
                        <span className="pointer-events-none absolute inset-y-0 right-0 mr-4 mt-4 text-3xl">
                          &#10003;
                        </span>
                      </button>
                    </div>
                  )
                : ''}

              {location.state?.loadType === 'carrier-rejected-loads' &&
                quote.bidType === OPEN_BID && (
                  <div className="text-left p-4 relative pl-0">
                    <button
                      onClick={toggleModal}
                      type="button"
                      className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                          bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                      text-sm font-bold transition duration-200 text-left"
                    >
                      Rebid
                    </button>
                  </div>
                )}

              {location.state?.loadType === 'carrier-enroute-loads' &&
                quote.isPickupByCarrier &&
                !quote.isDeliveredByCarrier && (
                  <div className="text-left py-4 relative pl-0">
                    <button
                      type="button"
                      onClick={onMarkAsCompleted}
                      className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                          bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                      text-sm font-bold transition duration-200 text-left"
                    >
                      Mark as Delivered
                      <span className="pointer-events-none absolute inset-y-0 right-0 mr-4 mt-4 text-3xl">
                        &#10003;
                      </span>
                    </button>
                  </div>
                )}
              {[
                'carrier-assigned-loads',
                'carrier-enroute-loads',
                'carrier-completed-loads'
              ].includes(location.state?.loadType) && (
                <ROCButton quoteId={quote?.id} />
              )}
            </div>
            <div className="mb-8 w-full lg:w-1/2 px-3 -mt-4">
              <GoogleMapComponent
                pickupLatLong={
                  quote.isMultipleQuote
                    ? quote?.pickupInfo?.map((pick) => {
                        return {
                          lat: pick.pickupLatitude,
                          lng: pick.pickupLongitude
                        };
                      })
                    : [
                        {
                          lat: quote.pickupLatitude,
                          lng: quote.pickupLongitude
                        }
                      ]
                }
                deliveryLatLong={
                  quote.isMultipleQuote
                    ? quote?.deliveryInfo?.map((del) => {
                        return {
                          lat: del.deliveryLatitude,
                          lng: del.deliveryLongitude
                        };
                      })
                    : [
                        {
                          lat: quote.deliveryLatitude,
                          lng: quote.deliveryLongitude
                        }
                      ]
                }
              />
            </div>
            {showModal && (
              <PlaceBid closeModal={toggleModal} sendBidAmount={onPlaceBid} />
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default LoadDetailsPage;
