/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState, memo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import Select from 'react-select';

import moment from 'moment';
import momBusinessDays from 'moment-business-days';
import CargoDetailsCard from '../../components/CargoDetailsCard/CargoDetailsCard';
import GoogleMapComponent from '../../components/GoogleMapComponent/GoogleMapComponent';
import { useAuthContext } from '../../contexts/auth.context';
import DeletePopup from '../../components/DeletePopup';

import {
  getQuoteDetailsService,
  markAsCompletedService,
  refreshLtlRatesService,
  updateCarrierType,
  updateShipmentService
} from '../../services/quote.service';
import {
  additionalCharges,
  cargoStringConvertor,
  getPickupDeliveryDate,
  printServiceOptions
} from '../../utils/quote.util';

import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { ReactComponent as CancelIcon } from '../../assets/images/cancel.svg';
import {
  BLANKET_WRAP,
  LTL_FREIGHT
} from '../../constants/service_type.constants';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { QUOTE_STATUS, BWRAP_CARRIERS } from '../../constants/quote.constants';
import UpdateMultipleShipments from '../../components/UpdateMultipleShipments';
import UpdateShipment from '../../components/UpdateShipment/UpdateShipment';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import { getCarriersService } from '../../services/carrier.service';
import LTLRateTable from '../../components/LTLRateTable/LTLRateTable';
import { BID_TYPE, BID_STATUS } from '../../constants/bids.constants';

const QuoteDetailsPage = memo(() => {
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const { authUser } = useAuthContext();

  const [isAPICalled, setisAPICalled] = useState(false);
  const [quote, setQuote] = useState(null);
  const [totalCargoWeight, setTotalCargoWeight] = useState(0);
  const [showTracking, setshowTracking] = useState(false);
  const [carrierValue, setCarrierValue] = useState('');
  const [showDeletePopup, setDeletePopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [shipmentStatus, setShipmentStatus] = useState();
  const [carriers, setCarriers] = useState();
  const [proNumber, setProNumber] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [carrierAssignDate, setAssignPickupDate] = useState();
  const [carrierPickupDate, setCarrierPickupDate] = useState();
  const [deliveryDate, setDeliveryDate] = useState();
  const [driverName, setDriverName] = useState('');
  const [openCargo, setOpenCargo] = useState();
  const [pickupLatLng, setPickuplLatLng] = useState([]);
  const [deliveryLatLng, setDeliverylLatLng] = useState([]);
  const [isOldQuote, setIsOldQuote] = useState(false);
  const [bwrapCarrier, setBwrapCarrier] = useState();

  const { FIXED_AMOUNT, OPEN_BID } = BID_TYPE;
  const {
    SALES_REP_PENDING_QUOTE,
    ASSIGNED_QUOTE,
    ENROUTE_QUOTE,
    DELIVERED_QUOTE
  } = BID_STATUS;

  async function fetchQuoteDetails() {
    try {
      //  Preventing Stale Data Rendering
      setisAPICalled(false);
      const response = await getQuoteDetailsService(quoteId);

      const quoteResponse = response.data.quote;

      // Set today's date if blank value set for pickup date
      quoteResponse.pickup_date =
        quoteResponse?.pickup_date !== ''
          ? quoteResponse.pickup_date
          : new Date();

      // Set today's date if blank value set for delivery date
      quoteResponse.delivery_date =
        quoteResponse?.delivery_date !== ''
          ? quoteResponse.delivery_date
          : new Date();

      // Set current time if blank value set for pickup time
      quoteResponse.pickup_time =
        quoteResponse?.pickup_time !== ''
          ? quoteResponse.pickup_time
          : new Date();

      // Set current time if blank value set for delivery time
      quoteResponse.delivery_time =
        quoteResponse?.delivery_time !== ''
          ? quoteResponse.delivery_time
          : new Date();

      // for handling older quotes
      if (quoteResponse?.isMultipleQuote) {
        const pickupInfo = quoteResponse.pickupInfo?.map((pick) => {
          const modifiedPick = { ...pick };

          if (!modifiedPick.pickup_id && modifiedPick.id) {
            modifiedPick.pickup_id = modifiedPick.id;
          }

          return modifiedPick;
        });

        const deliveryInfo = quoteResponse.deliveryInfo?.map((del) => {
          const modifiedDel = { ...del };

          if (!modifiedDel.delivery_id && modifiedDel.id) {
            modifiedDel.delivery_id = modifiedDel.id;
          }

          return modifiedDel;
        });

        quoteResponse.pickupInfo = pickupInfo;
        quoteResponse.deliveryInfo = deliveryInfo;
      }
      setQuote(quoteResponse);
      setisAPICalled(true);
      setTotalCargoWeight(
        quoteResponse?.cargos?.reduce(
          (prev, next) => prev + next.weight * next.quantity,
          0
        )
      );
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchCarrierDetails() {
    try {
      const response = await getCarriersService();
      setCarriers(response?.data?.carriers);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchQuoteDetails();
    fetchCarrierDetails();
  }, [quoteId]);

  useEffect(() => {
    switch (quote?.bidStatus) {
      case 2:
      case 1:
        setShipmentStatus('Pending');
        break;
      case 6:
        setShipmentStatus(quote?.isPickupByCarrier ? 'Enroute' : 'Assigned');
        break;
      case 3:
        setShipmentStatus('Delivered');
        break;
      default:
        break;
    }
    setProNumber(
      quote?.service_type === LTL_FREIGHT
        ? quote?.[quote.carrier_type]?.pickupNumber
        : quote?.tracking_details?.trackingNumber
    );
    if (quote && quote.service_type === LTL_FREIGHT && !quote?.booked) {
      const diff = momBusinessDays().businessDiff(
        momBusinessDays(quote.ltlRateDate || quote.createdAt)
      );
      if (diff >= 5) setIsOldQuote(true);
    }
  }, [quote]);

  useEffect(() => {
    if (
      [
        'salesRep-active-quotes',
        'salesRep-enroute-quotes',
        'salesRep-completed-quotes'
      ].includes(quote?.loadType)
    ) {
      setshowTracking(true);
    } else {
      setshowTracking(false);
    }
  }, [quote]);

  useEffect(() => {
    if (isAPICalled) {
      if (quote?.isMultipleQuote) {
        const currentCargo = quote.cargos.find(
          (cargo) => cargo.id === openCargo || quote?.cargos[0]
        );
        // Fetch selected cargo or choose first pickupInfo
        const pickupInfo = quote?.pickupInfo;
        const selectedPickup =
          pickupInfo &&
          (pickupInfo.find(
            (pick) => pick.pickup_id === currentCargo?.ship_from
          ) ||
            pickupInfo[0]);

        // Check if selectedPickup is defined before destructuring
        const { pickupLatitude, pickupLongitude } = selectedPickup || {};

        // Fetch selected cargo or choose first deliveryInfo
        const deliveryInfo = quote?.deliveryInfo;
        const selectedDelivery =
          deliveryInfo &&
          (deliveryInfo.find(
            (del) => del.delivery_id === currentCargo?.ship_to
          ) ||
            deliveryInfo[0]);

        // Check if selectedDelivery is defined before destructuring
        const { deliveryLatitude, deliveryLongitude } = selectedDelivery || {};

        setPickuplLatLng([{ lat: pickupLatitude, lng: pickupLongitude }]);
        setDeliverylLatLng([{ lat: deliveryLatitude, lng: deliveryLongitude }]);
      } else {
        const allPickupLatLng = quote?.pickupInfo?.map(
          ({ pickupLatitude, pickupLongitude }) => {
            return {
              lat: pickupLatitude,
              lng: pickupLongitude
            };
          }
        );

        const allDeliveryLatLng = quote?.deliveryInfo?.map(
          ({ deliveryLatitude, deliveryLongitude }) => {
            return {
              lat: deliveryLatitude,
              lng: deliveryLongitude
            };
          }
        );

        setPickuplLatLng(allPickupLatLng);
        setDeliverylLatLng(allDeliveryLatLng);
      }
    }
  }, [isAPICalled, openCargo]);

  const onBookNowClick = async () => {
    if (quote) {
      try {
        if (quote.service_type !== LTL_FREIGHT) {
          return navigate(`/book-now-quote/${quoteId}`);
        }
        if (quote.service_type === LTL_FREIGHT && !carrierValue) {
          toast.error('Select carrier');
        } else {
          if (
            !quote[carrierValue] ||
            !quote[carrierValue][`${carrierValue}_estimate`]
          ) {
            return toast.error('Please select another carrier');
          }
          const response = await updateCarrierType({
            carrier_type: carrierValue,
            quoteId
          });
          if (response.data.flag) {
            navigate(`/book-now-quote/${quoteId}`);
          }
        }
      } catch (error) {
        toast.error(error?.response?.data.message ?? 'Something went wrong!');
      }
    }
  };

  const onChangeHandler = (e) => {
    setCarrierValue(e.target.value);
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const onTrackShipmentClick = () => {
    navigate(`/salesRep/enroute-quote-details/tracking/${quoteId}`);
  };

  const onMarkAsCompleted = async () => {
    try {
      const response = await markAsCompletedService({
        quoteId,
        deliveryDateTime: moment().format('MM/DD/YYYY HH:mm')
      });

      if (response.data.flag) {
        toast.success(response.data.message);
        navigate('/shipper-completed');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  async function changeStatusHandler() {
    try {
      const body = { id: quoteId };
      if (
        [LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) &&
        currentStatus === 'Enroute' &&
        !proNumber
      ) {
        return toast.error('PRO/Tracking Number required for enruote');
      }

      if (currentStatus === 'Assigned') {
        if (
          [LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) ||
          quote.bidType === OPEN_BID
        ) {
          if (!carrierAssignDate) {
            return toast.error('Assigned Date required');
          }
        } else if (!carrierAssignDate || !selectedCarrier) {
          return toast.error('Carrier and Assigned Date required');
        }
        if (quote.service_type === BLANKET_WRAP && !bwrapCarrier) {
          return toast.error('Please select carrier');
        }
      }

      if (currentStatus === 'Enroute' && !carrierPickupDate) {
        return toast.error('Pickup Date required for enruote');
      }

      if (currentStatus === 'Delivered' && !deliveryDate) {
        return toast.error('Delivery Date required for Delivered');
      }

      body.bwrapCarrier = bwrapCarrier;

      switch (currentStatus) {
        case 'Pending':
          body.bidStatus = SALES_REP_PENDING_QUOTE;
          body.isPickupByCarrier = false;
          body.bookDateTime = moment().format('MM/DD/YYYY HH:mm');
          body.tracking_details = {
            ...quote.tracking_details,
            shipmentStatus: {
              ...quote?.tracking_details?.currentStatus,
              statusCd: 1
            }
          };
          break;
        case 'Assigned':
          body.bidStatus = ASSIGNED_QUOTE;
          body.isPickupByCarrier = false;
          body.assignedCarrier = selectedCarrier;
          body.estimate = quote.estimate;
          body.assignDateTime =
            moment(carrierAssignDate).format('MM/DD/YYYY HH:mm');
          body.tracking_details = {
            ...quote.tracking_details,
            shipmentStatus: {
              ...quote?.tracking_details?.currentStatus,
              statusCd: 1
            }
          };
          break;
        case 'Enroute':
          body.bidStatus = ENROUTE_QUOTE;
          body.isPickupByCarrier = true;
          body.carrierPickupDate =
            moment(carrierPickupDate).format('MM/DD/YYYY');
          body.carrierPickUpTime = moment().format('hh:mm A');
          body.tracking_details = {
            ...quote.tracking_details,
            shipmentStatus: {
              ...quote?.tracking_details?.currentStatus,
              statusCd: 3
            }
          };
          break;
        case 'Delivered':
          body.bidStatus = DELIVERED_QUOTE;
          body.isPickupByCarrier = true;
          body.deliveryDateTime =
            moment(deliveryDate).format('MM/DD/YYYY ') +
            moment().format('hh:mm A');
          body.tracking_details = {
            ...quote.tracking_details,
            shipmentStatus: {
              ...quote?.tracking_details?.currentStatus,
              statusCd: 5
            },
            driverName: driverName || undefined
          };
          break;
        default:
          body.tracking_details = {
            ...quote.tracking_details,
            shipmentStatus: {
              ...quote?.tracking_details?.currentStatus
            },
            driverName: driverName || undefined
          };
          break;
      }

      if (quote.service_type === LTL_FREIGHT) {
        body.tracking_details.trackingNumber = proNumber;
        body[`${quote.carrier_type}`] = quote[`${quote.carrier_type}`];
        body[`${quote.carrier_type}`].pickupNumber = proNumber;
      } else if (quote.service_type === BLANKET_WRAP) {
        if (
          !proNumber &&
          !body?.tracking_details?.trackingNumber &&
          currentStatus &&
          currentStatus === 'Enroute'
        )
          return toast.error('Please provide tracking number');
        body.tracking_details.trackingNumber = proNumber;
      }
      const response = await updateShipmentService(body);
      toast.success(response?.data?.message);
      window.location.reload();
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const refreshQuote = async () => {
    if (!isOldQuote) return navigate(`/new-quote?quoteId=${quoteId}`);
    try {
      const response = await refreshLtlRatesService(quoteId);
      setQuote(response?.data?.data);
      setIsOldQuote(false);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const handleOnStatusChange = (e) => {
    setCurrentStatus(e.target.value);
  };

  const getPickupDateTime = () => {
    if (quote?.isMultipleQuote) {
      return quote?.pickupInfo
        ?.map((pick) => {
          let pickupCloseTime = '';
          if (!pick?.is_pickup_time_flexible && pick?.pickup_close_time) {
            pickupCloseTime = ` (close time: ${pick.pickup_close_time})`;
          }
          return `${pick?.pickup_date}${pickupCloseTime}`;
        })
        .join(', ');
    }

    const formattedPickupDate = getPickupDeliveryDate(quote?.pickup_date);
    const pickupCloseTime = quote?.pickup_close_time
      ? `(close time: ${quote.pickup_close_time})`
      : '';

    return `${formattedPickupDate} ${pickupCloseTime}`;
  };
  const getDeliveryDateTime = () => {
    if (quote?.isMultipleQuote) {
      return quote?.deliveryInfo
        ?.map((del) => {
          let deliveryCloseTime = '';
          if (!del?.is_delivery_time_flexible && del?.delivery_close_time) {
            deliveryCloseTime = ` (close time: ${del.delivery_close_time})`;
          }
          return `${del?.delivery_date}${deliveryCloseTime}`;
        })
        .join(', ');
    }

    const formattedDeliveryDate = getPickupDeliveryDate(quote?.delivery_date);
    const deliveryCloseTime = quote?.delivery_close_time
      ? `(close time: ${quote?.delivery_close_time})`
      : '';

    return `${formattedDeliveryDate}${deliveryCloseTime}`;
  };

  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        {isAPICalled ? (
          <div className="flex flex-wrap -mx-4 text-center">
            <div className="w-full px-3 mb-8 lg:w-1/2">
              <div className="relative mb-6 ">
                <div className="flex flex-col items-start gap-3">
                  <div
                    tabIndex="-1"
                    role="button"
                    onKeyPress={() => {}}
                    onClick={onBackArrowClick}
                    className="inline-flex items-center gap-3"
                  >
                    <LeftArrowIcon className="w-6 h-6 " />
                    <p>Back to the list</p>
                  </div>
                  {quote?.equipmentType && (
                    <div className="mt-8">
                      <p className="text-left font-bold text-xl">
                        Equipment Type:{' '}
                        <span className="font-normal text-lg">
                          {quote?.equipmentType?.join(', ')}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="flex flex-row justify-between items-center w-full">
                    <h4 className="text-3xl font-bold text-left text-navy-500 ">
                      Summary
                    </h4>
                    {quote?.internalTrackingNumber && (
                      <div className="text-right">
                        Load #{quote?.internalTrackingNumber}
                      </div>
                    )}
                  </div>
                </div>
                {quote?.booked ? (
                  <div className="block w-1/2 mt-4 space-y-3 font-bold text-left">
                    <label className="text-xl">Change Shipment Status</label>
                    <select
                      value={currentStatus ?? shipmentStatus}
                      className="flex w-full px-2 py-2 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                      onChange={handleOnStatusChange}
                    >
                      {QUOTE_STATUS?.map(
                        (status) =>
                          status.label !== 'Recent' && (
                            <option
                              key={status.label}
                              value={status.label}
                              className="text-xs font-semibold leading-none"
                            >
                              {status.label}
                            </option>
                          )
                      )}
                    </select>

                    {quote.service_type === BLANKET_WRAP &&
                      currentStatus === 'Assigned' && (
                        <div className="mb-5 w-full">
                          <Select
                            options={BWRAP_CARRIERS}
                            value={BWRAP_CARRIERS.find(
                              (e) => e.value === bwrapCarrier
                            )}
                            onChange={(e) => {
                              setBwrapCarrier(e.value);
                            }}
                            className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
                          />
                        </div>
                      )}
                    {currentStatus === 'Assigned' ? (
                      <div>
                        {![LTL_FREIGHT, BLANKET_WRAP].includes(
                          quote.service_type
                        ) && quote.bidType !== OPEN_BID ? (
                          <>
                            <label className="text-sm">Select Carrier</label>
                            <div className="mb-5 w-full">
                              <Select
                                options={carriers}
                                getOptionLabel={({ company, mc }) =>
                                  `${company} (#${mc})`
                                }
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                  setSelectedCarrier(e.id);
                                }}
                                className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
                              />
                            </div>
                          </>
                        ) : null}
                        <div>
                          <label
                            className="block w-full text-sm font-bold text-black"
                            htmlFor=""
                          >
                            Assigned Date
                          </label>
                          <DatePickerInput
                            name="carrierPickupDate"
                            value={carrierAssignDate}
                            handleDateChange={(e) => setAssignPickupDate(e)}
                            selectedDate={carrierAssignDate}
                            minDate=""
                          />
                        </div>
                      </div>
                    ) : null}
                    {currentStatus === 'Enroute' &&
                    [LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) ? (
                      <div>
                        <label
                          className="block w-full text-sm font-bold text-black"
                          htmlFor=""
                        >
                          PRO/Tracking Number
                        </label>
                        <input
                          className="w-full p-2 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
                          type="text"
                          value={proNumber}
                          onChange={(e) => {
                            setProNumber(e.target.value);
                          }}
                        />
                      </div>
                    ) : null}
                    {currentStatus === 'Enroute' ? (
                      <div>
                        <label
                          className="block w-full text-sm font-bold text-black"
                          htmlFor=""
                        >
                          Pickup Date
                        </label>
                        <DatePickerInput
                          name="carrierPickupDate"
                          value={carrierPickupDate}
                          handleDateChange={(e) => setCarrierPickupDate(e)}
                          selectedDate={carrierPickupDate}
                          minDate=""
                        />
                      </div>
                    ) : null}
                    {currentStatus === 'Delivered' ? (
                      <div>
                        <label
                          className="block w-full text-sm font-bold text-black"
                          htmlFor=""
                        >
                          Delivery Date
                        </label>
                        <DatePickerInput
                          name="deliveryDate"
                          value={deliveryDate}
                          handleDateChange={(e) => setDeliveryDate(e)}
                          selectedDate={deliveryDate}
                          minDate=""
                        />
                      </div>
                    ) : null}
                    {currentStatus === 'Delivered' ? (
                      <div>
                        <label
                          className="block w-full text-sm font-bold text-black"
                          htmlFor=""
                        >
                          Delivered To
                        </label>
                        <input
                          className="w-full p-2 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
                          type="text"
                          value={driverName}
                          onChange={(e) => {
                            setDriverName(e.target.value);
                          }}
                        />
                      </div>
                    ) : null}
                    <button
                      type="button"
                      className="inline-block w-1/3 py-2 text-sm font-bold text-center text-white transition duration-200 border bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                      onClick={changeStatusHandler}
                      disabled={currentStatus === undefined}
                    >
                      Save
                    </button>
                  </div>
                ) : null}
                {quote?.booked ? (
                  quote?.isMultipleQuote ? (
                    <UpdateMultipleShipments
                      quote={quote}
                      openCargo={setOpenCargo}
                    />
                  ) : (
                    <UpdateShipment quote={quote} />
                  )
                ) : null}
              </div>

              {!quote?.booked ? (
                <>
                  {' '}
                  <div className="pb-5 border border-t-0 border-l-0 border-r-0 border-gray-200">
                    {quote?.isMultipleQuote ? (
                      <p className="text-left">
                        You are requesting for a quote of{' '}
                        {cargoStringConvertor(quote.cargos)}. All piece will
                        need to be transported from{' '}
                        {quote.pickupInfo
                          ?.map((pick) => pick.pickup_zip_code)
                          ?.join(', ')}{' '}
                        to{' '}
                        {quote.deliveryInfo
                          ?.map((del) => del.delivery_zip_code)
                          ?.join(', ')}{' '}
                        {quote.distance ? `(${quote.distance} miles) ` : ''}
                        with a pick up on {getPickupDateTime()} and drop off on{' '}
                        {getDeliveryDateTime()}. The total weight of your cargo
                        is {totalCargoWeight} lbs.{' '}
                      </p>
                    ) : (
                      <p className="text-left">
                        You are requesting for a quote of{' '}
                        {cargoStringConvertor(quote.cargos)}. All piece will
                        need to be transported from {quote.pickup_zip_code} to{' '}
                        {quote.delivery_zip_code}{' '}
                        {quote.distance ? `(${quote.distance} miles) ` : ''}
                        with a pick up on {getPickupDateTime()} and drop off on{' '}
                        {getDeliveryDateTime()}. The total weight of your cargo
                        is {totalCargoWeight} lbs.{' '}
                      </p>
                    )}

                    <p className="mt-5 text-left">
                      You have requested special pickup services and delivery
                      Services.
                    </p>
                  </div>
                  <div className="pt-5">
                    <p className="text-xl text-left text-gray-400">
                      Special pickup services
                    </p>
                    {printServiceOptions(
                      quote?.isMultipleQuote
                        ? quote.pickupInfo?.map(
                            (pick) => pick.pickup_service_options
                          )
                        : quote.pickup_service_options,
                      quote?.isMultipleQuote,
                      'PICKUP'
                    )}
                  </div>
                  <div className="pt-5 pb-5 border border-t-0 border-l-0 border-r-0 border-gray-200">
                    <p className="text-xl text-left text-gray-400">
                      Special delivery services
                    </p>
                    {printServiceOptions(
                      quote?.isMultipleQuote
                        ? quote.deliveryInfo?.map(
                            (del) => del.delivery_service_options
                          )
                        : quote.delivery_service_options,
                      quote?.isMultipleQuote,
                      'DELIVERY'
                    )}
                  </div>
                  {quote.cargos.map((cargo) => {
                    return (
                      <div
                        key={cargo.id}
                        className="pb-5 mt-6 border border-t-0 border-l-0 border-r-0 border-gray-200"
                      >
                        <CargoDetailsCard
                          cargo={cargo}
                          shipFrom={quote?.pickupInfo?.find(
                            (pick) => pick.pickup_id === cargo.ship_from
                          )}
                          shipTo={quote?.deliveryInfo?.find(
                            (del) => del.delivery_id === cargo.ship_to
                          )}
                        />
                      </div>
                    );
                  })}
                  {quote?.booked ? (
                    <div className="pt-5 text-left">
                      <p className="text-xl text-left text-black">
                        Instructions
                      </p>
                      <form action="">
                        <div className="p-4 pl-0 mb-3">
                          <label
                            className="block w-full mb-2 text-sm font-bold text-black"
                            htmlFor=""
                          >
                            Special pickup instructions
                          </label>
                          <input
                            className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
                            type="text"
                            name="pickup-instructions"
                            readOnly
                            value={quote.pickup_special_instructions}
                          />
                        </div>
                        <div className="p-4 pl-0 mb-3">
                          <label
                            className="block w-full mb-2 text-sm font-bold text-black"
                            htmlFor=""
                          >
                            Special delivery instructions
                          </label>
                          <input
                            className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
                            type="text"
                            name="delivery-instructions"
                            readOnly
                            value={quote.delivery_special_instructions}
                          />
                        </div>
                      </form>
                    </div>
                  ) : null}
                </>
              ) : null}

              {quote.service_type === LTL_FREIGHT && !quote?.booked ? (
                <LTLRateTable
                  quote={quote}
                  authUser={authUser}
                  isOldQuote={isOldQuote}
                  carrierValue={carrierValue}
                  setCarrierValueChange={onChangeHandler}
                />
              ) : null}

              {!quote?.booked && (
                // quote.delivery_address &&
                // quote.pickup_address &&
                <>
                  <div className="flex col-span-2">
                    <div className="relative flex-1 p-4 pl-0 text-left">
                      <LeftArrowIcon className="absolute inset-y-0 left-0 flex items-center w-10 h-10 px-2 mt-5 mr-5 text-white pointer-events-none font-boldx" />

                      <button
                        type="button"
                        onClick={() => refreshQuote()}
                        className="order-1 inline-block w-full py-3 mb-2 mr-12 text-sm font-bold text-center text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                      >
                        {isOldQuote ? 'REFRESH NOW' : 'REFRESH QUOTE'}
                      </button>
                    </div>
                    <div className="relative flex-1 p-4 pl-0 text-left">
                      <button
                        type="button"
                        disabled={isOldQuote}
                        onClick={onBookNowClick}
                        className={`order-1 inline-block w-full py-3 mb-2 mr-12 text-sm font-bold text-center transition duration-200 border xl:order-0 ${
                          isOldQuote
                            ? 'text-gray-400 bg-gray-300 border-gray-300 '
                            : 'text-white bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700'
                        }   `}
                      >
                        <RightArrow className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-5 mr-5 font-bold pointer-events-none" />
                        Book Now
                      </button>
                    </div>
                  </div>
                  {isOldQuote && quote.service_type === LTL_FREIGHT && (
                    <div className="w-full border rounded bg-red-100 border-red-300 flex items-center p-2">
                      <p className="bg-red-500 rounded-full h-2 w-2 p-1" />
                      <p className="text-md">
                        {`LTL quote rates seems to be older than 5 business days.
                        Please tap on 'Refresh Now' to fetch latest rates.`}
                      </p>
                    </div>
                  )}
                </>
              )}

              <div className="relative pl-0 text-left">
                <h2 className="text-3xl font-bold text-center text-green-700">
                  {(quote.bidType === FIXED_AMOUNT ||
                    quote.bidType === OPEN_BID) &&
                    (quote.amount ? (
                      <>
                        $
                        {(
                          quote.amount +
                          quote.tarps_value +
                          additionalCharges(quote).revenueCharge
                        )?.toFixed(2)}
                      </>
                    ) : null)}
                  {quote.bidType !== FIXED_AMOUNT &&
                    quote.bidType !== OPEN_BID && (
                      <>
                        $
                        {(
                          quote.estimate +
                          quote.tarps_value +
                          additionalCharges(quote).revenueCharge
                        )?.toFixed(2)}
                      </>
                    )}
                </h2>
              </div>

              {showTracking && (
                <>
                  {![LTL_FREIGHT, BLANKET_WRAP].includes(quote?.service_type) &&
                    shipmentStatus === 'Enroute' && (
                      <div className="relative p-4 pl-0 text-left">
                        <h4 className="text-xl font-bold text-left text-green-700">
                          This shipment has been picked up by Carrier at{' '}
                          {quote.carrierPickupDate} {quote.carrierPickUpTime} .
                        </h4>
                      </div>
                    )}
                  {shipmentStatus === 'Assigned' &&
                    ![LTL_FREIGHT, BLANKET_WRAP].includes(
                      quote?.service_type
                    ) && (
                      <div className="relative p-4 pl-0 text-left">
                        <h4 className="mb-4 text-xl font-bold text-left text-red-600">
                          This shipment is not picked up by carrier yet.
                        </h4>
                      </div>
                    )}
                  <div className="relative p-4 pl-0 text-left">
                    <button
                      type="button"
                      onClick={onTrackShipmentClick}
                      className="order-1 inline-block w-full py-3 pl-4 mb-2 mr-12 text-sm font-bold text-left text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                    >
                      Track Shipment
                      <RightArrow className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-5 mr-5 font-bold pointer-events-none" />
                    </button>
                  </div>
                </>
              )}

              {quote.bidStatus === ENROUTE_QUOTE &&
                quote.isDeliveredByCarrier && (
                  <div className="relative p-4 pl-0 text-left">
                    <button
                      type="button"
                      onClick={onMarkAsCompleted}
                      className="order-1 inline-block w-full py-3 pl-4 mb-2 mr-12 text-sm font-bold text-left text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                    >
                      Mark as Completed
                      <span className="absolute inset-y-0 right-0 px-2 mt-4 mr-4 text-3xl pointer-events-none">
                        &#10003;
                      </span>
                    </button>
                  </div>
                )}
              {['salesRep', 'customerRep'].includes(authUser?.user?.usertype) &&
              !['Enroute', 'Delivered']?.includes(shipmentStatus) &&
              quote?.booked ? (
                <div className="relative p-4 pl-0">
                  <button
                    type="button"
                    onClick={() => setDeletePopup(true)}
                    className="order-1 inline-block w-full py-3 pl-4 mb-2 mr-12 text-sm font-bold text-center text-white transition duration-200 border xl:order-0 center bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                  >
                    Delete Shipment
                    <CancelIcon className="absolute inset-y-0 right-0 px-2 mt-4 mr-4 text-3xl pointer-events-none" />
                  </button>
                </div>
              ) : null}
            </div>
            {showDeletePopup ? (
              <DeletePopup quote={quote} setOpenModal={setDeletePopup} />
            ) : null}
            <div className="w-full px-3 mb-8 -mt-4 lg:w-1/2">
              <GoogleMapComponent
                pickupLatLong={
                  quote?.isMultipleQuote
                    ? pickupLatLng
                    : [
                        {
                          lat: quote.pickupLatitude,
                          lng: quote.pickupLongitude
                        }
                      ]
                }
                deliveryLatLong={
                  quote?.isMultipleQuote
                    ? deliveryLatLng
                    : [
                        {
                          lat: quote.deliveryLatitude,
                          lng: quote.deliveryLongitude
                        }
                      ]
                }
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
});

export default QuoteDetailsPage;
