const API_ENDPOINTS = {
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
  // ||
  //   'http://159.203.73.84:1337/v3',
  // baseURL: 'http://159.203.73.84:1337/v3',
  // baseURL: 'http://45.79.164.149/v3',
  USERS: {
    Login: '/user/login',
    SignUp: '/user/register',
    ForgotPassword: '/user/forgot',
    Logout: 'user/logout',
    Profile: '/user',
    Update: '/user/update',
    StripeOnBoard: '/user/customerOnboard',
    BillingPortal: '/user/billing',
    CheckSignupLink: '/user/checkSignupLink',
    ActivateUser: '/user/activateUser',
    AddLocation: '/user/addLocation',
    GetLocation: '/user/getLocation',
    UpdateLocation: '/user/updateLocation',
    DeleteLocation: '/user/deleteLocation',
    AddNewCargo: '/user/addNewCargo',
    UpdateCargo: '/user/updateCargo',
    GetSavedCargos: '/user/savedCargos',
    GetCargo: '/user/getCargo',
    DeleteCargo: '/user/deleteCargo',
    UpdateSettings: '/user/updateUserSettings',
    UpdateEmailPreference: '/user/updateEmailPreferences'
  },
  CARRIER: {
    Carries: '/carrier/all',
    SignUp: '/carrier/register',
    Profile: '/carrier/profile',
    Update: '/carrier',
    ListQuotes: '/carrier/list',
    StripeOnBoard: '/carrier/stripeOnboard',
    AcceptBid: '/carrier/bid',
    RejectBid: '/carrier/reject',
    MarkAsPicked: '/carrier/markAsPicked',
    MarkAsDelivered: '/carrier/markAsDelivered',
    Rebid: '/carrier/rebid',
    UpdateDOTNumber: '/carrier/updateDotNumber',
    GetMCPData: '/carrier/getMCPData',
    RefreshMCPData: '/carrier/refreshMCPData',
    SearchQuotes: '/carrier/searchQuotes',
    DashboardBids: '/carrier/dashboardBids'
  },
  SALES_REP: {
    Login: '/salesrepresentative/login',
    Logout: '/salesrepresentative/logout',
    ForgotPassword: '/salesrepresentative/forgot',
    ResetPassword: '/salesrepresentative/resetPassword',
    GetAllQuotes: '/salesrepresentative/getAllQuotes',
    GetAllCompaniesBySalesRep: '/salesrepresentative/getAllCompaniesBySalesRep',
    GetCarriers: '/salesrepresentative/getCarriers',
    GetCustomerReps: '/salesrepresentative/getCustomerRepsBySalesRep',
    Invite: '/salesrepresentative/invite',
    CarrierInvite: '/salesrepresentative/carrierInvite',
    GetAll: '/salesrepresentative',
    UpdateSettings: '/salesrepresentative/updateSettings',
    Profile: '/salesrepresentative',
    GetAllUsersBySalesRep: '/salesrepresentative/getAllUsersBySalesRep',
    DeleteUser: '/salesrepresentative/deleteUser'
  },
  CUSTOMER_REP: {
    Profile: 'customerRep'
  },
  QUOTE: {
    AddQuote: '/quote/addQuote',
    UpdateShipment: '/quote/updateShipment',
    DeleteShipment: '/quote/deleteShipment',
    RecentQuotes: '/quote/recentQuotes',
    updateCarrierType: '/quote/updateCarrierType',
    sendEmails: '/quote/sendEmails',
    GetQuote: '/quote/getone',
    GetAllQuotes: '/quote/getAllQuotes',
    MarkAsCompleted: '/quote/markAsCompleted',
    BookNowQuote: '/quote/booknow',
    Address: '/quote/address',
    TrackShipment: '/quote/track',
    Tracking: '/quote/tracking',
    FilterQuotes: '/quote/filterQuote',
    DownloadBOL: '/quote/downloadBOL',
    DownloadROC: '/quote/downloadROC',
    GuestTrackingData: '/quote/guestTracking',
    LoadAddress: '/quote/loadAddress',
    RefreshLtlRates: '/quote/refreshLtlRates',
    AddCargo: '/quote/addCargo',
    AddAdditionalCharges: '/quote/addAdditionalCharges',
    SearchQuotes: '/quote/searchQuotes',
    DashboardGraph: '/quote/dashboardGraph',
    DashboardTable: 'quote/dashboardTable'
  },
  BID: {
    GetCarrierBids: '/bid/getCarrierBids',
    GetBid: 'bid/getbid',
    GetQuoteBids: '/bid/getQuoteBids',
    AddCarrierSignature: '/bid/signature',
    AcceptBid: '/bid/acceptbid',
    DeclineBid: '/bid/declineBid'
  },
  FILES: {
    AddFile: '/files/addFile'
  },
  PAYMENT: {
    IntentForPaymet: '/payment/createIntentForPayment',
    CreatePaymentIntent: '/payment/createPaymentIntent'
  },
  NOTES: {
    AddNote: '/notes/addNote',
    GetAllInternalNotes: '/notes/getAllInternalNotes',
    GetAllExternalNotes: '/notes/getAllExternalNotes'
  },
  COMPANY: {
    GetAllCompanies: '/company/getAllCompanies',
    GetGraph: '/company/getGraph',
    GetCompany: '/company/getCompany',
    GetUsers: '/company/getUsers',
    UpdateCompany: '/company/updateCompany',
    UpdateEmailPreferences: '/company/updateEmailPreferences'
  }
};

export default API_ENDPOINTS;
