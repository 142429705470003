import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const getAllCompanies = () => {
  return API.get(API_ENDPOINTS.COMPANY.GetAllCompanies, {
    params: {
      platform: 'web'
    }
  });
};

const getCompanyGraphData = (duration, companyId) => {
  return API.get(`${API_ENDPOINTS.COMPANY.GetGraph}/${companyId}`, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web',
      duration
    }
  });
};

const getCompanyData = (companyId) => {
  return API.get(`${API_ENDPOINTS.COMPANY.GetCompany}/${companyId}`, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const getUsersData = (companyId) => {
  return API.get(`${API_ENDPOINTS.COMPANY.GetUsers}/${companyId}`, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};
const updateCompanyData = (companyId, body) => {
  return API.put(`${API_ENDPOINTS.COMPANY.UpdateCompany}/${companyId}`, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};
const updateEmailPreferences = (companyId, body) => {
  return API.post(
    `${API_ENDPOINTS.COMPANY.UpdateEmailPreferences}/${companyId}`,
    body,
    {
      params: {
        token: localStorage.getItem('token'),
        platform: 'web'
      }
    }
  );
};

export {
  getAllCompanies,
  getCompanyGraphData,
  getCompanyData,
  getUsersData,
  updateCompanyData,
  updateEmailPreferences
};
