import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const shipperSignUpService = (body, isNewCompany, salesRep = false) => {
  return API.post(API_ENDPOINTS.USERS.SignUp, body, {
    params: {
      salesRep,
      newCompany: isNewCompany
    }
  });
};
const updateEmailPreferences = (customerId, body) => {
  return API.post(
    `${API_ENDPOINTS.USERS.UpdateEmailPreference}/${customerId}`,
    body,
    {
      params: {
        token: localStorage.getItem('token'),
        platform: 'web'
      }
    }
  );
};

const getAllShipperService = () => {
  return API.get(API_ENDPOINTS.USERS.Profile, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const shipperProfileService = (userId) => {
  return API.get(`${API_ENDPOINTS.USERS.Profile}/${userId}`, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const updateShipperService = (userId, body) => {
  return API.put(`${API_ENDPOINTS.USERS.Profile}/${userId}`, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const updateShipperDataService = (userId, body) => {
  return API.post(`${API_ENDPOINTS.USERS.Update}/${userId}`, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const shipperStripeOnBoardService = () => {
  return API.get(API_ENDPOINTS.USERS.StripeOnBoard, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const checkSignupLinkService = (token) => {
  return API.get(API_ENDPOINTS.USERS.CheckSignupLink, {
    params: {
      token,
      platform: 'web'
    }
  });
};

const activateUserService = (id, body) => {
  return API.post(API_ENDPOINTS.USERS.ActivateUser, body, {
    params: {
      id,
      platform: 'web'
    }
  });
};

const addLocationService = (body) => {
  return API.post(API_ENDPOINTS.USERS.AddLocation, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const getLocationService = (locationId) => {
  return API.get(`${API_ENDPOINTS.USERS.GetLocation}/${locationId}`, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const updateLocationService = (locationId, body) => {
  return API.put(`${API_ENDPOINTS.USERS.UpdateLocation}/${locationId}`, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const deleteLocationService = (locationId) => {
  const myToken = localStorage.getItem('token');
  return API.del(
    `${API_ENDPOINTS.USERS.DeleteLocation}/${locationId}?token=${myToken}`,
    {
      params: {
        token: localStorage.getItem('token'),
        platform: 'web'
      }
    }
  );
};

const addNewCargoService = (body) => {
  return API.post(`${API_ENDPOINTS.USERS.AddNewCargo}`, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const updateCargoService = (body, cargoId) => {
  return API.put(`${API_ENDPOINTS.USERS.UpdateCargo}/${cargoId}`, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const getCargoService = (cargoId) => {
  return API.get(`${API_ENDPOINTS.USERS.GetCargo}/${cargoId}`, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const getSavedCargosService = (userId) => {
  return API.get(`${API_ENDPOINTS.USERS.GetSavedCargos}/${userId}`, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const deleteCargoService = (cargoId) => {
  return API.del(`${API_ENDPOINTS.USERS.DeleteCargo}/${cargoId}`, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const deleteAccount = () => {
  const myToken = localStorage.getItem('token');
  return API.del(`${API_ENDPOINTS.USERS.DeleteAccount}?token=${myToken}`, {
    params: {
      platform: 'web'
    }
  });
};
const updateUserSettings = (columns) => {
  return API.put(API_ENDPOINTS.USERS.UpdateSettings, columns, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};
const getUser = (userId) => {
  return API.get(`${API_ENDPOINTS.USERS.Profile}/${userId}`, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

// const billingPortalService = () => {
//   return API.get(API_ENDPOINTS.USERS.BillingPortal, {
//     params: {
//       token: localStorage.getItem('token'),
//       platform: 'web',
//     },
//   });
// };

const acceptedLoadsService = () => {};

export {
  acceptedLoadsService,
  getAllShipperService,
  shipperSignUpService,
  shipperProfileService,
  updateShipperService,
  updateShipperDataService,
  // billingPortalService,
  checkSignupLinkService,
  shipperStripeOnBoardService,
  activateUserService,
  addLocationService,
  getLocationService,
  updateLocationService,
  deleteLocationService,
  addNewCargoService,
  updateCargoService,
  getSavedCargosService,
  getCargoService,
  deleteCargoService,
  updateEmailPreferences,
  updateUserSettings,
  deleteAccount,
  getUser
};
