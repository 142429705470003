import React, { useEffect, useState, memo } from 'react';
import { useLocation } from 'react-router-dom';

import toast from 'react-hot-toast';

import QuoteDetailsCard from '../../components/QuoteDetailsCard';

import {
  getAllActiveQuotesService,
  getAllCompletedQuotesService,
  getAllEnrouteQuotesService,
  getAllPendingQuotesService,
  getAllRecentQuotes
} from '../../services/quote.service';
import { LTL_FREIGHT } from '../../constants/service_type.constants';

import { VENDORS } from '../../constants/carrier.constants';

const QuotesListingPage = memo(() => {
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const [title, setTitle] = useState('');
  const [quotes, setQuotes] = useState([]);
  const [loadType, setLoadType] = useState('');
  const [isAPICalled, setisAPICalled] = useState(false);

  async function fetchRecentQuotes(isReset, pageNo) {
    try {
      const apiResponse = await getAllRecentQuotes(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      const response = apiResponse.data.quotes;
      if (!response.length) setIsEnd(true);

      const finalRes = isReset ? response : [...quotes, ...response];

      if (apiResponse.data.flag) {
        const carriers = VENDORS.map((vendor) => vendor.name);
        const quoteArr = [];
        finalRes.forEach((quote) => {
          if (quote.service_type !== LTL_FREIGHT) {
            if (quote.estimate) quoteArr.push(quote);
          } else {
            const rates = [];
            carriers.forEach((carrier) => {
              const rate = quote[carrier]?.estimate;
              if (rate && rate !== 0) rates.push(rate);
            });
            const quoteData = { ...quote };
            const min = Math.min(...rates);

            quoteData.minRate = min === Infinity ? 0 : min;
            if (quoteData.minRate) {
              quoteArr.push(quoteData);
            }
          }
        });
        setQuotes(quoteArr);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchAvailableLoads(isReset, pageNo) {
    try {
      const response = await getAllActiveQuotesService(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      if (!response?.data?.quotes?.length) setIsEnd(true);

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.quotes
          : [...quotes, ...response.data.quotes];
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchPendingLoads(isReset, pageNo) {
    try {
      const response = await getAllPendingQuotesService(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      if (!response?.data?.quotes?.length) setIsEnd(true);

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.quotes
          : [...quotes, ...response.data.quotes];
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchEnrouteLoads(isReset, pageNo) {
    try {
      const response = await getAllEnrouteQuotesService(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      if (!response?.data?.quotes?.length) setIsEnd(true);

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.quotes
          : [...quotes, ...response.data.quotes];
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchCompletedLoads(isReset, pageNo) {
    try {
      const response = await getAllCompletedQuotesService(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      if (!response?.data?.quotes?.length) setIsEnd(true);

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.quotes
          : [...quotes, ...response.data.quotes];
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    switch (location.pathname) {
      case '/salesRep/recentQuotes':
        setTitle('Recent Quotes');
        fetchRecentQuotes(true, 1);
        setPage(1);
        setLoadType('salesRep-recent-quotes');
        break;

      case '/salesRep/active-shipment':
        setTitle('Assigned Shipment');
        fetchAvailableLoads(true, 1);
        setLoadType('salesRep-active-quotes');
        break;

      case '/salesRep/pending-quotes':
        setTitle('Pending');
        fetchPendingLoads(true, 1);
        setPage(1);
        setLoadType('salesRep-pending-quotes');
        break;

      case '/salesRep/enroute-quotes':
        setTitle('Enroute');
        fetchEnrouteLoads(true, 1);
        setLoadType('salesRep-enroute-quotes');
        break;

      case '/salesRep/completed-quotes':
        setTitle('Delivered');
        fetchCompletedLoads(true, 1);
        setLoadType('salesRep-completed-quotes');
        break;

      default:
        break;
    }
  }, [location.pathname]);

  const handleLoadMore = () => {
    switch (location.pathname) {
      case '/salesRep/recentQuotes':
        fetchRecentQuotes(undefined, page + 1);
        break;

      case '/salesRep/active-shipment':
        fetchAvailableLoads(undefined, page + 1);
        break;

      case '/salesRep/pending-quotes':
        fetchPendingLoads(undefined, page + 1);
        break;

      case '/salesRep/enroute-quotes':
        fetchEnrouteLoads(undefined, page + 1);
        break;

      case '/salesRep/completed-quotes':
        fetchCompletedLoads(undefined, page + 1);
        break;

      default:
        break;
    }
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <section>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10" />
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10" />
        </svg>
      </div>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div className="mb-6  py-4 relative">
            {/* <h3 className="text-3xl font-bold ">{title}</h3> */}
            <div className="w-full md:w-6/12 inline-block">
              <h3 className="text-3xl font-bold inline-block text-navy-500">
                {title}
              </h3>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            {isAPICalled && quotes.length > 0
              ? quotes.map((quote) => (
                  <QuoteDetailsCard
                    key={quote.id}
                    quote={quote}
                    loadType={loadType}
                  />
                ))
              : 'No loads to display'}
          </div>
          {!isEnd ? (
            <button
              type="submit"
              className="bg-navy-500 text-white px-10 py-2 text-sm font-semibold float-right mt-4"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          ) : (
            <p className="text-center">No more data</p>
          )}
        </div>
      </div>
    </section>
  );
});

export default QuotesListingPage;
