import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { Modal, Collapse, ConfigProvider } from 'antd';
import { FaCircleExclamation } from 'react-icons/fa6';
import { IoIosSquare } from 'react-icons/io';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import {
  dropdownOptions,
  dropDownStyles
} from '../../constants/dashboard.constants';
import { deleteUser } from '../../services/salesrep.service';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { VENDORS } from '../../constants/carrier.constants';
import {
  getCompanyData,
  getCompanyGraphData,
  getUsersData,
  updateCompanyData,
  updateEmailPreferences
} from '../../services/company.service';
import AddNewUserForm from './AddNewUserForm';

const CustomerDetailsPage = () => {
  const { confirm } = Modal;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const { customerId: companyId } = useParams();
  const navigate = useNavigate();
  const [currentOption, setCurrentOption] = useState({
    value: 30,
    label: 'Last 30 days'
  });

  const [isAPICalled, setAPICalled] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [chartData, setChartData] = useState({});
  const [isChartAPICalled, setIsChartAPICalled] = useState(false);
  const [currentCompany, setCurrentCompany] = useState();
  const [tableData, setTableData] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const validationSchema = yup.object({
    name: yup.string().required('Shipper name is required'),
    address: yup.object({
      address1: yup.string().required('Address Line 1 is required'),
      address2: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      country: yup.string().required('Country is required').default('US'),
      zipcode: yup.string().required('Zip code is required')
    }),
    ltlFreight: yup
      .number()
      .positive()
      .min(1)
      .max(100)
      .required('Commission is required.'),
    minLTLFreight: yup.number().positive().required('Commission is required.'),
    maxLTLFreight: yup.number().positive().required('Commission is required.'),
    blanketWrap: yup
      .number()
      .positive()
      .min(1)
      .max(100)
      .required('Commission is required.'),
    minBlanketWrap: yup.number().positive().required('Commission is required.'),
    maxBlanketWrap: yup.number().positive().required('Commission is required.'),
    truckLoadFlat: yup
      .number()
      .positive()
      .min(1)
      .max(100)
      .required('Commission is required.'),
    minTruckloadFlat: yup
      .number()
      .positive()
      .required('Commission is required.'),
    maxTruckloadFlat: yup
      .number()
      .positive()
      .required('Commission is required.'),
    truckLoadVan: yup
      .number()
      .positive()
      .min(1)
      .max(100)
      .required('Commission is required.'),
    minTruckloadVan: yup
      .number()
      .positive()
      .required('Commission is required.'),
    maxTruckloadVan: yup
      .number()
      .positive()
      .required('Commission is required.'),
    partialFlat: yup
      .number()
      .positive()
      .min(1)
      .max(100)
      .required('Commission is required.'),
    minPartialFlat: yup.number().positive().required('Commission is required.'),
    maxPartialFlat: yup.number().positive().required('Commission is required.'),
    partialVan: yup
      .number()
      .positive()
      .min(1)
      .max(100)
      .required('Commission is required.'),
    minPartialVan: yup.number().positive().required('Commission is required.'),
    maxPartialVan: yup.number().positive().required('Commission is required.'),
    newQuoteNotify: yup.bool(),
    shipmentBookNotify: yup.bool(),
    shipmentDeliverNotify: yup.bool(),
    shipperNewQuoteNotify: yup.bool(),
    shipperShipmentBookNotify: yup.bool(),
    shipperShipmentDeliverNotify: yup.bool()
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const form = watch();
  const fetchUserDetails = async () => {
    try {
      const response = await getUsersData(companyId);
      setTableData(response?.data?.data);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  async function fetchCompanyDetails() {
    try {
      const response = await getCompanyData(companyId);
      const user = response?.data?.data;

      setCurrentCompany(user);
      reset({
        name: user.name,
        ltlFreight: user.ltlFreight,
        minLTLFreight: user.minLTLFreight,
        maxLTLFreight: user.maxLTLFreight,
        blanketWrap: user.blanketWrap,
        minBlanketWrap: user.minBlanketWrap,
        maxBlanketWrap: user.maxBlanketWrap,
        truckLoadFlat: user.truckLoadFlat,
        minTruckloadFlat: user.minTruckloadFlat,
        maxTruckloadFlat: user.maxTruckloadFlat,
        truckLoadVan: user.truckLoadVan,
        minTruckloadVan: user.minTruckloadVan,
        maxTruckloadVan: user.maxTruckloadVan,
        partialFlat: user.partialFlat,
        minPartialFlat: user.minPartialFlat,
        maxPartialFlat: user.maxPartialFlat,
        partialVan: user.partialVan,
        minPartialVan: user.minPartialVan,
        maxPartialVan: user.maxPartialVan,
        newQuoteNotify: user?.salesRepEmailPreferences[0]?.newQuoteNotify,
        shipmentBookNotify:
          user?.salesRepEmailPreferences[0]?.shipmentBookNotify,
        shipmentDeliverNotify:
          user?.salesRepEmailPreferences[0]?.shipmentDeliverNotify,

        address: {
          address1: user?.address?.address1,
          address2: user?.address?.address2,
          city: user?.address?.city,
          state: user?.address?.state,
          zipcode: user?.address?.zipcode
        }
      });
      setCustomer(user);
      setAPICalled(true);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const changeMode = () => setEditMode((value) => !value);

  const onBackArrowClick = () => {
    navigate(-1);
  };

  function carrierChangeHandler(e, carrier) {
    let carrierList = customer.disabledCarrier || [];
    if (e.target.checked) {
      carrierList = carrierList.filter((car) => car !== carrier);
    } else {
      carrierList.push(carrier);
    }
    setCustomer({ ...customer, disabledCarrier: carrierList });
  }
  const onSubmitForm = async (data) => {
    try {
      if (isValid) {
        await updateCompanyData(companyId, {
          ...data,
          disabledCarrier: customer.disabledCarrier
        });

        const response = await updateEmailPreferences(companyId, {
          salesRepEmailPreferences: {
            newQuoteNotify: data?.newQuoteNotify,
            shipmentBookNotify: data?.shipmentBookNotify,
            shipmentDeliverNotify: data?.shipmentDeliverNotify
          }
        });
        if (response?.data?.flag) {
          toast.success('Customer Details updated successfully!');
          changeMode();
        } else {
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    } finally {
      fetchCompanyDetails();
    }
  };

  const indicators = [
    {
      colour: 'rgba(255, 99, 132, 0.4)',
      text: 'Recent'
    },
    {
      colour: 'rgba(54, 162, 235, 0.4)',
      text: 'Pending'
    },
    {
      colour: 'rgba(255, 206, 86, 0.4)',
      text: 'Assigned'
    },
    {
      colour: 'rgba(75, 192, 192, 0.4)',
      text: 'En-route'
    },
    {
      colour: 'rgba(153, 102, 255, 0.4)',
      text: 'Delivered'
    }
  ];

  const barOptions = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true
        }
      }
    },
    legend: {
      position: 'bottom',
      display: true,
      onClick: (e) => {
        if (e.event) {
          e.event.preventDefault();
        }
      },
      labels: {
        fontColor: 'rgb(35, 31, 32)',
        usePointStyle: true,
        boxWidth: 9,
        fontSize: 16
      }
    },
    responsive: true,
    cutoutPercentage: 50
  };

  const handleChange = (selectedOption) => {
    setCurrentOption(selectedOption);
  };

  async function fetchGraphData(duration) {
    try {
      const response = await getCompanyGraphData(duration, companyId);
      const { bidStatuses, recentQuotes } = response.data;

      setChartData({
        labels: Array(5).fill(''),
        datasets: [
          {
            label: 'Number of Quotes',
            data: [
              recentQuotes,
              bidStatuses.bidAdded,
              bidStatuses.bidAccepted,
              bidStatuses.bidEnroute,
              bidStatuses.bidCompleted
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)'
            ],
            borderWidth: 0
          }
        ]
      });
      setIsChartAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const fetchData = async () => {
    const link = localStorage.getItem('link');
    if (link) {
      localStorage.removeItem('link');
      return navigate(link);
    }

    try {
      await fetchGraphData(currentOption?.value);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const res = await deleteUser(userId);
      if (res?.data?.flag) {
        toast.success(res?.data?.message);
        fetchUserDetails();
      }
    } catch (error) {
      console.log({ error });
      toast.error('Something went wrong!');
    }
  };

  const handleDeleteClick = (userId) => {
    confirm({
      title: (
        <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
          Are you sure you want to delete this user ?
        </span>
      ),
      icon: (
        <FaCircleExclamation
          style={{
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            color: 'red',
            paddingRight: '15px'
          }}
        />
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(userId);
      },
      centered: true
    });
  };
  const handleEdit = (index) => {
    const currUser = tableData[index];
    navigate(`/customers/editUsers/${currUser?.id}`);
  };

  const handleStatusChange = async () => {
    try {
      await updateCompanyData(companyId, {
        ...form,
        disabledCarrier: customer.disabledCarrier,
        is_active: !currentCompany?.is_active
      });
      fetchCompanyDetails();
    } catch (error) {
      console.log({ error });
      toast.error('Something Went Wrong!');
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      setChartData();
    };
  }, [currentOption]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
    fetchCompanyDetails();
    fetchUserDetails();
  }, [companyId]);

  return (
    <section>
      <div className="py-10 bg-gray-50 radius-for-skewed">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-3 mb-8">
              <div className="relative mb-6 text-navy-500">
                <div
                  tabIndex="-1"
                  role="button"
                  onKeyPress={() => {}}
                  onClick={onBackArrowClick}
                  className="inline-flex items-center"
                >
                  <LeftArrowIcon className="left-0 flex items-center w-6 h-6 " />
                  <p className="ml-3">Back to the list</p>
                </div>
              </div>
              <div className="flex flex-row py-2 my-2 justify-between">
                <h3 className="text-3xl font-bold mb-2 text-navy-500">
                  This month&apos;s stats
                </h3>
                <div className="w-48">
                  <Select
                    value={currentOption}
                    onChange={handleChange}
                    options={dropdownOptions}
                    isSearchable={false}
                    styles={dropDownStyles}
                  />
                </div>
              </div>
              {isChartAPICalled && chartData && (
                <div className="flex flex-row gap-6 pt-2 px-3 border border-gray-300 h-60 items-center mb-8">
                  <Bar data={chartData} options={barOptions} />
                  <div class="grid grid-cols-3 ">
                    {indicators.map(({ colour, text }) => (
                      <div class="flex flex-row gap-2 items-center py-2 my-2 mx-8">
                        <IoIosSquare
                          size={25}
                          style={{
                            color: colour
                          }}
                        />
                        <span>{text}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex flex-col my-4">
                <div className="font-bold text-lg">Billing</div>
                <div className="flex flex-row gap-12 py-6">
                  <div className="flex flex-col">
                    <span className=" text-gray-600 mb-2">Credit Limit:</span>
                    <span className="font-semibold">
                      {currentCompany?.creditLimit}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className=" text-gray-600 mb-2">Open balance:</span>
                    <span className="font-semibold">NA</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-600 mb-2">Terms:</span>
                    <span className="font-semibold">
                      {currentCompany?.paymentTerms}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-col my-8 gap-8">
                <div className="flex flex-row justify-between h-10">
                  <div className="flex flex-row gap-8 w-full items-end">
                    <span className="font-bold text-2xl">
                      {currentCompany?.name}
                    </span>
                    <div
                      className={`flex items-center justify-center rounded-4xl w-32 p-1 ${
                        currentCompany?.is_active
                          ? 'btn-container--on'
                          : 'btn-container--off'
                      }`}
                    >
                      <span className="mr-1 ml-0">
                        {currentCompany?.is_active ? 'Active' : 'Inactive'}
                      </span>
                      <div
                        className={`btn-switch ${
                          currentCompany?.is_active ? 'btn-switch--on' : ''
                        }`}
                        onClick={handleStatusChange}
                        onKeyPress={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        <div
                          className={`btn-switch-circle ${
                            currentCompany?.is_active
                              ? 'btn-switch-circle--on'
                              : ''
                          }`}
                          onClick={handleStatusChange}
                          onKeyPress={() => {}}
                          role="button"
                          tabIndex={0}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="bg-navy-500 hover:bg-navy-600 text-white rounded-lg w-40"
                    onClick={() => setShowAddUserModal(true)}
                  >
                    Add New User
                  </button>
                  <AddNewUserForm
                    showAddUserModal={showAddUserModal}
                    setShowAddUserModal={setShowAddUserModal}
                    fetchUserDetails={fetchUserDetails}
                    companyId={companyId}
                  />
                </div>
                <table className="w-full ">
                  <thead className="text-left">
                    <tr>
                      <th className="text-gray-400 px-2">Name</th>
                      <th className="text-gray-400">Email</th>
                      <th className="text-gray-400">Phone Number</th>
                      <th className="text-gray-400">Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr
                        className="border border-gray-300 text-sm font-semibold"
                        key={item?.id}
                      >
                        <td className="py-2 px-2 ">{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.role.roleType}</td>
                        <td
                          className="text-red-600 hover:text-red-700 cursor-pointer underline"
                          onClick={() => handleDeleteClick(item?.id)}
                        >
                          Delete
                        </td>
                        <td
                          className="text-navy-300 hover:text-navy-500 cursor-pointer underline"
                          onClick={() => handleEdit(index)}
                        >
                          Edit
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <ConfigProvider
                theme={{
                  components: {
                    Collapse: {
                      contentBg: '#00b96b'
                    }
                  }
                }}
              >
                <Collapse
                  items={[
                    {
                      key: '1',
                      label: (
                        <span className="font-semibold">Customer Details</span>
                      ),
                      children: isAPICalled && (
                        <form onSubmit={handleSubmit(onSubmitForm)}>
                          <div className="w-full  pb-6 mb-6 md:w-1/2 md:pr-12">
                            <h3 className="mb-4 text-3xl font-bold text-left text-navy-500">
                              Customer Details
                            </h3>
                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Company Name
                              </label>
                              <input
                                {...register('name')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="text"
                                disabled={!isEditMode}
                              />
                              {errors.name && (
                                <span className="text-red-700 text-md">
                                  {errors.name.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Address line 1
                              </label>
                              <input
                                {...register('address.address1')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="text"
                                disabled={!isEditMode}
                              />
                              {errors.address?.address1 && (
                                <span className="text-red-700 text-md">
                                  {errors.address.address1.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Address line 2
                              </label>
                              <input
                                {...register('address.address2')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="text"
                                disabled={!isEditMode}
                              />
                              {errors.address?.address2 && (
                                <span className="text-red-700 text-md">
                                  {errors.address.address2.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                City
                              </label>
                              <input
                                {...register('address.city')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="text"
                                disabled={!isEditMode}
                              />
                              {errors.address?.city && (
                                <span className="text-red-700 text-md">
                                  {errors.address.city.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label
                                className="block mb-2 tracking-wide"
                                htmlFor="state"
                              >
                                State
                              </label>
                              <input
                                {...register('address.state')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="text"
                                disabled={!isEditMode}
                              />
                              {errors.pickup_address?.state && (
                                <span className="text-red-700 text-md">
                                  {errors.address.state.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Zip code
                              </label>
                              <input
                                {...register('address.zipcode')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="text"
                                disabled={!isEditMode}
                              />
                              {errors.address?.zipcode && (
                                <span className="text-red-700 text-md">
                                  {errors.address.zipcode.message}
                                </span>
                              )}
                            </div>
                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Minimum LTL Freight Commission: ($)
                              </label>
                              <input
                                {...register('minLTLFreight')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.minLTLFreight && (
                                <span className="text-red-700 text-md">
                                  {errors.minLTLFreight.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Maximum LTL Freight Commission: ($)
                              </label>
                              <input
                                {...register('maxLTLFreight')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.maxLTLFreight && (
                                <span className="text-red-700 text-md">
                                  {errors.maxLTLFreight.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                LTL Freight Commission: (%)
                              </label>
                              <input
                                {...register('ltlFreight')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="100"
                                disabled={!isEditMode}
                              />
                              {errors.ltlFreight && (
                                <span className="text-red-700 text-md">
                                  {errors.ltlFreight.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Minimum Blanket Wrap Commission: ($)
                              </label>
                              <input
                                {...register('minBlanketWrap')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.minBlanketWrap && (
                                <span className="text-red-700 text-md">
                                  {errors.minBlanketWrap.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Maximum Blanket Wrap Commission: ($)
                              </label>
                              <input
                                {...register('maxBlanketWrap')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.maxBlanketWrap && (
                                <span className="text-red-700 text-md">
                                  {errors.maxBlanketWrap.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Blanket Wrap Commission: (%)
                              </label>
                              <input
                                {...register('blanketWrap')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="100"
                                disabled={!isEditMode}
                              />
                              {errors.blanketWrap && (
                                <span className="text-red-700 text-md">
                                  {errors.blanketWrap.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Minimum Truckload - Flatbed Commission: ($)
                              </label>
                              <input
                                {...register('minTruckloadFlat')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.minTruckloadFlat && (
                                <span className="text-red-700 text-md">
                                  {errors.minTruckloadFlat.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Maximum Truckload - Flatbed Commission: ($)
                              </label>
                              <input
                                {...register('maxTruckloadFlat')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.maxTruckloadFlat && (
                                <span className="text-red-700 text-md">
                                  {errors.maxTruckloadFlat.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Truckload - Flatbed Commission: (%)
                              </label>
                              <input
                                {...register('truckLoadFlat')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="100"
                                disabled={!isEditMode}
                              />
                              {errors.truckLoadFlat && (
                                <span className="text-red-700 text-md">
                                  {errors.truckLoadFlat.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Minimum Truckload - Van Commission: ($)
                              </label>
                              <input
                                {...register('minTruckloadVan')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.minTruckloadVan && (
                                <span className="text-red-700 text-md">
                                  {errors.minTruckloadVan.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Maximum Truckload - Van Commission: ($)
                              </label>
                              <input
                                {...register('maxTruckloadVan')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.maxTruckloadVan && (
                                <span className="text-red-700 text-md">
                                  {errors.maxTruckloadVan.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Truckload - Van Commission: (%)
                              </label>
                              <input
                                {...register('truckLoadVan')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="100"
                                disabled={!isEditMode}
                              />
                              {errors.truckLoadVan && (
                                <span className="text-red-700 text-md">
                                  {errors.truckLoadVan.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Minimum Partial Direct Flatbed Commission: ($)
                              </label>
                              <input
                                {...register('minPartialFlat')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.minPartialFlat && (
                                <span className="text-red-700 text-md">
                                  {errors.minPartialFlat.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Maximum Partial Direct Flatbed Commission: ($)
                              </label>
                              <input
                                {...register('maxPartialFlat')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.maxPartialFlat && (
                                <span className="text-red-700 text-md">
                                  {errors.maxPartialFlat.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Partial Direct Flatbed Commission: (%)
                              </label>
                              <input
                                {...register('partialFlat')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="100"
                                disabled={!isEditMode}
                              />
                              {errors.partialFlat && (
                                <span className="text-red-700 text-md">
                                  {errors.partialFlat.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Minimum Partial Direct Van Commission: ($)
                              </label>
                              <input
                                {...register('minPartialVan')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.minPartialVan && (
                                <span className="text-red-700 text-md">
                                  {errors.minPartialVan.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Maximum Partial Direct Van Commission: ($)
                              </label>
                              <input
                                {...register('maxPartialVan')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                disabled={!isEditMode}
                              />
                              {errors.maxPartialVan && (
                                <span className="text-red-700 text-md">
                                  {errors.maxPartialVan.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full md:w-9/12">
                              <label className="block mb-2 tracking-wide">
                                Partial Direct Van Commission: (%)
                              </label>
                              <input
                                {...register('partialVan')}
                                className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                max="100"
                                disabled={!isEditMode}
                              />
                              {errors.partialVan && (
                                <span className="text-red-700 text-md">
                                  {errors.partialVan.message}
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col my-8">
                              <div className="font-bold text-2xl text-navy-500 underline">
                                Email Preferences
                              </div>

                              <div className="flex flex-col">
                                <div className="mt-4 w-full">
                                  <div className="font-bold text-lg mb-4 text-navy-500">
                                    SalesRep
                                  </div>
                                  <div className="w-full mt-4 pb-4">
                                    <label className="block mb-2 tracking-wide">
                                      <input
                                        type="checkbox"
                                        disabled={!isEditMode}
                                        {...register('newQuoteNotify')}
                                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                      />
                                      <span
                                        className={`ml-1 ${!isEditMode ? 'text-gray-500' : ''}`}
                                      >
                                        Receive new quote emails
                                      </span>
                                    </label>
                                    {errors.newQuoteNotify && (
                                      <span className="text-red-700 text-md">
                                        {errors.newQuoteNotify.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="w-full pb-4">
                                    <label className="block mb-2 tracking-wide">
                                      <input
                                        type="checkbox"
                                        disabled={!isEditMode}
                                        {...register('shipmentBookNotify')}
                                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                      />
                                      <span
                                        className={`ml-1 ${!isEditMode ? 'text-gray-500' : ''}`}
                                      >
                                        Receive shipment book emails
                                      </span>
                                    </label>
                                    {errors.shipmentBookNotify && (
                                      <span className="text-red-700 text-md">
                                        {errors.shipmentBookNotify.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="w-full pb-4">
                                    <label className="block mb-2 tracking-wide">
                                      <input
                                        type="checkbox"
                                        disabled={!isEditMode}
                                        {...register('shipmentDeliverNotify')}
                                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                      />
                                      <span
                                        className={`ml-1 ${!isEditMode ? 'text-gray-500' : ''}`}
                                      >
                                        Receive shipment delivery emails
                                      </span>
                                    </label>
                                    {errors.shipmentDeliverNotify && (
                                      <span className="text-red-700 text-md">
                                        {errors.shipmentDeliverNotify.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="w-full pb-8 md:w-9/12">
                              <h3 className="mb-4 font-bold">
                                Select LTL carriers
                              </h3>
                              {VENDORS.map((carrier) => (
                                <label className="block mb-2 tracking-wide">
                                  <input
                                    type="checkbox"
                                    disabled={!isEditMode}
                                    value={carrier.name}
                                    onChange={(e) =>
                                      carrierChangeHandler(e, carrier.name)
                                    }
                                    defaultChecked={
                                      !customer?.disabledCarrier?.includes(
                                        carrier.name
                                      )
                                    }
                                    className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                                  />
                                  <span className="ml-1">
                                    {`${carrier.display_name} (${carrier.service})`}
                                  </span>
                                </label>
                              ))}

                              {errors.notificationEnabled && (
                                <span className="text-red-700 text-md">
                                  {errors.notificationEnabled.message}
                                </span>
                              )}
                            </div>

                            <div className="w-full text-left md:w-9/12 ">
                              <button
                                type="button"
                                onClick={changeMode}
                                className="relative inline-block w-full py-4 pl-4 mb-2 mr-12 text-xl font-bold text-left text-white transition duration-75 border bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                              >
                                Edit Details
                                <span
                                  style={{ transform: 'rotateZ(90deg)' }}
                                  className="absolute inset-y-0 right-0 px-2 mt-2 mb-1 mr-2 text-4xl pointer-events-none"
                                >
                                  &#9998;
                                </span>
                              </button>
                            </div>

                            <div className="w-full text-left md:w-9/12 ">
                              <button
                                type="submit"
                                disabled={!isEditMode}
                                className={`${
                                  !isEditMode
                                    ? 'bg-white text-gray-400 border-2 border-gray-400 cursor-not-allowed'
                                    : 'bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 border border-navy-500 hover:border-navy-600 cursor-pointer'
                                } mr-12 inline-block pl-4 mb-2 w-full py-4  border  text-xl font-bold transition duration-75 text-left relative `}
                              >
                                Update Details
                                <span className="absolute inset-y-0 right-0 px-2 mt-2 mr-2 text-3xl pointer-events-none">
                                  &#10003;
                                </span>
                              </button>
                            </div>
                            <div className="w-full text-left md:w-9/12 mt-6">
                              <button
                                type="button"
                                onClick={() =>
                                  navigate(`/shipper-profile/${companyId}`)
                                }
                                className="relative inline-block w-full py-4 pl-4 mb-2 mr-12 text-xl font-bold text-left text-white transition duration-75 border bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                              >
                                Add/Update Customer Profile
                              </button>
                            </div>
                          </div>
                        </form>
                      )
                    }
                  ]}
                  bordered={false}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerDetailsPage;
