import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Select, Space, ConfigProvider } from 'antd';
import { FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';
import { getAllCompanies } from '../../../services/company.service';

const BasicDetailsForm = ({
  isVerifyMode,
  setIsExistingCompany,
  setIsNewCompany,
  setCurrentCompany
}) => {
  const {
    register,
    formState: { errors, isValid }
  } = useFormContext();

  const navigate = useNavigate();

  const [companies, setCompanies] = useState([]);

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const fetchAllCompanies = async () => {
    try {
      const response = await getAllCompanies();
      setCompanies(response?.data?.data);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.message ?? 'Something Went Wrong!');
    }
  };

  const handleAddCompany = () => {
    if (isValid) {
      setIsNewCompany(true);
    }
  };

  const handleOnChange = (val) => {
    if (val) {
      setIsExistingCompany(true);
      setCurrentCompany(val);
    } else {
      setIsExistingCompany(false);
    }
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  return (
    <>
      <div
        onClick={onBackArrowClick}
        onKeyPress={() => {}}
        role="button"
        tabIndex="-1"
      >
        <LeftArrowIcon className="absolute inset-y-0 left-0 flex items-center w-6 h-6 mt-12 font-bold text-navy-500" />
      </div>
      <div className="p-4 mb-6 ml-6 text-navy-500">
        <p className="text-md">Send</p>
        <h3 className="text-3xl font-bold ">Shipment!</h3>
        <p className="text-md">Send with NET and be smarter.</p>
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Name
        </label>
        <input
          {...register('name')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="text"
        />
        {errors.name && (
          <span className="text-xs text-red-700">{errors.name.message}</span>
        )}
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Email
        </label>
        <input
          {...register('email')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="email"
          disabled={isVerifyMode}
        />
        {errors.email && (
          <span className="text-xs text-red-700">{errors.email.message}</span>
        )}
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Mobile Number
        </label>
        <input
          {...register('phone')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="text"
        />
        {errors.phone && (
          <span className="text-xs text-red-700">{errors.phone.message}</span>
        )}
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Create Password
        </label>
        <input
          {...register('password')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="password"
        />
        {errors.password && (
          <span className="text-xs text-red-700">
            {errors.password.message}
          </span>
        )}
      </div>
      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Confirm Password
        </label>
        <input
          {...register('confirmPassword')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="password"
        />
        {errors.confirmPassword && (
          <span className="text-xs text-red-700">
            {errors.confirmPassword.message}
          </span>
        )}
      </div>
      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-m font-bold text-navy-500"
          htmlFor=""
        >
          Select Company
        </label>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                contentFontSize: 15
              },
              Select: {
                optionActiveBg: '#DEEBFF',
                optionFontSize: 15
              }
            }
          }}
        >
          <Select
            style={{
              width: '100%',
              height: 40
            }}
            placeholder="Select Company"
            allowClear
            showSearch
            filterOption={filterOption}
            onChange={handleOnChange}
            dropdownRender={(menu) => (
              <>
                <Divider
                  style={{
                    margin: '8px 0'
                  }}
                />
                <Space
                  style={{
                    padding: '0 8px 4px'
                  }}
                >
                  <Button
                    type="primary"
                    icon={<FaPlus />}
                    onClick={handleAddCompany}
                    style={{ backgroundColor: '#2b5bb5', margin: '10px' }}
                  >
                    Add New Company
                  </Button>
                </Space>
                {menu}
              </>
            )}
            options={companies.map((item) => ({
              label: item?.name,
              value: item?.id
            }))}
          />
        </ConfigProvider>
      </div>
    </>
  );
};

export default BasicDetailsForm;

BasicDetailsForm.propTypes = {
  formContent: PropTypes.any.isRequired,
  isVerifyMode: PropTypes.bool.isRequired,
  setIsExistingCompany: PropTypes.func.isRequired,
  setIsNewCompany: PropTypes.func.isRequired,
  setCurrentCompany: PropTypes.func.isRequired
};
