import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { IoCloseSharp } from 'react-icons/io5';
import toast from 'react-hot-toast';
import { ADDITIONAL_FEES } from '../../constants/quote.constants';
import { addAdditionalChargesService } from '../../services/quote.service';

const customStyles = {
  control: (base, styleState) => ({
    ...base,
    background: 'whitesmoke',
    borderRadius: styleState.isFocused ? '3px 3px 0 0' : 3,
    boxShadow: styleState.isFocused ? null : null,
    fontWeight: 300,
    '&:hover': {
      borderColor: styleState.isFocused ? 'black' : 'transparent'
    }
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  })
};

const AddFeesPopup = ({ quote, feesType, setFeesType }) => {
  const [name, setName] = useState('');
  const [fees, setFees] = useState('');

  const submitData = async () => {
    try {
      if (!name || !fees || !feesType)
        return toast.error('Name and Fees are required.');

      const response = await addAdditionalChargesService({
        name,
        fees: Number(fees),
        feesType,
        quoteId: quote.id
      });
      toast.success(response.data.message);
      window.location.reload();

      setFeesType('');
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  return (
    <div
      className="fixed inset-0 z-10 overflow-y-auto backdrop-filter 
                backdrop-brightness-50 "
    >
      <div className="flex items-center min-h-screen">
        <div className="relative w-full max-w-md mx-auto bg-white rounded-md shadow-lg border rounded-xl">
          <div className="sm:flex">
            <div className="text-center sm:text-left w-full p-3 mx-6 mt-2">
              <div className="flex items-center justify-between">
                <h4 className="text-xl font-extrabold text-navy-500">
                  Add Fees/Charges
                </h4>
                <IoCloseSharp
                  className="cursor-pointer"
                  size={25}
                  onClick={() => setFeesType('')}
                />
              </div>

              <div className="flex flex-col gap-3  py-4">
                <div>
                  <label className="text-sm font-bold">Name</label>
                  <Select
                    options={ADDITIONAL_FEES}
                    className="bg-gray-100"
                    styles={customStyles}
                    onChange={(option) => {
                      setName(option?.value);
                    }}
                  />
                </div>
                <div>
                  <label className="text-sm font-bold">Fees ($)</label>
                  <input
                    type="number"
                    min={0}
                    onWheel={(e) => e.target.blur()}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    onChange={(e) => setFees(e?.target?.value)}
                  />
                </div>
                <button
                  type="button"
                  className="text-center bg-navy-500 text-white w-full mt-4 py-2"
                  onClick={submitData}
                >
                  Add Charges
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFeesPopup;

AddFeesPopup.propTypes = {
  quote: PropTypes.object.isRequired,
  feesType: PropTypes.string.isRequired,
  setFeesType: PropTypes.string.isRequired
};
