import React, { useState, useCallback, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import Camera from '../../../assets/images/camera.png';
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';

import {
  FREIGHT_CLASS,
  GOODS_CONDITION,
  GOODS_TYPE,
  PACKAGE_TYPE
} from '../../../constants/quote.constants';

import { calculateDensity } from '../../../utils/quote.util';
import {
  addNewCargoService,
  updateCargoService
} from '../../../services/shipper.service';
import {
  addCargoService,
  uploadCargoFileService
} from '../../../services/quote.service';
import { useAuthContext } from '../../../contexts/auth.context';
import {
  LTL_FREIGHT,
  PARTIAL_FLAT,
  TRUCKLOAD_FLAT
} from '../../../constants/service_type.constants';

// addCargo
const AddNewCargo = ({ quote, isPopup, setIsPopup }) => {
  const [files, setFiles] = useState();
  const [fileURL, setfileURL] = useState();
  const [isFileSelected, setFileSelected] = useState(false);
  const [density, setDensity] = useState();
  const [cubitFeet, setCubitFeet] = useState();

  const { authUser } = useAuthContext();
  const { customerId } = useParams();
  const { state } = useLocation();

  const navigate = useNavigate();

  const validationSchema = yup.object({
    goods_condition: yup.mixed().required('Goods Condition is required'),
    freight_class: yup.mixed().when('dummy', {
      is: () => quote?.service_type === LTL_FREIGHT,
      then: () =>
        yup
          .mixed()
          .test({
            name: 'notFirstOption',
            message: 'Please select a valid Freight Class',
            test(value) {
              return value && value.value !== '0';
            }
          })
          .required('Freight Class is required'),
      otherwise: () => yup.mixed().nullable()
    }),
    nmfc_code: yup.string(),
    goods_type: yup.mixed().nullable(),
    package_type: yup.mixed().required('Cargo Package type is required'),
    width: yup
      .number()
      .positive()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .required('Cargo width is required'),
    length: yup
      .number()
      .positive()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .required('Cargo length is required'),
    height: yup
      .number()
      .positive()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .required('Cargo height is required'),
    weight: yup
      .number()
      .positive()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .required('Cargo weight is required'),
    desc: yup.string().required('Cargo description is required'),
    quantity: yup
      .number()
      .positive()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .required('Quanitity is required'),
    tarps: yup.bool(),
    files: yup.mixed().nullable(),
    cargo_img: yup.string().nullable()
  });

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const cargo = watch();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles[0]);
    setValue('cargo_img', URL.createObjectURL(acceptedFiles[0]));
    setfileURL(URL.createObjectURL(acceptedFiles[0]));
    setFileSelected(true);
  }, []);

  const { getRootProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (state) {
      reset({
        ...state,
        goods_condition: {
          value: state?.goods_condition?.id,
          label: state?.goods_condition?.display_name
        },
        goods_type: state?.goods_type?.map((type) => type.id),
        freight_class: {
          value: state.freight_class,
          label: state.freight_class
        },
        package_type: state?.package_type?.[0].id,
        cargo_img: state?.files?.fd
          ? `${process.env.REACT_APP_API_URL}/${state?.files?.fd}`
          : ''
      });
      setfileURL(
        state?.files?.fd
          ? `${process.env.REACT_APP_API_URL}/${state?.files?.fd}`
          : ''
      );
      setFiles('');
    } else {
      reset({
        quantity: '1',
        goods_condition: { value: '57f638ef95757c64129caea6', label: 'New' }
      });
    }
  }, []);

  const onFileChange = (e) => {
    setFiles(e.target.files[0]);
    setValue('cargo_img', URL.createObjectURL(e.target.files[0]));
    setfileURL(URL.createObjectURL(e.target.files[0]));
    setFileSelected(true);
  };

  const remove = () => {
    setfileURL(null);
    setFileSelected(false);
  };

  useEffect(() => {
    setDensity(calculateDensity(cargo).density);
    setCubitFeet(calculateDensity(cargo).cubitFeet);
  }, [cargo]);

  const customStyles = {
    control: (base, styleState) => ({
      ...base,
      background: 'whitesmoke',
      borderRadius: styleState.isFocused ? '3px 3px 0 0' : 3,
      boxShadow: styleState.isFocused ? null : null,
      fontWeight: 300,
      '&:hover': {
        borderColor: styleState.isFocused ? 'black' : 'transparent'
      }
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0
    }),
    menuList: (base) => ({
      ...base,
      padding: 0
    })
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const onSubmitForm = async (data) => {
    try {
      let fileId;
      if (isFileSelected && files) {
        const formData = new FormData();
        formData.append('cargo_image', files);
        const response = await uploadCargoFileService(formData);
        if (response.data.flag) {
          fileId = response.data.id;
          toast.success(response.data.message);
        }
      }

      const userId =
        authUser?.user?.usertype === 'user'
          ? authUser?.user?.companyId?.id
          : customerId;

      const cargoData = {
        quantity: data.quantity,
        package_type: data.package_type,
        goods_condition: data.goods_condition?.value,
        freight_class: data.freight_class?.value,
        nmfc_code: data.nmfc_code,
        height: data.height,
        width: data.width,
        length: data.length,
        weight: data.weight,
        desc: data.desc,
        goods_type: data.goods_type,
        files: fileId,
        companyId: userId,
        isDefault: data.isDefault,
        tarps: data.tarps
      };

      if (isPopup) {
        cargoData.quote = quote.id;
        if (data.ship_from) cargoData.ship_from = data.ship_from;
        if (data.ship_to) cargoData.ship_to = data.ship_to;

        await addCargoService(cargoData);
        toast.success('New cargo added successfully');
        window.location.reload();
      } else {
        const isEdit = !!state;

        if (isEdit) {
          await updateCargoService(cargoData, state.id);
          toast.success('Cargo updated successfully!');
        } else {
          await addNewCargoService(cargoData);
          toast.success('Cargo saved successfully!');
          navigate(-1);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const handleAddClick = () => {
    handleSubmit(onSubmitForm)();
  };

  return (
    <div className="container mx-auto">
      <div className=" mx-auto">
        <form onSubmit={handleSubmit}>
          <div className="mb-6 lg:mb-10 text-left">
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3 py-4 text-navy-500 my-4">
                  <div
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="-1"
                    onClick={() => onBackArrowClick()}
                  >
                    <LeftArrowIcon className="w-6 h-6 flex items-center text-navy-500 font-bold" />
                  </div>
                  <h3 className="text-2xl font-extrabold">Add New Cargo</h3>
                </div>
                {isPopup && (
                  <button type="button" onClick={() => setIsPopup(false)}>
                    <IoClose className=" text-4xl" />
                  </button>
                )}
              </div>
              <label
                className="w-full block text-black text-xl font-bold mb-6"
                htmlFor="delivery-date"
              >
                Packaging
              </label>
              <div className="flex justify-center items-center">
                <div className="w-1/3 flex flex-col">
                  <label
                    className="w-9/12 overflow-hidden flex flex-col text-center items-center bg-white rounded-xl border-2 border-dotted border-navy-500 cursor-pointer "
                    {...getRootProps({})}
                  >
                    {isFileSelected || fileURL ? (
                      <>
                        <input
                          type="file"
                          multiple={false}
                          accept="image/*"
                          onChange={onFileChange}
                          className="hidden"
                        />
                        <img
                          src={fileURL}
                          className="object-cover w-28 h-28"
                          alt="cargo_img"
                        />
                      </>
                    ) : (
                      <>
                        <span className="mt-2 p-8 pt-10 pb-10  text-base leading-normal">
                          <img
                            className="mx-auto mb-2"
                            src={Camera}
                            alt="camera"
                          />
                          Drag & Drop{' '}
                          <span className="text-gray-400">files here</span>{' '}
                          <br /> <span className="text-gray-400">OR</span>{' '}
                          <br />
                          Click <span className="text-gray-400">to upload</span>
                        </span>
                        <input
                          type="file"
                          multiple={false}
                          accept="image/*"
                          onChange={onFileChange}
                          className="hidden"
                        />
                      </>
                    )}
                  </label>
                  <button
                    type="button"
                    className="w-9/12 bg-red-500 hover:bg-red-400 text-white font-bold py-1 mt-4 "
                    onClick={remove}
                  >
                    Remove Picture
                  </button>
                </div>
                <div className="grid grid-cols-4 mb-10">
                  {PACKAGE_TYPE.map((packageType) => {
                    return (
                      <div className="float-left" key={packageType.value}>
                        <label>
                          <input
                            {...register(`package_type`)}
                            type="radio"
                            value={packageType.value}
                          />
                          <span className="ml-1">{packageType.label}</span>
                        </label>
                      </div>
                    );
                  })}
                  {errors.package_type && (
                    <span className="text-xs text-red-700 mt-8">
                      {errors.package_type.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="border border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
              <p className="text-left w-full block text-black text-xl font-bold mb-6">
                Dimensions
              </p>

              <div className="grid grid-cols-4 gap-4">
                <div className="mb-8   float-left  ">
                  <label
                    className="w-full block text-black text-sm  mb-2"
                    htmlFor="ship-from"
                  >
                    Length
                  </label>
                  <input
                    {...register(`length`)}
                    className="leading-tight shadow appearance-none w-full text-xs  border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.length && (
                    <span className="text-xs text-red-700">
                      {errors.length.message}
                    </span>
                  )}
                </div>

                <div className="mb-8   float-left">
                  <label
                    className="w-full block text-black text-sm  mb-2"
                    htmlFor="ship-from"
                  >
                    Width
                  </label>
                  <input
                    {...register(`width`)}
                    className="leading-tight shadow appearance-none w-full text-xs  border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.width && (
                    <span className="text-xs text-red-700">
                      {errors.width.message}
                    </span>
                  )}
                </div>

                <div className="mb-8 float-left ">
                  <label
                    className="w-full block text-black text-sm  mb-2"
                    htmlFor="ship-from"
                  >
                    Height
                  </label>
                  <input
                    {...register(`height`)}
                    className="leading-tight shadow appearance-none w-full text-xs  border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    // min="0"
                  />
                  {errors.height && (
                    <span className="text-xs text-red-700">
                      {errors.height.message}
                    </span>
                  )}
                </div>

                <div className="mb-8  float-left ">
                  <label
                    className="w-full block text-black text-sm  mb-2"
                    htmlFor="ship-from"
                  >
                    Weight
                  </label>
                  <input
                    {...register(`weight`)}
                    className="leading-tight shadow appearance-none w-full text-xs  border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    min="0"
                  />
                  {errors.weight && (
                    <span className="text-xs text-red-700">
                      {errors.weight.message}
                    </span>
                  )}
                </div>
                <div className="mb-8  float-left">
                  <label
                    className="w-full block text-black text-sm mb-2"
                    htmlFor="ship-from"
                  >
                    Quantity
                  </label>
                  <div className="mb-3  w-full ">
                    <input
                      {...register(`quantity`)}
                      className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="1"
                    />
                    {errors.quantity && (
                      <span className="text-xs text-red-700">
                        {errors.quantity.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {cargo.length &&
              cargo.height &&
              cargo.width &&
              cargo.weight &&
              cargo.quantity ? (
                <div className="mb-8 float-left pr-1">
                  <p className="mb-1 font-heading space-x-2">
                    <span>
                      <b>Density</b>: {density} lb/ft
                      <sup>3</sup>
                    </span>
                  </p>
                  <p>
                    <span>
                      <b> Cubic FT</b>: {cubitFeet} ft
                      <sup>3</sup>
                    </span>
                  </p>
                </div>
              ) : null}
            </div>

            <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
              <p className="text-left w-full block text-black text-xl font-bold mb-6">
                Goods type
              </p>
              <div className="relative">
                <Controller
                  name="goods_condition"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={GOODS_CONDITION}
                      styles={customStyles}
                      className="appearance-none w-full text-xs font-semibold leading-none bg-gray-100 outline-none border-gray-200"
                    />
                  )}
                />
                {errors.goods_condition && (
                  <span className="text-xs text-red-700">
                    {errors.goods_condition.message}
                  </span>
                )}
              </div>
              <div className="flex mt-4">
                {GOODS_TYPE.map((goodsType) => {
                  return (
                    <div
                      className="mt-4 mb-4 float-left mr-6"
                      key={goodsType.value}
                    >
                      <label>
                        <input
                          {...register(`goods_type`)}
                          type="checkbox"
                          value={goodsType.value}
                        />
                        <span className="ml-1">{goodsType.label}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
              {errors.goods_type && (
                <span className="text-xs text-red-700">
                  {errors.goods_type.message}
                </span>
              )}
            </div>
            {[TRUCKLOAD_FLAT, PARTIAL_FLAT]?.includes(quote.service_type) && (
              <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                <p className="text-xl text-left text-black font-bold">Tarps</p>
                <div className="p-2 pl-0 relative">
                  <label>
                    <input {...register('tarps')} type="checkbox" />
                    <span className="ml-1">
                      Select this option if you would like us to deliver this
                      cargo covered with tarps
                    </span>
                    {errors.tarps && (
                      <span className="text-xs text-red-700">
                        {errors.tarps.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            )}

            {quote?.service_type === LTL_FREIGHT && (
              <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4 flex">
                <div className="mt-4 mb-4 w-1/2 float-left flex-1">
                  <p className="text-left w-full block text-black text-xl font-bold mb-6">
                    Freight Class
                  </p>
                  <div className="relative mb-3 w-1/2">
                    <Controller
                      name="freight_class"
                      defaultValue={{
                        value: '0',
                        label: 'Select Freight Class'
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={FREIGHT_CLASS}
                          styles={customStyles}
                          className="appearance-none w-full text-xs font-semibold leading-none bg-gray-100  outline-none border-gray-200"
                        />
                      )}
                    />

                    {errors.freight_class && (
                      <span className="text-xs text-red-700">
                        {errors.freight_class.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-4 mb-4 w-1/2 float-right flex-1">
                  <p className="text-left w-full block text-black text-xl font-bold mb-6">
                    NMFC Code
                  </p>
                  <input
                    {...register(`nmfc_code`)}
                    className="leading-tight shadow appearance-none w-1/2 text-xs  border-gray-200 border-solid border bg-gray-100 py-2 px-2"
                    type="string"
                  />
                  {errors.nmfc_code && (
                    <span className="text-xs text-red-700">
                      {errors.nmfc_code.message}
                    </span>
                  )}
                </div>
              </div>
            )}
            {quote.isMultipleQuote ? (
              <>
                <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                  <p className="text-left w-full block text-black text-xl  mb-6">
                    Ship from
                  </p>
                  {quote?.pickupInfo?.map((pickup, index) => {
                    return (
                      <div className="mt-4 mb-4 w-1/2 float-left">
                        <label>
                          <input
                            {...register(`ship_from`)}
                            type="radio"
                            value={pickup.id}
                            defaultChecked={index === 0}
                          />
                          <span className="ml-1">
                            {pickup?.pickup_formatted_address?.split('|')?.[0]}
                          </span>
                          <p className="pl-8 pt-1">
                            (
                            {!pickup.pickup_date
                              ? 'flexible date'
                              : pickup.pickup_date}{' '}
                            |{' '}
                            {!pickup.pickup_time
                              ? 'flexible time'
                              : pickup.pickup_time}
                            )
                          </p>
                        </label>
                      </div>
                    );
                  })}
                  {errors.ship_from && (
                    <span className="text-xs text-red-700">
                      {errors.ship_from.message}
                    </span>
                  )}
                </div>
                <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                  <p className="text-left w-full block text-black text-xl  mb-6">
                    Ship to
                  </p>
                  {quote?.deliveryInfo?.map((delivery, index) => {
                    return (
                      <div className="mt-4 mb-4 w-1/2 float-left">
                        <label>
                          <input
                            {...register(`ship_to`)}
                            type="radio"
                            value={delivery.id}
                            defaultChecked={index === 0}
                          />
                          <span className="ml-1">
                            {
                              delivery?.delivery_formatted_address?.split(
                                '|'
                              )?.[0]
                            }
                          </span>
                          <p className="pl-8 pt-1">
                            (
                            {!delivery.delivery_date
                              ? 'flexible date'
                              : delivery.delivery_date}{' '}
                            |{' '}
                            {!delivery.delivery_time
                              ? 'flexible time'
                              : delivery.delivery_time}
                            )
                          </p>
                        </label>
                      </div>
                    );
                  })}
                  {errors.ship_to && (
                    <span className="text-xs text-red-700">
                      {errors.ship_from.message}
                    </span>
                  )}
                </div>
              </>
            ) : null}
            <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
              <p className="text-left w-full block text-black text-xl font-bold mb-6">
                Description
              </p>
              <textarea
                {...register('desc')}
                className="resize border w-full p-2 pb-6 bg-gray-100"
                placeholder="make, model, serial number"
              />
              {errors.desc && (
                <span className="text-xs text-red-700">
                  {errors.desc.message}
                </span>
              )}
            </div>
            {!isPopup && (
              <label className="p-4">
                <input {...register('isDefault')} type="checkbox" />
                <span className="ml-1 text-sm">
                  Make This Cargo As A Default Cargo
                </span>
              </label>
            )}
            <div className="p-4 mt-10">
              <button
                type="button"
                onClick={handleAddClick}
                className="bg-navy-500 text-white px-10 py-2 text-sm font-semibold"
              >
                Add Cargo
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewCargo;

AddNewCargo.propTypes = {
  quote: PropTypes.object,
  isPopup: PropTypes.any,
  setIsPopup: PropTypes.func
};

AddNewCargo.defaultProps = {
  quote: {},
  isPopup: false,
  setIsPopup: false
};
