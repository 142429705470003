import React, { Suspense, useState, useMemo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  Navigate
} from 'react-router-dom';
import Toast from './Toast';
import Spinner from './components/Spinner/Spinner';
import PublicRoute from './components/PublicRoute/PublicRoute';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LayoutAnonymous from './layouts/LayoutAnonymous';
import LayoutAuthenticated from './layouts/LayoutAuthenticated';
import LayoutProtected from './layouts/LayoutProtected';
import PageNotFound from './pages/ErrorsPages/404';
import InternalServerError from './pages/ErrorsPages/500';
import CarrierThankYouPage from './pages/CarrierThankYouPage';
import { AuthContext } from './contexts/auth.context';
import routes from './routes';
import './styles/output.css';
import './styles/custom.css';
import 'react-datepicker/dist/react-datepicker.css';
import HomePageComponent from './HomePageComponent';

function App() {
  const existingToken = localStorage.getItem('token');
  const existingUser = JSON.parse(localStorage.getItem('user'));

  // Initialize App state with existing token, if exists.
  const [authUser, setAuthUser] = useState({
    user: existingUser,
    token: existingToken
  });

  // Extend "setAuthTokens" function to update localstorage when tokens gets changed
  // Pass this function as a context, so that it can be called from anywhere to update the tokens
  const setAuthUserExtended = (user, token) => {
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('link');
    }
    setAuthUser({ user, token });
  };

  const contextValue = useMemo(
    () => ({ authUser, setAuthUser: setAuthUserExtended }),
    [authUser]
  );

  return (
    <div className="App">
      <AuthContext.Provider value={contextValue}>
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
        <Spinner />
        <Toast />
      </AuthContext.Provider>
    </div>
  );
}

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<App />}
      errorElement={<Navigate to="/page-not-found" replace />}
    >
      <Route path="/" element={<HomePageComponent />} />
      <Route path="/page-not-found" element={<PageNotFound />} />
      <Route path="/something-went-wrong" element={<InternalServerError />} />
      <Route path="/carrier-thank-you" element={<CarrierThankYouPage />} />

      {/* Public Routes */}
      <Route element={<LayoutAnonymous />}>
        {routes.publicRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<PublicRoute element={element} />}
          />
        ))}
      </Route>

      {/* Protected Routes */}
      <Route element={<LayoutProtected />}>
        {routes.protectedRoutes.map(({ path, rolesAccess, element }) => (
          <Route
            key={path}
            path={path}
            rolesAccess={rolesAccess}
            element={
              <PrivateRoute
                element={element}
                rolesAccess={rolesAccess}
                path={path}
              />
            }
          />
        ))}
      </Route>

      {/* Authenticated Routes */}
      <Route element={<LayoutAuthenticated />}>
        {routes.privateRoutes.map(({ path, rolesAccess, element }) => (
          <Route
            key={path}
            path={path}
            rolesAccess={rolesAccess}
            element={
              <PrivateRoute
                element={element}
                rolesAccess={rolesAccess}
                path={path}
              />
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/page-not-found" replace />} />
      </Route>
    </Route>
  )
);

export default appRouter;
