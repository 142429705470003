import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChevronRightIcon from '../ChevronRightIcon';
import QuoteIcon from '../QuoteIcon';
import {
  BLANKET_WRAP,
  LTL_FREIGHT
} from '../../constants/service_type.constants';
import { BID_TYPE, BID_STATUS } from '../../constants/bids.constants';
import { additionalCharges, getServiceTypeName } from '../../utils/quote.util';

const SalesRepCard = memo(({ summaryPath, quote, showEnroute, loadType }) => {
  const { QUOTE_FROM_NET, FIXED_AMOUNT, OPEN_BID } = BID_TYPE;
  const { PENDING_QUOTE } = BID_STATUS;

  return (
    <div className="mb-6 mt-5 lg:mb-0 w-full lg:w-full px-3">
      <Link to={summaryPath}>
        <div className="p-6 flex flex-wrap bg-gray-100 border border-gray-100 shadow items-center">
          <div>
            <QuoteIcon />
          </div>
          <div className="flex justify-between w-full lg:w-2/3 items-center">
            <div>
              <h3 className="mb-2 text-xl font-bold font-heading">
                {quote.company}
              </h3>
              <p className="text-black ">
                From:{' '}
                <span className="font-bold">
                  {quote?.isMultipleQuote
                    ? quote?.pickupInfo?.length &&
                      quote?.pickupInfo
                        ?.map((pick) => pick.pickup_zip_code)
                        ?.join(', ')
                    : quote.pickup_zip_code}
                </span>{' '}
                &nbsp; To:{' '}
                <span className="font-bold">
                  {quote?.isMultipleQuote
                    ? quote?.deliveryInfo?.length &&
                      quote?.deliveryInfo
                        ?.map((del) => del.delivery_zip_code)
                        ?.join(', ')
                    : quote.delivery_zip_code}
                </span>
              </p>
              <p className="text-black ">
                Service Type:{' '}
                <span className="font-bold">
                  {`${getServiceTypeName(quote.service_type)} ${
                    quote.booked && quote.carrier_type
                      ? `( ${quote.carrier_type.toUpperCase()} )`
                      : ''
                  }`}
                </span>
              </p>
              {quote?.tracking_details?.trackingNumber ? (
                <p className="text-black ">
                  PRO:{' '}
                  <span className="font-bold">
                    {quote?.tracking_details?.trackingNumber}
                  </span>
                </p>
              ) : null}
              {loadType === 'salesRep-active-quotes' ||
              loadType === 'salesRep-pending-quotes' ? (
                <>
                  {quote.bidType === OPEN_BID && (
                    <h3 className="mb-2 text-xl font-bold font-heading text-green-700">
                      Open Bid
                    </h3>
                  )}
                  {quote.bidType === FIXED_AMOUNT && (
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Budget: $
                      {Number(
                        quote.amount +
                          quote.tarps_value +
                          additionalCharges(quote).revenueCharge
                      ).toFixed(2)}
                    </h3>
                  )}
                  {quote.bidType === QUOTE_FROM_NET && (
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Budget: $
                      {Number(
                        quote.estimate +
                          quote.tarps_value +
                          additionalCharges(quote).revenueCharge
                      ).toFixed(2)}
                    </h3>
                  )}
                </>
              ) : (
                <div>
                  {(loadType === 'salesRep-enroute-quotes' ||
                    loadType === 'salesRep-completed-quotes' ||
                    loadType === 'salesRep-recent-quotes') &&
                  (quote.bidType === FIXED_AMOUNT ||
                    quote.bidType === OPEN_BID) ? (
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Budget: $
                      {Number(
                        quote.amount +
                          quote.tarps_value +
                          additionalCharges(quote).revenueCharge
                      ).toFixed(2)}
                    </h3>
                  ) : (
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Budget: $
                      {quote.service_type === LTL_FREIGHT &&
                      quote.bidStatus === PENDING_QUOTE
                        ? `${quote.minRate} (min)`
                        : Number(
                            quote.estimate +
                              quote.tarps_value +
                              additionalCharges(quote).revenueCharge
                          ).toFixed(2)}
                    </h3>
                  )}
                </div>
              )}
              {showEnroute &&
                ![LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) &&
                (quote.isPickupByCarrier ? (
                  <h3 className="text-green-700">
                    Your shipment has been picked up by Carrier at{' '}
                    {quote.carrierPickupDate} {quote.carrierPickUpTime} .
                  </h3>
                ) : (
                  <h3 className="text-red-600">
                    Your shipment is not picked up by carrier yet.
                  </h3>
                ))}
            </div>
            <div className="items-center">
              {[
                'salesRep-pending-quotes',
                'salesRep-active-quotes',
                'salesRep-enroute-quotes'
              ].includes(loadType) && (
                <Link
                  to={`/salesRep/enroute-quote-details/tracking/${quote.id}`}
                  className="bg-navy-500 py-2 px-6 text-white z-50 m-2"
                >
                  Track
                </Link>
              )}
              {![LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) &&
              loadType === 'salesRep-pending-quotes' &&
              quote.bidStatus === PENDING_QUOTE &&
              quote.bidType === OPEN_BID ? (
                <Link
                  to={`/salesRep/shipper-submission-list/${quote.id}`}
                  state={quote}
                  className="bg-navy-500 py-2 px-6 text-white z-50"
                >
                  View Bids
                </Link>
              ) : null}
            </div>
          </div>
          <div className="ml-6">
            {quote?.internalTrackingNumber && (
              <span> #{quote.internalTrackingNumber}</span>
            )}
          </div>
          <div className="mt-auto ml-auto mb-auto">
            <ChevronRightIcon />
          </div>
        </div>
      </Link>
    </div>
  );
});

export default SalesRepCard;

SalesRepCard.propTypes = {
  quote: PropTypes.object.isRequired,
  summaryPath: PropTypes.any.isRequired,
  showEnroute: PropTypes.bool.isRequired,
  loadType: PropTypes.string.isRequired
};
