import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { options } from '../../constants/carrier.constants';

const PlacesAutoCompleteInput = ({
  onSelect,
  isOrigin,
  setDisableOriginSelect,
  setDisableDestinationSelect,
  filters,
  setFilters,
  setOrigin,
  setDestination
}) => {
  const { TextArea } = Input;

  const [address, setAddress] = useState('');

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    onSelect(value, latLng);
  };

  const handleInputChange = (data) => {
    if (isOrigin) {
      if (!data) {
        const newFilters = { ...filters };
        delete newFilters.origin;
        delete newFilters.originMaxDistance;
        setFilters(newFilters);
        setOrigin(options[0]);
      }
      setDisableOriginSelect(!data);
    } else {
      if (!data) {
        const newFilters = { ...filters };
        delete newFilters.destination;
        delete newFilters.destinationMaxDistance;
        setFilters(newFilters);
        setDestination(options[0]);
      }
      setDisableDestinationSelect(!data);
    }
    setAddress(data);
  };

  return (
    <PlacesAutocomplete
      value={address ?? ''}
      onChange={(e) => handleInputChange(e)}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="autocomplete-container">
          <TextArea
            {...getInputProps({
              placeholder: 'City or Postal Code',
              className:
                'carrier-input pl-3 border border-gray-200 placeholder:text-sm focus:outline-none border-radio rounded-none '
            })}
            autoSize
          />
          <div className="suggestions-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = suggestion.active
                ? { backgroundColor: '#E5EEFE', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };

              return (
                <div key={suggestion.placeId}>
                  <div
                    {...getSuggestionItemProps(suggestion, { style })}
                    className="px-1 py-2 bg-gray-500 cursor-pointer active:bg-black"
                  >
                    {suggestion.description}
                  </div>
                  <hr className="suggestion-divider" />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default PlacesAutoCompleteInput;

PlacesAutoCompleteInput.propTypes = {
  onSelect: PropTypes.func.isRequired,
  setDisableOriginSelect: PropTypes.func,
  setDisableDestinationSelect: PropTypes.func,
  isOrigin: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setOrigin: PropTypes.func,
  setDestination: PropTypes.func
};

PlacesAutoCompleteInput.defaultProps = {
  setDisableOriginSelect: () => {},
  setDisableDestinationSelect: () => {},
  setOrigin: () => {},
  setDestination: () => {}
};
