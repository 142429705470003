import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { useForm } from 'react-hook-form';
import { parse } from 'tldts';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { resetPasswordSalesRep } from '../services/salesrep.service';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required!')
      .email('Please enter a valid email.')
      .test('is-valid-tld', 'Please enter a valid email.', (value) => {
        if (!value) return false;
        const { isIcann } = parse(value);
        return isIcann;
      }),
    password: yup.string().required('Password is required!'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Please make sure your passwords match!')
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onResetPassword = async (data) => {
    try {
      if (isValid || !Object.keys(errors).length) {
        const { tempToken } = queryString.parse(location.search);

        const response = await resetPasswordSalesRep({
          email: data.email,
          password: data.password,
          tempToken
        });
        if (response.status === 200 && response.data.flag) {
          toast.success(response.data.message);
          navigate('/sign-in', { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  return (
    <div className="">
      <section className="py-10 lg:py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-md mx-auto">
            <div className="mb-6 lg:mb-10 text-left">
              <div className="mb-6  p-4">
                <h3 className="text-2xl text-navy-500 font-bold ">
                  Reset Password
                </h3>
              </div>
              <form action="" onSubmit={handleSubmit(onResetPassword)}>
                <div className="mb-3  p-4">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Enter your email
                  </label>
                  <input
                    {...register('email')}
                    className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                    type="email"
                    id="email"
                  />
                  {errors.email && (
                    <span className="text-xs text-red-700">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="mb-6 p-4 bg-white rounded">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    {...register('password')}
                    className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                    type="password"
                  />
                  {errors.password && (
                    <span className="text-xs text-red-700">
                      {errors.password.message}
                    </span>
                  )}
                </div>
                <div className="mb-6 p-4 bg-white rounded">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Confirm Password
                  </label>
                  <input
                    {...register('confirmPassword')}
                    className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                    type="password"
                  />
                  {errors.confirmPassword && (
                    <span className="text-xs text-red-700">
                      {errors.confirmPassword.message}
                    </span>
                  )}
                </div>
                <div className="text-left p-4 relative">
                  <button
                    // href="carrier-available-load.html"
                    type="submit"
                    disabled={isSubmitting}
                    className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                    bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                    text-sm font-bold  transition duration-200 text-left"
                  >
                    Reset Password
                    <svg
                      className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPasswordPage;
