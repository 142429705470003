import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as fileDownload from 'js-file-download';
import { generateBOLService } from '../../services/quote.service';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';

const BOLButton = ({ quoteId }) => {
  async function generateBOL() {
    try {
      const response = await generateBOLService({ quoteId });
      fileDownload(response.data, `${quoteId}.pdf`);
    } catch (error) {
      console.log({ error });
      toast.error('Something went wrong!');
    }
  }
  return (
    <button
      type="button"
      onClick={generateBOL}
      className="order-1 xl:order-0 mr-12 inline-block pl-4 my-2 w-full py-3 
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                        hover:border-navy-600 active:border-navy-700
                          text-sm font-bold transition duration-200 text-left"
    >
      Generate BOL
      <RightArrow className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-1 font-bold" />
    </button>
  );
};

export default BOLButton;

BOLButton.propTypes = {
  quoteId: PropTypes.string.isRequired
};
